import React from "react";
import {Space} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {DocSection} from "./Reusable";

export const DocEntryPoint = () => {
  return (
    <DocSection>
      <Space align="end">
        <p>L'ensemble de la documentation de ShootRecord est disponible sur cette page.</p>
      </Space>
      <p>
        <ArrowLeftOutlined /> Veuillez choisir une section sur le menu à gauche.
      </p>
    </DocSection>
  );
};
