import React from "react";
import { Button, Descriptions, Divider, notification, Typography } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useSendValidationEmailMutation } from "../../api/gqlTypes";

const { Paragraph } = Typography;

export const EmailManager = (props: any) => {
  const [sendValidationEmail] = useSendValidationEmailMutation({
    onCompleted: () => {
      notification.success({
        message: "Email",
        description: "Email de confirmation envoyé!",
      });
    },
  });

  return (
    <>
      <Divider orientation="left">Email</Divider>
      <Descriptions column={2}>
        <Descriptions.Item label="Adresse">
          <Paragraph>{props.current.user.emails[0]?.address ?? "Adresse non renseignée"}</Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Etat de vérification">
          {props.current?.user?.emails?.[0]?.verified ? (
            <p>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </p>
          ) : (
            <p>
              <CloseCircleTwoTone twoToneColor="#eb2f96" />
              <Button
                type="link"
                size="small"
                onClick={() => {
                  sendValidationEmail();
                }}
              >
                Renvoyer email
              </Button>
            </p>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
