import React, { useState } from "react";
import { Col } from "antd";
import { GunsForm } from "./GunsForm";
import { GunsTable } from "./GunsTable";

export const GunsManager = () => {
  const [form, setForm] = useState<string | null>(null);

  return (
    <>
      {form && (
        <Col span={24}>
          <GunsForm gunId={form} setForm={setForm} />
        </Col>
      )}
      <Col span={24}>
        <GunsTable setForm={setForm} />
      </Col>
    </>
  );
};
