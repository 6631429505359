import React, { useEffect, useRef, useState } from "react";
import { FormInstance } from "antd/lib/form";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Switch,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { errNot, layout, tailLayout } from "../../Common";
import {
  useAddGunMutation,
  useEditGunMutation,
  useGunQuery,
} from "../../api/gqlTypes";

type Props = {
  gunId: string;
  setForm(arg: string | null): void;
};

export const GunsForm = (props: Props) => {
  const [form] = Form.useForm();
  const gunFormRef = useRef<FormInstance>(null);

  //useState for available switch, as antd form can't control it correctly
  const [available, setAvailable] = useState<boolean>(true);

  useEffect(() => {
    if (props.gunId === "add") {
      form.resetFields();
      setAvailable(true);
    }
  }, [props.gunId, form]);

  useGunQuery({
    variables: { id: props.gunId },
    skip: props.gunId === "add",
    onCompleted: ({ gun }) => {
      gunFormRef.current?.setFieldsValue({
        brand: gun.brand,
        model: gun.model,
        serial: gun.serial,
      });
      setAvailable(gun.available);
    },
  });

  const [addGun] = useAddGunMutation({
    refetchQueries: ["Guns"],
    onCompleted: () => {
      notification.success({
        message: "Succès: ",
        description: "Arme ajoutée.",
      });
      form.resetFields();
    },
    onError: (err) => errNot(err),
  });

  const [editGun] = useEditGunMutation({
    refetchQueries: ["Guns"],
    onCompleted: () => {
      notification.success({
        message: "Succès: ",
        description: "Arme modifiée.",
      });
      form.resetFields();
      props.setForm(null);
    },
    onError: (err) => errNot(err),
  });

  const onFinish = ({
    brand,
    model,
    serial,
  }: {
    brand: string;
    model: string;
    serial: string;
  }) => {
    if (props.gunId === "add")
      addGun({
        variables: {
          brand,
          model,
          serial,
          available,
        },
      });
    else
      editGun({
        variables: {
          id: props.gunId,
          brand,
          model,
          serial,
          available,
        },
      });
  };

  return (
    <Card
      title={
        props.gunId === "add" ? "Ajouter une arme :" : "Editer cette arme :"
      }
      size="small"
    >
      <Form
        {...layout}
        name="GnForm"
        onFinish={onFinish}
        form={form}
        ref={gunFormRef}
        style={{ marginRight: "20%" }}
      >
        <Form.Item
          label="Marque:"
          name="brand"
          rules={[{ required: true, message: "Entre la marque de l'arme!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Modèle:"
          name="model"
          rules={[{ required: true, message: "Entre le modèle de l'arme!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="N° série:"
          name="serial"
          rules={[
            { required: true, message: "Entre le numero de série de l'arme!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Disponibilité de l'arme">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(val) => setAvailable(val)}
            checked={available}
          />
        </Form.Item>

        <Form.Item {...tailLayout} name="available">
          <Row gutter={[1, 0]}>
            <Col offset={0}>
              <Button type="primary" htmlType="submit">
                {props.gunId === "add" ? "Ajouter" : "Modifier"}
              </Button>
            </Col>
            <Col offset={1}>
              <Button htmlType="button" onClick={() => props.setForm(null)}>
                Fermer
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
