import React from "react";
//import ReactPlayer from "react-player";
//import satisfied from "../../assets/satisfied.webp";
import product from "../../assets/product.webp";
import stm from "../../assets/stm.jpg";
import lingo from "../../assets/srLingo.png";

import styled from "styled-components";
import { Button, Col, List, Row, Typography, Image, Card, Rate } from "antd";
import { AreaChartOutlined, DesktopOutlined, IdcardOutlined, UnlockOutlined, ApartmentOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import Texty from "rc-texty";
import "rc-texty/assets/index.css";
//import { PriceMaker } from "./PriceMaker";
import { useNavigate } from "react-router-dom";
import { UrlRoutes } from "../../Common";

const SpredCard = styled(Card)`
  height: calc(100% - 20px);
`;

const salesArg: { title: JSX.Element; args: JSX.Element[] }[] = [
  {
    title: (
      <>
        <IdcardOutlined /> Un système de pointage{" "}
        <u>
          <b>intelligent, rapide et sécurisé</b>
        </u>{" "}
        :
      </>
    ),
    args: [
      <>
        Le membre scanne en 5 secondes le code-barre de sa licence
        <u>
          <b>avec un scanner de haute qualité</b>
        </u>{" "}
        fourni à cet effet.
      </>,
      <>
        Le registre contient{" "}
        <u>
          <b>le nom, le prénom, la licence et l'heure d'arrivée de la personne.</b>
        </u>
      </>,
      <>
        En option le logiciel peut ajouter à l'entrée de registre{" "}
        <u>
          <b>le pas de tir, ou/et les calibres utilisés</b>
        </u>
        .
      </>,
      <>
        Une autre option vous propose de{" "}
        <u>
          <b>gérer vos armes de prêt</b>
        </u>
        , et permet de savoir qui emprunte quelle arme.
      </>,
      <>
        <u>
          <b>La gestion d'une heure de départ</b>
        </u>{" "}
        est également possible en option: le tireur rescanne sa licence au moment de partir. Le logiciel peut alors gérer le nombre de places
        disponibles aux pas de tirs et les armes disponibles.
      </>,
    ],
  },
  {
    title: (
      <>
        <DesktopOutlined /> Contrôle et{" "}
        <u>
          <b>synchronisation instantanée avec ITAC: </b>
        </u>
      </>
    ),
    args: [
      <>
        Le fait de scanner le QR-code au lieu du code 1d fait
        <u>
          <b> automatiquement </b>
        </u>{" "}
        appel à cette fonctionalité,{" "}
        <u>
          <b>aucune autre manipulation n'est requise</b>
        </u>
        .
      </>,
      <>
        Lorsqu'un QR-code est scanné, ITAC est consulté et les informations renvoyées vont soit:
        <ul>
          <li>
            <u>
              <b>Créer instantanément le nouveau membre</b>
            </u>{" "}
            si il n'existe pas déjà dans ShootRecord.
          </li>
          <li>
            <u>
              <b>Noter la date du contrôle et la validité de la licence </b>
            </u>{" "}
            pour un membre déjà existant.
          </li>
        </ul>
      </>,
      <>
        A l'issu du contrôle Itac, un pointage peut être fait à l'aide d'un{" "}
        <u>
          <b> simple clic </b>
        </u>
        .
      </>,
    ],
  },
  {
    title: (
      <>
        <AreaChartOutlined /> Consultation des données:
      </>
    ),
    args: [
      <>
        Le registre peut être lu par date ou intervalle de dates, comme un{" "}
        <u>
          <b>simple calendrier.</b>
        </u>
      </>,
      <>
        Depuis la page des membres, on peut obtenir une liste de toutes les présences d'un membre, ainsi que tous les contrôles de licences de ce
        membre.
      </>,
      <>
        <u>
          <b>Des statistiques de présences</b>
        </u>{" "}
        sont réunies dans un calendrier{" "}
        <u>
          <b>mensuel et annuel</b>
        </u>
        .
      </>,
      <>
        Chaque membre peut consulter ses données:
        <u>
          <b>où il veut, et quand il veut, grâce à une simple connexion à internet</b>
        </u>
        .
      </>,
    ],
  },
  {
    title: (
      <>
        <UnlockOutlined /> Gestion des droits (N'importe qui ne fait pas n'importe quoi):
      </>
    ),
    args: [
      <>
        <u>
          <b>Chaque membre peut se voir attribuer des droits:</b>
        </u>{" "}
        accéder au registre, modifier des membres, gérer des pas de tir ou des calibres...{" "}
      </>,
      <>ShootRecord propose un mode "Kiosk" qui peut être lancé lorsque des membres pointent hors de la surveillance du gérant du club.</>,
      <>Le mode kiosk n'autorise que le pointage, bloque toutes les autres fonctionalités et nécessite un mot de passe pour être quitté.</>,
    ],
  },
  {
    title: (
      <>
        <ApartmentOutlined /> Fonctionnement multiposte:
      </>
    ),
    args: [
      <>
        ShootRecord peut être lancé sur{" "}
        <u>
          <b>plusieurs postes</b>
        </u>{" "}
        en même temps: ainsi vous pouvez avoir une ou plusieurs badgeuses, et poste de gestion dans le bureau.
      </>,
      <>Possibilité de mettre un badgeuse par pas de tir.</>,
    ],
  },
];

const CenteredDiv = styled.div`
  width: max-content;
  margin: auto;
`;

const BigButton = styled(Button)`
  height: fit-content;
  font-size: 1.5em;
`;

const EnlargedButton = styled(BigButton)`
  max-width: 600px;
`;

//General div of the page
const LandingPageDiv = styled.section`
  margin-top: -20px;
  margin-bottom: -20px;
  background-color: #efefef;
  height: calc(100% + 40px);
  position: relative;
  z-index: 20;
`;

//Makes Typography uses inherit color for blocks
const Title = styled(Typography.Title)`
  && {
    color: inherit;
  }
`;

const MargedTitle = styled(Title)`
  && {
    margin: 40px 0 20px;
  }
`;

const Text = styled(Typography.Text)`
  && {
    color: inherit;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  && {
    color: inherit;
  }
`;

const TitleRow = styled.div`
  width: 100%;
  height: 36px;
  background-color: #1b2b3a;
  display: flex;
`;
const TitleCol = styled.div`
  margin: auto;
  width: fit-content;
  height: fit-content;
  color: #e5e5f3;
`;

const InlineTitle = styled(Title)`
  && {
    display: inline;
    font-weight: 300;
    color: inherit;
    //color: #aeb6bd;
  }
`;

const CenteredTitle = styled(Title)`
  text-align: center;
  color: inherit;
`;

const LpRow = styled(Row)`
  max-width: 800px;
`;

//First Wrapper + content
const FirstWrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  height: fit-content;
  background-color: #151f2a;
  display: flex;
  justify-content: center;
`;

const FirstWrapperCol = styled(Col)`
  margin: 20px 0;
  color: #e1e5e5;
`;

/*const VideoPlayer = styled(ReactPlayer)`
  && {
    margin: auto;
    background-color: #2f54eb;
  }
`;*/

//Second Wrapper and elements
const SecondWrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #34506e;
`;

const ListUl = styled.ul`
  margin-top: 10px;
`;

const ListLi = styled.li`
  margin-top: 10px;
`;

export const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <LandingPageDiv>
      <TitleRow>
        <TitleCol>
          <InlineTitle level={4}>ShootRecord: </InlineTitle>
          <InlineTitle level={5}>
            Eliminez le superflu et{" "}
            <u>
              <b>consacrez votre temps à votre passion</b>
            </u>
            : Le Tir
          </InlineTitle>
        </TitleCol>
      </TitleRow>
      <FirstWrapper>
        <LpRow gutter={[10, 20]}>
          <FirstWrapperCol xs={24} sm={24} md={12} style={{ display: "flex", flexDirection: "column" }}>
            <CenteredTitle level={2}>Nouveau:</CenteredTitle>

            <CenteredTitle level={2} style={{ marginTop: "0px" }}>
              Faites passer votre Club de tir au{" "}
              <u>
                <b>niveau supérieur</b>
              </u>
            </CenteredTitle>
            <Text style={{ marginLeft: "auto", marginRight: "auto", fontSize: "1.5em" }}>
              (juste avec une connexion internet)
              <ul>
                <QueueAnim delay={300} className="queue-simple">
                  <li key={1}>Simplifiez la vie de vos adhérents</li>
                  <li key={2}>Protégez vos données</li>
                  <li key={3}>Allez tirer en toute sérénité</li>
                  <li key={4}>Donnez du prestige à votre club!</li>
                </QueueAnim>
              </ul>
            </Text>
            <BigButton
              type="primary"
              onClick={() => {
                navigate("/" + UrlRoutes.Su);
              }}
            >
              Démarrer l'essai gratuit de 60 jours!
            </BigButton>
          </FirstWrapperCol>
          <FirstWrapperCol xs={24} sm={24} md={12} flex={1} style={{ display: "flex", alignItems: "center" }}>
            {/*  <VideoPlayer url="https://www.youtube.com/watch?v=nl0QDkAwxWY" height="100%" width="100%" />*/}
            <Image src={product} />
          </FirstWrapperCol>
        </LpRow>
      </FirstWrapper>
      <br />
      <br />
      <SecondWrapper>
        <LpRow gutter={[20, 20]}>
          <Col span={12}>
            <SpredCard cover={<img alt="stm" src={stm} />}>
              <Card.Meta
                title="Armurerie S.T. Militaria"
                description="ShootRecord correspond parfaitement à mes attentes. Le logiciel est simple et fiable. Son développeur m'a par ailleurs codé une fonctionnalité de comptage de munition sur mesure."
              />
              <Rate disabled defaultValue={5} />
            </SpredCard>
          </Col>
          <Col span={12}>
            <SpredCard cover={<img alt="stm" src={lingo} />}>
              <Card.Meta
                title="Club de tir l'Amitié Lingolsheim"
                description="C'est pour répondre à notre besoin que les options pas de tir et calibre ont été ajoutées à ShootRecord par son développeur. Elles sont très pratiques et permettet de garder une trace de ce que font les tireurs. "
              />
              <Rate disabled defaultValue={5} />
            </SpredCard>
          </Col>
          <Col span={24}>
            <List
              header={
                <>
                  <CenteredDiv style={{ color: "#34506e", textAlign: "center", fontSize: "1.5em" }}>
                    <Title>
                      <Texty>ShootRecord c'est quoi? </Texty>
                    </Title>

                    <Title level={3} style={{ display: "inline-block" }}>
                      C'est un logiciel en ligne,
                    </Title>
                    <br />
                    <Text>(une simple connexion internet suffit)</Text>
                    <br />
                    <Title level={2} style={{ display: "inline-block" }}>
                      <u>créé par un tireur, pour les tireurs.</u>
                    </Title>

                    <br />
                    <Text>
                      Il{" "}
                      <u>
                        <b>vous</b>
                      </u>{" "}
                      permet de contrôler votre club et le gérer comme{" "}
                      <u>
                        <b>vous</b>
                      </u>{" "}
                      l'entendez.
                    </Text>

                    <br />
                    <br />
                  </CenteredDiv>
                  <CenteredDiv>
                    <EnlargedButton type="primary" onClick={() => navigate("/" + UrlRoutes.Su)}>
                      Démarrer l'essai gratuit de 60 jours!
                    </EnlargedButton>
                  </CenteredDiv>
                </>
              }
              footer={<br />}
              dataSource={salesArg}
              renderItem={({ title, args }, index) => (
                <List.Item key={index} style={{ flexDirection: "column", color: "#34506e" }}>
                  <MargedTitle level={4} key={index}>
                    {title}
                  </MargedTitle>
                  <ListUl>
                    {args.map((arg, index) => (
                      <ListLi key={index}>
                        <Text>{arg}</Text>
                      </ListLi>
                    ))}
                  </ListUl>
                </List.Item>
              )}
            />
            <div style={{ color: "#34506e", textAlign: "center" }}>
              <BigButton
                type="primary"
                onClick={() => {
                  navigate("/" + UrlRoutes.Su);
                }}
              >
                Démarrer l'essai gratuit de 60 jours!
              </BigButton>
            </div>
            <br />
            <br />
          </Col>
        </LpRow>
      </SecondWrapper>
      <SecondWrapper>
        <LpRow>
          <Col span={24} style={{ color: "#34506e", padding: "0 20px" }}>
            <div style={{ color: "#34506e", textAlign: "center" }}>
              <Title>D'où vient Shootrecord?</Title>
            </div>
            <Paragraph>Tout a commencé dans un club de tir alsacien à Dettwiller, dans lequel le créateur de ShootRecord est membre.</Paragraph>
            <Paragraph>
              Les problèmes étaient nombreux: <br />
              <ul>
                <li>Matériel dégradé.</li>
                <li>Tireurs ne respectant pas certaines interdictions de calibres.</li>
                <li>Parfois certains tireurs ne s'inscrivaient même pas sur le cahier de présence avant d'aller tirer.</li>
                <li>Difficulté à estimer l'assiduité de certaines personnes que nous ne voyons pas très souvent.</li>
              </ul>
            </Paragraph>
            <Paragraph>
              D'un coté nous avons à faire face à tous ces petits problèmes de gestion qui deviennent une charge pesante pour le comité de direction.
            </Paragraph>
            <Paragraph>
              Et de l'autre la fédération nous mettait de plus en plus la pression sur les règles à respecter, les documents administratifs à remplir.
              La responsabilité du président s'est encore accrue le 28 avril 2020 avec la suppression du carnet de tir.
            </Paragraph>

            <CenteredTitle level={3} style={{ color: "#34506e", textAlign: "center" }}>
              Entre le marteau et l'enclume:
            </CenteredTitle>

            <Paragraph>
              La direction se trouvait entre les membres qui réclamaient davantage de libertés, de confiance et la fédération qui impose toujours plus
              de règles.
            </Paragraph>

            <Paragraph>Aux yeux des membres, le président passait pour celui qui est toujours derrière, à surveiller tout le monde.</Paragraph>

            <Paragraph>
              Alors même que c'est lui le responsable en cas d'accident, et qu'il met tout en oeuvre pour que les membres passent un bon moment et
              profitent de leur passion.
            </Paragraph>

            <CenteredTitle level={3}>
              Après 5 mois de d'apprentissage, de tests et d'échecs,{" "}
              <u>
                <b>une solution a vu le jour :</b>
              </u>
            </CenteredTitle>

            <Paragraph>
              Un membre de la direction du club de tir de Dettwiller a un jour décidé de commencer à développer un petit logiciel tout simple. L'idée
              était de faciliter les tâches "ingrates" des membres et de la direction.
            </Paragraph>

            <Paragraph>
              Au début il fallait l'installer sur un ordinateur dédié à cela, corriger quelques bugs, ce n'était pas tout à fait au point.
            </Paragraph>

            <Paragraph>
              Il a testé, re-testé, simplifié, étudié, changé, passé des nuits blanches à travailler et à améliorer son programme.
            </Paragraph>

            <Paragraph>
              Sur les conseils des membres du club il a simplifié l'interface et intégré des nouvelles fonctionnalités : Le contrôle des pas de tirs,
              des calibres, des armes de prêts, l'intégration de statistiques.
              <ListUl>
                <ListLi>La paperasse diminuait.</ListLi>
                <ListLi>La charge mentale des membres de la direction était allégée.</ListLi>

                <ListLi>Leurs relations avec les adhérents se sont améliorées.</ListLi>
              </ListUl>
            </Paragraph>
            <br />
            <div style={{ color: "#34506e", textAlign: "center" }}>
              <BigButton
                type="primary"
                onClick={() => {
                  navigate("/" + UrlRoutes.Su);
                }}
              >
                Démarrer l'essai gratuit de 60 jours!
              </BigButton>
            </div>
            <br />
            <br />
          </Col>

          {/*<Col span={14}>
            <img src={satisfied} alt="Satisfait ou remboursé" style={{ width: "100%" }} />
          </Col>
          <Col span={10}>
            <CenteredTitle level={3}>
              Garantie 60 jours{" "}
              <u>
                <b>satisfait ou remboursé</b>
              </u>{" "}
            </CenteredTitle>
            <Title level={5}>Peu importe la formule que vous choisissez:</Title>
            <Paragraph>
              Si au bout de 60 jours vous n'êtes pas satisfait du produit, nous nous engagons à vous rembourser intégralement votre achat.
            </Paragraph>
            <Paragraph>
              le scanner (indispensable) vous est fourni{" "}
              <u>
                <b>à prix coutant. Il est compris dans le remboursement</b>
              </u>
            </Paragraph>
          </Col>
          <Col span={24}>
            <PriceMaker />
          </Col>*/}
        </LpRow>
      </SecondWrapper>
    </LandingPageDiv>
  );
};
