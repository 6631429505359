import React, { createContext, useEffect } from "react";
import { Layout, notification } from "antd";
import { CurrentQuery, useCurrentQuery } from "./api/gqlTypes";
import { UrlRoutes, useStore } from "./Common";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "./ui/Loader";

import "antd/dist/antd.css";
import { HeaderContent, LoadingHeaderContent } from "./ui/Header/HeaderContent";
import { DocMenu } from "./ui/Documentation/DocMenu";
import { MainContainer } from "./MainContainer";
import { ApolloClient } from "@apollo/client";

//Stylisation
const { Header, Content, Sider, Footer } = Layout;

const SrHeader = styled(Header)`
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 2px 8px #f0f1f2;
  z-index: 10;
`;

const MainLayout = styled(Layout)`
  padding-top: 64px;
`;

const SrSider = styled(Sider)`
  background-color: #ffffff;
  border-right: 0.5px solid #eeeeee;
  font-size: 10px;
`;

const SrContent = styled(Content)`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const SrFooter = styled(Footer)`
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
`;

//Context utilisateur
export const CurrentContext = createContext<CurrentQuery["current"]>({
  user: null,
  clubs: [],
  currentClub: null,
  currentRoles: [],
  kiosk: false,
});

//Types
type Props = {
  apolloClient: ApolloClient<any>;
};

notification.config({
  placement: "bottomLeft",
});

function App(props: Props) {
  const [token] = useStore("loginToken");

  //Getting the data for current.
  const { data, error, loading } = useCurrentQuery({
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    props.apolloClient.resetStore();
  }, [token, data?.current.currentClub, props.apolloClient]);

  if (error) return <Loader embedded={false} error={error} />;
  if (data || loading)
    return (
      <CurrentContext.Provider value={data?.current}>
        <Layout>
          <SrHeader>
            {data && <HeaderContent />} {loading && <LoadingHeaderContent />}
          </SrHeader>
          <MainLayout>
            <Routes>
              <Route
                path={UrlRoutes.Dc + "/*"}
                element={
                  <SrSider width={250}>
                    <DocMenu />
                  </SrSider>
                }
              />
            </Routes>
            <Layout>
              <SrContent>
                {data && <MainContainer />}
                {loading && <Loader embedded={false} />}
              </SrContent>
              <SrFooter>ShootRecord Cloud v1.0.0 — Diazonium Developpement</SrFooter>
            </Layout>
          </MainLayout>
        </Layout>
      </CurrentContext.Provider>
    );
}

export default App;
