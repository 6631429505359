import React from "react";
import { Card, Image, Space } from "antd";
import itacCtrl from "../../assets/itacCtrl.png";
import { DocSection } from "./Reusable";

const { Meta } = Card;

export const ItacCtrl = () => {
  return (
    <DocSection>
      <Space size="large" direction="vertical">
        Cette fonctionnalité est disponible en scannant le Qr-code de la licence à la place du code-barres, depuis le même formulaire (voir Pointage)
        <ul>
          <li>ShootRecord consulte Itac et vérifie la validité de la licence scannée.</li>
          <li>Si le tireur n'est pas encore dans la base de donnée de ShootRecord il est automatiquement ajouté</li>
          <li>Si le tireur est déjà présent dans votre ShootRecord sans que son numéro de licence soit renseigné il est aussi ajouté</li>
          <li>
            Dans tous les cas, les informations sur le tireur et la validité de sa licence sont affichés dès que la requête sur Itac est terminée
          </li>
          <li>Vous pouvez alors Pointer le tireur pour l'ajouter au registre du jour, ou revenir simplement au formulaire de pointage</li>
          <li>
            Tout contrôle sur Itac est sauvegardé dans ShootRecord, veuillez vous référer à la documentation sur la gestion des utilisateurs pour plus
            de détails
          </li>
        </ul>
        <Card cover={<Image src={itacCtrl} width="800px" />}>
          <Meta description="Lors d'un contrôle Itac" />
        </Card>
      </Space>
    </DocSection>
  );
};
