import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Input, Result, Row, Steps, Typography } from "antd";
import { KeyOutlined, MailOutlined, QrcodeOutlined, QuestionCircleOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSignUpMutation, useUserExistsLazyQuery } from "../api/gqlTypes";
import { errNot, UrlRoutes, useStore } from "../Common";
import { sha256 } from "js-sha256";
import { CurrentContext } from "../App";
import { useNavigate } from "react-router-dom";

import { LoadingCircle, CenteredWidthLimitedCard } from "./CommonStyledCMP";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const { Step } = Steps;

const StepCol = styled(Col)`
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const SignUp = () => {
  const { user } = useContext(CurrentContext);
  const navigate = useNavigate();

  //Set the step of registration procedure
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const [userExists, setUserExists] = useState<number>();

  //to receive the loginToken if successful registration
  const [, setToken] = useStore("loginToken");
  const [licence, setLicence] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [wItacCheck, setWithItacCheck] = useState(true);

  //First get info about licence is already used or not
  const [checkUserExists] = useUserExistsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: ({ userExists }) => {
      setUserExists(userExists);
      if (userExists >= 2) {
        setStep(2);
      } else setStep(1);
    },
  });

  //called with signUp info, sets token if successfull
  const [signUp, { loading }] = useSignUpMutation({
    onCompleted: ({ signUp }) => {
      setSuccessMsg(true);
      setStep(2);
      setToken(signUp);
    },
    onError: (err) => errNot(err),
  });

  //redirection useEffect
  useEffect(() => {
    if (user && step === 0) navigate("/" + UrlRoutes.Sm);
    if (user) {
      const timeout = setTimeout(() => {
        navigate("/" + UrlRoutes.Sm);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [user, navigate, step]);

  return (
    <Col>
      <CenteredWidthLimitedCard title="S'inscrire" size="small">
        <Row gutter={[20, 20]}>
          <StepCol span={4}>
            <Steps current={step} size={"small"} direction="vertical">
              <Step title="N° Licence" icon={<QuestionCircleOutlined />} />
              <Step title="Inscription" icon={<SolutionOutlined />} />
              <Step title="Fin" icon={<SmileOutlined />} />
            </Steps>
          </StepCol>

          <Col span={20}>
            <Row>
              {step < 3 && (
                <Col span={24}>
                  <Typography.Title level={3} style={{ textAlign: "center" }}>
                    Inscription
                  </Typography.Title>
                </Col>
              )}

              {step === 0 && (
                <>
                  <Col offset={6} span={24}>
                    <Typography.Paragraph>Pour commencer, veuillez renseigner votre numéro de licence FFTir:</Typography.Paragraph>
                  </Col>

                  <Col span={24}>
                    <Form
                      title="licence"
                      {...layout}
                      onFinish={({ licence }) => {
                        setLicence(licence);
                        checkUserExists({ variables: { licence: parseInt(licence) } });
                      }}
                    >
                      <Form.Item
                        name="licence"
                        label="N° de licence FFTir"
                        rules={[{ required: true, message: "Veuillez entrer vote numéro de licence" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                          Suivant
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </>
              )}

              {step === 1 && (
                <>
                  <Col offset={1} span={20}>
                    <Typography.Paragraph>
                      {userExists === 1 &&
                        "Votre compte existe, toutefois vous n'avez pas d'identifiants pour vous connecter. \n Vous pouvez les créer en vous authentifiant en donnant votre lien itac que vous pouvez obtenir en flashant le QR-code de votre licence (avec votre téléphone par exemple)"}
                      {userExists === 0 &&
                        "Votre licence n'a jamais été utilisée sur ShootRecord, vous pouvez vous inscrire en remplissant le formulaire; votre compte ShootRecord sera utilisable dans tous les clubs partenaires"}
                    </Typography.Paragraph>

                    {!wItacCheck && (
                      <Alert
                        message="Attention! Si vous ne communiquez pas votre lien Itac, nous ne pouvons pas vérifier la validité des informations transmises. Il
                    faudra le communiquer plus tard ou pointer avec le Qr Code dans un club"
                      />
                    )}
                    <br />
                  </Col>

                  <Col offset={1} span={20}>
                    <Form
                      style={{ width: "maxWidth" }}
                      title="licence"
                      onFinish={({ lname, fname, email, password, password2, itacLink, username }) => {
                        signUp({
                          variables: {
                            lname,
                            fname,
                            email,
                            username,

                            pwdHash: sha256(password),
                            pwdHash2: sha256(password2),
                            licence: parseInt(licence),
                            itacLink,
                          },
                        });
                      }}
                    >
                      {!wItacCheck && (
                        <>
                          <Form.Item name="lname" rules={[{ required: true, message: "Veillez entrer votre nom." }]}>
                            <Input prefix={<UserOutlined />} placeholder="Nom" />
                          </Form.Item>
                          <Form.Item name="fname" rules={[{ required: true, message: "Veillez entrer votre prénom." }]}>
                            <Input prefix={<UserOutlined />} placeholder="Prénom" />
                          </Form.Item>
                        </>
                      )}
                      {!userExists && (
                        <Form.Item
                          name="username"
                          rules={[{ required: true, message: "Veillez entrer votre pseudo (utilisé pour vous connecter sur ShootRecord)" }]}
                        >
                          <Input prefix={<UserOutlined />} placeholder="Pseudo" />
                        </Form.Item>
                      )}
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Veillez entrer votre email." },
                          { type: "email", message: "Veuillez entrer une adresse email valide." },
                        ]}
                      >
                        <Input prefix={<MailOutlined />} placeholder="Email" />
                      </Form.Item>
                      <Form.Item name="password" rules={[{ required: true, message: "Veillez entrer un mot de passe" }]}>
                        <Input type="password" prefix={<KeyOutlined />} placeholder="Mot de passe" />
                      </Form.Item>
                      <Form.Item name="password2" rules={[{ required: true, message: "Veillez confirmer votre mot de passe." }]}>
                        <Input type="password" prefix={<KeyOutlined />} placeholder="Confirmation du mot de passe" />
                      </Form.Item>
                      {wItacCheck && (
                        <Form.Item
                          name="itacLink"
                          rules={[
                            {
                              required: true,
                              message:
                                "Si vous n'êtres pas en mesure de scanner votre lien itac depusi vore licence, merci de clique sur le bouton \"S'inscrire sans le lien Itac. \"",
                            },
                          ]}
                        >
                          <Input prefix={<QrcodeOutlined />} placeholder="https://itac.pro/F.aspx?..." />
                        </Form.Item>
                      )}
                      <Form.Item {...tailLayout}>
                        {loading ? (
                          <LoadingCircle spin />
                        ) : (
                          <>
                            <Button type="primary" htmlType="submit">
                              Suivant
                            </Button>
                            <Button
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                setStep(0);
                              }}
                            >
                              Précédent
                            </Button>
                            <Button
                              type="link"
                              onClick={() => {
                                setWithItacCheck(!wItacCheck);
                              }}
                            >
                              {wItacCheck ? "S'inscrire sans le lien Itac" : "S'inscrire avec le lien Itac"}
                            </Button>
                          </>
                        )}
                      </Form.Item>
                    </Form>
                  </Col>
                </>
              )}

              {step === 2 && (
                <Result
                  status={userExists < 2 && successMsg ? "success" : "warning"}
                  title={userExists === 3 ? "Votre compte est déjà créé!" : "Votre inscription sur ShootRecord est effectuée avec succès!"}
                  subTitle={
                    <>
                      {userExists === 3 &&
                        'Vous disposez d\'un login et d\'un mot de passe. Si vous les avez perdu, cliquez sur "mot de passe oublié" afin de recevoir un mail pour le ré-initialiser. Si jamais votre adresse email a changé, cliquez sur "contacter un administrateur" '}
                      {userExists === 2 &&
                        'Un gérant de club a déjà rentré votre email dans ShootRecord. Cliquez sur "définir un mot de passe pour recevoir un email d\'initialisation de mot de passe" '}
                      {successMsg && "Vous allez être redirigé vers votre espace personnel ShootRecord sous 5 secondes."}
                    </>
                  }
                  extra={
                    userExists === 3 ? (
                      <>
                        <Button
                          style={{ marginLeft: "4px" }}
                          type="primary"
                          onClick={() => {
                            setStep(0);
                          }}
                        >
                          Précédent
                        </Button>
                        <Button key="console">{userExists === 3 ? "Mot de passe oublié" : "Définir un mot de passe"}</Button>
                        <Button>Contacter un administrateur</Button>
                      </>
                    ) : (
                      <Button onClick={() => navigate("/")}> Aller à l'accueil </Button>
                    )
                  }
                />
              )}
            </Row>
          </Col>
        </Row>
      </CenteredWidthLimitedCard>
    </Col>
  );
};
