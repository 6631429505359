import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export enum DocUrlRoutes {
  principe = "principe",
  tarifs = "tarification",
  inscription = "inscription",
  inscriptionClub = "inscription_club",
  pointage = "pointage",
  itac = "itac_ctrl",
  register = "registre",
}

const StyledItemGroup = styled(Menu.ItemGroup)`
  .ant-menu-item-group-title:after {
    content: "";
    display: block;
    border-bottom: 1px solid #eeeeee;
  }
`;

export const DocMenu = () => {
  const { pathname } = useLocation();

  return (
    <Menu mode="inline" selectedKeys={[pathname.slice(5)]}>
      <StyledItemGroup title="Débuter avec ShootRecord">
        <Menu.Item key={DocUrlRoutes.principe}>
          <Link to={DocUrlRoutes.principe}>Principe de Fonctionnement</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={DocUrlRoutes.tarifs}>Tarification</Link>
        </Menu.Item>
        <Menu.Item key={DocUrlRoutes.inscription}>
          <Link to={DocUrlRoutes.inscription}>S'inscrire</Link>
        </Menu.Item>
        <Menu.Item key={DocUrlRoutes.inscriptionClub}>
          <Link to={DocUrlRoutes.inscriptionClub}>Inscrire mon club</Link>
        </Menu.Item>
      </StyledItemGroup>
      <StyledItemGroup title="Fonctionnalités de base">
        <Menu.Item key={DocUrlRoutes.pointage}>
          <Link to={DocUrlRoutes.pointage}>Pointage membre</Link>
        </Menu.Item>
        <Menu.Item key={DocUrlRoutes.itac}>
          <Link to={DocUrlRoutes.itac}>Contrôle Itac</Link>
        </Menu.Item>
        <Menu.Item key={DocUrlRoutes.register}>
          <Link to={DocUrlRoutes.register}>Consultation du cahier</Link>
        </Menu.Item>
      </StyledItemGroup>
      {/*<Divider />
      <StyledItemGroup title="Gestion des membres">
        <Menu.Item key="4">Pointage Normal</Menu.Item>
        <Menu.Item key="5">Imports Itac</Menu.Item>
      </StyledItemGroup>*/}
    </Menu>
  );
};
