import React, { useEffect } from "react";
import { Button, Card, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AttendByLicenceMutation } from "../../api/gqlTypes";
import { ApolloError } from "@apollo/client";

type Props = {
  attendFeedback: ApolloError;
  setAttendFeedback(attendFeedback: AttendByLicenceMutation["attendByLicence"] | ApolloError | null): void;
};

export const AttendError = (props: Props) => {
  //10S time to live for erropr message
  useEffect(() => {
    const timeout = setTimeout(() => props.setAttendFeedback(null), 10000);
    return () => clearTimeout(timeout);
  }, [props]);

  return (
    <Card
      type="inner"
      size="small"
      title={
        <Tag icon={<ExclamationCircleOutlined />} color="error">
          Erreur :
        </Tag>
      }
      extra={
        <Button type="link" onClick={() => props.setAttendFeedback(null)}>
          Fermer
        </Button>
      }
    >
      <p>{props.attendFeedback.message}</p>
    </Card>
  );
};
