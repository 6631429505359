import { Button, Card, Col, DatePicker, Form, Input, notification, Row, Select } from "antd";
import React, { useEffect, useRef } from "react";
import moment from "moment";

import { Role, useAddUserMutation, useEditUserMutation, useUserFormQuery } from "../../api/gqlTypes";
import { FormInstance } from "antd/es/form";
import styled from "styled-components";

const { Option } = Select;

type Props = {
  userId: string;
  setForm(userId: string | null): void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SpredDatePicker = styled(DatePicker)`
  width: -webkit-fill-available;
  width: -moz-available;
`;

export const MemberForm = (props: Props) => {
  const { data } = useUserFormQuery({
    fetchPolicy: "no-cache",
    variables: { id: props.userId },
    skip: props.userId === "add",
  });

  //Adduser Mutation code
  const [addUser] = useAddUserMutation({
    refetchQueries: ["UsersTable"],
    onError: (err) => notification.error({ message: "Error : ", description: err.message }),
    onCompleted: () => {
      form.resetFields();
      notification.success({
        message: "Succès : ",
        description: "Nouvel utilisateur ajouté avec succès",
      });
    },
  });

  //EditUser  Mutation code
  const [editUser] = useEditUserMutation({
    refetchQueries: ["UsersTable"],
    onError: (err) => {
      notification.error({ message: "Error : ", description: err.message });
    },
    onCompleted: () => {
      notification.success({
        message: "Succès : ",
        description: "Modifications effectuées avec succès",
      });
      props.setForm(null);
    },
  });

  //deleteUser Mutation code
  /*const [removeUser] = useRemoveUserMutation({
    refetchQueries: ["UsersTable"],
    onError: (err) => {
      notification.error({ message: "Error", description: err.message });
    },
    onCompleted: () => {
      notification.success({
        message: "Succès",
        description: "Utilisateur supprimé!",
      });
      props.setForm(null);
    },
  });*/

  /*const [formDate, setFormDate] = useState(
    data ? moment(data?.user?.bdate).format("YYYY-MM-DD") : ""
  );*/
  const memberFormRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data && memberFormRef.current) {
      const memberFormValues = {
        lname: data.user.lname,
        fname: data.user.fname,
        licence: data.user.licence,
        username: data.user.username,
        email: data.user.emails[0]?.address,
        bdate: data.user.bdate ? moment(data?.user?.bdate) : undefined,
        roles: data.user.actualClubRoles,
      };
      //Object.assign({  }, memberFormValues);
      memberFormRef.current.setFieldsValue(memberFormValues);
    }
    if (props.userId === "add" && memberFormRef.current) {
      memberFormRef.current.resetFields();
      //memberFormRef.current.setFieldsValue({ bdate: null });
    }
  }, [data, props.userId]);

  type FormValues = {
    username: string;
    password: string;
    email: string;
    lname: string;
    fname: string;
    licence: string;
    bdate: moment.Moment;
    roles: Role[];
  };
  const onFinish = ({ username, password, email, lname, fname, licence, bdate, roles }: FormValues) => {
    if (
      data?.user?.username === username &&
      data?.user?.emails[0].address === email &&
      data?.user?.lname === lname &&
      data?.user?.fname === fname &&
      data?.user?.licence === parseInt(licence) &&
      data?.user?.bdate === (bdate?.toDate().toString() ?? null) &&
      (roles?.every((role, index) => role === data?.user.actualClubRoles[index]) ?? false) &&
      roles.length === data?.user.actualClubRoles.length &&
      !password
    ) {
      notification.warning({
        message: "Information: ",
        description: "Aucune modification effectuée",
      });
      props.setForm(null);
    } else {
      if (!data) {
        addUser({
          variables: {
            email: email,
            lname: lname,
            fname: fname,
            licence: parseInt(licence),
            bdate: bdate?.toDate().toString() ?? null,
            roles: roles || [],
          },
        });
      } else {
        editUser({
          variables: {
            id: props.userId,
            username: username,
            email: email,
            lname: lname,
            fname: fname,
            licence: parseInt(licence),
            bdate: bdate?.toDate().toString() ?? null,
            roles: roles || [],
          },
        });
        /*}*/
      }
    }
  };

  /*const onUserDelete = () => {
    removeUser({
      variables: {
        id: props.userId,
      },
    });
  };*/

  const authOptions = [
    { label: "Attesteur", value: Role.At },
    { label: "Registre", value: Role.Rg },
    { label: "Accès Membres", value: Role.Ma },
    { label: "Edit. membres", value: Role.Me },
    { label: "Ges. pas de tir", value: Role.Ra },
    { label: "Ges. armes de prêt", value: Role.Gu },
    { label: "Administrateur", value: Role.Ad },
  ];

  //const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Card title={data ? "Modifier ce tireur: " : "Ajouter un tireur:"} size="small">
        <Form
          {...layout}
          form={form}
          ref={memberFormRef}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //onFinishFailed={onFinishFailed}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Nom" name="lname" rules={[{ required: true, message: "Entrez le nom" }]}>
                <Input disabled={data?.user.trustedData} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Prénom" name="fname" rules={[{ required: true, message: "Entrez le prénom" }]}>
                <Input disabled={data?.user.trustedData} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="N° Licence"
                name="licence"
                getValueFromEvent={(value) => {
                  const res = Number(value.currentTarget.value);
                  if (res) return res;
                  return value.currentTarget.value;
                }}
                rules={[
                  { required: false, message: "Entrez le numéro de licence" },
                  {
                    type: "number",
                    message: "Un nombre ne peut comporter de lettres",
                  },
                ]}
              >
                <Input disabled={data?.user.trustedData} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Date de N." name="bdate" rules={[{ required: false, message: "Entrez une date de naissance" }]}>
                <SpredDatePicker disabled={data?.user.emails[0]?.verified && !!data?.user.passwords[0]} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Email" name="email" rules={[{ required: false, message: "Entrez un email" }, { type: "email" }]}>
                <Input disabled={data?.user.emails[0]?.verified && !!data?.user.passwords[0]} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Privilèges" name="roles">
                <Select mode="multiple" allowClear placeholder="Ajouter des droits" value={memberFormRef?.current?.getFieldValue("authlvl")}>
                  {authOptions.map((right) => (
                    <Option key={right.value} value={right.value}>
                      {right.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col offset={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Valider
                </Button>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  onClick={() => {
                    props.setForm(null);
                  }}
                >
                  Fermer
                </Button>
              </Form.Item>
            </Col>

            {/*            {props.userId !== "add" && !data?.user?.totalAttendCount && (
              <Col>
                <Form.Item>
                  <Button htmlType="button" onClick={() => onUserDelete()}>
                    Supprimer cet utilisateur
                  </Button>
                </Form.Item>
              </Col>
            )}*/}
          </Row>
        </Form>
      </Card>
    </div>
  );
};
