import styled from "styled-components";
import { Button, Card, Descriptions, Typography } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";

export const TableRowExpandedDescription = styled(Descriptions)`
  && > .ant-descriptions-view > table {
    width: 100%;
  }
`;

export const WidthLimitedCard = styled(Card)<{ width?: number }>`
  max-width: ${(p) => p.width || 1000}px;
`;

export const CenteredWidthLimitedCard = styled(WidthLimitedCard)`
  margin-right: auto;
  margin-left: auto;
`;

export const TopMargedCard = styled(Card)`
  margin-top: 16px;
`;

export const FormInsertParagraph = styled(Typography.Paragraph)`
  margin-bottom: 30px;
`;

export const LeftMargedButton = styled(Button)`
  margin-left: 10px;
`;

export const SmallMargedP = styled.section`
  margin-bottom: 0.5em;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: #e34850;
`;

export const LoadingCircle = styled(LoadingOutlined)`
  font-size: 24px;
`;

export const UnmargedButton = styled(Button)`
  padding: 0;
`;
