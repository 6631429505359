import { GunsQuery, useGunsQuery, useRemoveGunMutation } from "../../api/gqlTypes";
import { ColumnsType } from "antd/es/table";
import { Button, Card, notification, Popconfirm, Skeleton, Table, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import React from "react";
import { errNot, setCase, useSearchProps } from "../../Common";
import { Loader } from "../Loader";
import { DeleteIcon } from "../CommonStyledCMP";

type Props = {
  setForm(arg: string | null): void;
};

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
type inTableGun = ArrayElement<GunsQuery["guns"]>;

export const GunsTable = (props: Props) => {
  const { loading, data, error } = useGunsQuery();

  const getColumnsSearchProps = useSearchProps();

  const [removeGun] = useRemoveGunMutation({
    refetchQueries: ["Guns"],
    onCompleted: () =>
      notification.success({
        message: "Succès",
        description: "Arme supprimée!",
      }),
    onError: (err) => errNot(err),
  });

  const columns: ColumnsType<inTableGun> = [
    {
      title: "Marque",
      dataIndex: ["brand"],
      key: "brd",
      sorter: (a, b) => a.brand.localeCompare(b.brand),
      sortDirections: ["ascend", "descend"],
      ...getColumnsSearchProps(["brand"], "Marque:"),
    },
    {
      title: "Modèle",
      dataIndex: ["model"],
      key: "mod",
      sorter: (a, b) => a.model.localeCompare(b.model),
      sortDirections: ["ascend", "descend"],
      ...getColumnsSearchProps(["model"], "Modèle"),
    },
    {
      title: "N° série",
      dataIndex: ["serial"],
      key: "sn",
      sorter: (a, b) => a.serial.localeCompare(b.serial),
      sortDirections: ["ascend", "descend"],
      ...getColumnsSearchProps(["serial"], "N° série"),
    },
    {
      title: "Disponibilité",
      dataIndex: ["available"],
      key: "av",
      filters: [
        { text: "Disponible", value: "available" },
        { text: "Indisponible", value: "unavailable" },
        { text: "Emprunté", value: "borrowed" },
      ],
      onFilter: (value, record) => {
        switch (value) {
          case "available":
            return !record.borrower && record.available;
          case "unavailable":
            return !record.borrower && !record.available;
          case "borrowed":
            return !!record.borrower;
        }
      },
      render: (field, record) =>
        record.borrower ? (
          <Tag color="orange"> {record.borrower.lname + " " + setCase(record.borrower.fname)} </Tag>
        ) : field ? (
          <Tag color="green"> Disponible</Tag>
        ) : (
          <Tag color="red"> Non disponible</Tag>
        ),
    },
    {
      title: "Editer / Supprimer",
      dataIndex: "id",
      align: "center",
      render: (ammoId: string) => (
        <>
          <Button type="link" onClick={() => props.setForm(ammoId)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            placement="topLeft"
            title="Voulez-vous vraiment supprimer cette arme ?"
            onConfirm={() => {
              removeGun({ variables: { id: ammoId } });
            }}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">
              <DeleteIcon />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Card
      title="Gestion des armes"
      extra={
        <Button type="primary" onClick={() => props.setForm("add")}>
          Ajouter une Arme
        </Button>
      }
      size="small"
    >
      {data?.guns && <Table columns={columns} dataSource={data.guns} rowKey={(record) => record.id} />}
      {error && <Loader embedded={true} error={error} />}
      {loading && !data && <Skeleton />}
    </Card>
  );
};
