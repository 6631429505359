import React, { useState } from "react";
import { Col } from "antd";
import { RangesForm } from "./RangesForm";
import { RangesTable } from "./RangesTable";

export const RangesManager = () => {
  const [form, setForm] = useState<string | null>(null);
  return (
    <>
      {form && (
        <Col span={24}>
          <RangesForm rangeId={form} setForm={setForm} />
        </Col>
      )}
      <Col span={24}>
        <RangesTable setForm={setForm} />
      </Col>
    </>
  );
};
