import React from "react";
import { Button, Modal, Typography } from "antd";
import { useStore } from "../Common";

export const RGPD = () => {
  const [rgpd, setRgpd] = useStore("rgpd");

  return (
    <Modal
      visible={!rgpd}
      closable={false}
      footer={[
        <Button key="submit" type="primary" onClick={() => setRgpd(true)}>
          Accepter
        </Button>,
      ]}
    >
      <Typography.Title level={3}>Politique de confidentialité</Typography.Title>
      <Typography.Paragraph>
        ShootRecord est un service en ligne qui garantit la sécurité des données de ses membres. Les données suivantes sont conservées:
        <ul>
          <li>Nom</li>
          <li>Prénom</li>
          <li>N° Licence FFT</li>
          <li>Date de naissance</li>
          <li>Adresse email</li>
        </ul>
        <br />
        Aucune de ces données ne sera revendue ou distribuée sur internet. Les membres de SHootRecord peuvent à tout moment anonymiser leurs données
        (effacement du nom, prénom, et de la date de naissance de leur compte). Il suffit d'adresser une requête à l'administrateur du site.
      </Typography.Paragraph>

      <Typography.Title level={3}>ShootRecord utilise la mémoire de votre navigateur pour conserver les données suivantes:</Typography.Title>
      <Typography.Paragraph>
        <ul>
          <li>Un jeton de connexion permettant de savoir qui vous êtes.</li>
          <li>La taille des formulaires de pointage</li>
          <li>L'identifiant d'un contrôle Itac que vous devez valider</li>
          <li>L'acceptation de ce volet d'information RGPD</li>
        </ul>
      </Typography.Paragraph>
    </Modal>
  );
};
