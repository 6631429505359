import React from "react";
import { Avatar, Button, Card, List, notification, Popconfirm, Skeleton, Table, Tag } from "antd";
import { Loader } from "../Loader";

import { EditOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Caliber, RangesQuery, useRangesQuery, useRemoveRangeMutation } from "../../api/gqlTypes";
import { setCase } from "../../Common";
import dayjs from "dayjs";
import styled from "styled-components";
import { DeleteIcon } from "../CommonStyledCMP";

type Props = {
  setForm(arg: string | null): void;
};

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
type inTableRange = ArrayElement<RangesQuery["ranges"]>;
type allowedCaliber = { __typename?: "Caliber" } & Pick<Caliber, "id" | "caliberName">;

const RangeUserList: typeof List = styled(List)`
  margin-top: -16px;
  margin-bottom: -16px;
  padding-left: 50px;
`;

export const RangesTable = (props: Props) => {
  const columns: ColumnsType<inTableRange> = [
    {
      title: "Pas de tir",
      dataIndex: ["rangeName"],
      key: "cal",
    },
    {
      title: "Nb. postes",
      dataIndex: ["shootingStations"],
      key: "shStations",
    },
    {
      title: "Nb. dispo.",
      dataIndex: ["availableStations"],
      key: "avStations",
    },
    {
      title: "Calibres autorisés",
      dataIndex: ["allowedCalibers"],
      key: "allowedCals",
      render: (allowedCalibers) => allowedCalibers.map(({ id, caliberName }: allowedCaliber) => <Tag key={id}>{caliberName}</Tag>),
    },
    {
      title: "Editer / Supprimer",
      dataIndex: "id",
      align: "center",
      render: (rangeId: string) => (
        <>
          <Button type="link" onClick={() => props.setForm(rangeId)}>
            <EditOutlined />
          </Button>

          <Popconfirm
            placement="topLeft"
            title="Voulez-vous vraiment supprimer ce pas de tir ?"
            onConfirm={() => {
              removeRange({ variables: { id: rangeId } });
            }}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">
              <DeleteIcon />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const { loading, data, error } = useRangesQuery();

  const [removeRange] = useRemoveRangeMutation({
    refetchQueries: ["Ranges"],
    onCompleted: () =>
      notification.success({
        message: "Succès",
        description: "Pas de tir supprimé!",
      }),
    onError: (err) => notification.error({ message: "Erreur: ", description: err.message }),
  });
  return (
    <Card
      title="Gestion des pas de tir"
      extra={
        <Button type="primary" onClick={() => props.setForm("add")}>
          Ajouter un Pas de tir
        </Button>
      }
      size="small"
    >
      {data?.ranges && (
        <Table
          columns={columns}
          dataSource={data.ranges}
          rowKey={(record) => record.id}
          expandable={{
            rowExpandable: (record) => record.membersOnRange.length > 0,
            expandedRowRender: (record) => (
              <RangeUserList
                itemLayout="horizontal"
                dataSource={record.membersOnRange}
                renderItem={(member) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar children={member.lname.charAt(0) + member.fname.charAt(0)} />}
                      title={member.lname + " " + setCase(member.fname)}
                      description={
                        <>
                          Date d'arrivée:
                          {" " + dayjs(member.onGoingRegister?.beginDate).format("DD/MM/YYYY")}
                          &nbsp; &nbsp; Heure d'arrivée:
                          {" " + dayjs(member.onGoingRegister?.beginDate).format("HH:mm")} &nbsp; &nbsp;
                          {member.onGoingRegister?.usedGun
                            ? "Arme: " +
                              member.onGoingRegister.usedGun.brand +
                              " " +
                              member.onGoingRegister.usedGun.model +
                              " " +
                              member.onGoingRegister.usedGun.serial
                            : ""}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            ),
          }}
        />
      )}
      {error && <Loader embedded={true} error={error} />}
      {loading && !data && <Skeleton />}
    </Card>
  );
};
