import React, { useContext, useState } from "react";
import { Badge, Button, Card, Descriptions, Skeleton, Space, Table } from "antd";
import { CalendarOutlined, EditOutlined, LoginOutlined } from "@ant-design/icons";
import { useSearchProps } from "../../Common";
import { ItacChecksTimeline } from "./ItacChecksTimeline";
import { Loader } from "../Loader";
import { AttendByUserIdMutationVariables, Role, UsersTableQuery, useUsersTableQuery } from "../../api/gqlTypes";
import { ColumnType } from "antd/es/table";
import dayjs from "dayjs";
import { CurrentContext } from "../../App";
import { TableRowExpandedDescription } from "../CommonStyledCMP";
import { MemberTimelineDrawer } from "./MemberTimeline";

//Members Table

type Props = {
  form: string | null;
  setForm(id: string): void;
  attendByUserId(arg: { variables: AttendByUserIdMutationVariables }): any;
};

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
type inTableUser = ArrayElement<UsersTableQuery["users"]>;

export const MembersTable = (props: Props) => {
  const { currentRoles } = useContext(CurrentContext);

  const [timeline, setTimeline] = useState<string | null>(null);
  const [itacChecksTimeline, setItacChecksTimeline] = useState<string | null>(null);

  const getColumnSearchProps = useSearchProps();

  const { loading, error, data } = useUsersTableQuery();

  function editUser(value: string) {
    props.setForm(value);
  }

  const columns: ColumnType<inTableUser>[] = [
    {
      title: "Nom",
      dataIndex: ["lname"],
      key: "nom",
      sorter: (a, b) => a.lname.localeCompare(b.lname),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps(["lname"], "Nom"),
    },
    {
      title: "Prénom",
      dataIndex: ["fname"],
      key: "prénom",
      ...getColumnSearchProps(["fname"], "Prénom"),
    },
    {
      title: "N° Licence",
      dataIndex: ["licence"],
      key: "licence",
      sorter: (a, b) => {
        if (a.licence && b.licence) return a.licence - b.licence;
        return 0;
      },
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps(["licence"], "N° Licence"),
    },
    {
      title: "Date N.",
      dataIndex: ["bdate"],
      key: "bdate",
      render: (bdate: string) => (bdate ? new Date(bdate).toLocaleDateString("en-GB") : "- NC -"),
      sorter: (a, b) => {
        if (a.bdate && b.bdate) return new Date(a.bdate).getTime() - new Date(b.bdate).getTime();
        return 0;
      },
      sortDirections: ["ascend", "descend"],
    },

    {
      title: "Histo / Editer / Pointer",

      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id: string) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setTimeline(id);
            }}
          >
            <CalendarOutlined />
          </Button>
          <Button
            type="link"
            disabled={!currentRoles.includes(Role.Me)}
            onClick={() => {
              editUser(id);
            }}
          >
            <EditOutlined />
          </Button>

          <Button type="link" onClick={() => props.attendByUserId({ variables: { id: id, punch: false } })}>
            <LoginOutlined />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {timeline && <MemberTimelineDrawer userId={timeline} setTimeline={setTimeline} />}
      {itacChecksTimeline && <ItacChecksTimeline userId={itacChecksTimeline} setItacChecksTimeline={setItacChecksTimeline} />}
      <Card
        title="Gestion des tireurs"
        extra={
          currentRoles.includes(Role.Me) && (
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  props.setForm("add");
                }}
                disabled={props.form === "add"}
              >
                Ajouter un tireur
              </Button>
            </Space>
          )
        }
        size="small"
      >
        {loading && !data && <Skeleton active />}
        {error && <Loader embedded={true} error={error} />}
        {data && (
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => (
                <TableRowExpandedDescription column={3}>
                  <Descriptions.Item label="Pseudo">{record.username}</Descriptions.Item>
                  <Descriptions.Item label="Nombre de venues total">{record.totalAttendCount}</Descriptions.Item>
                  <Descriptions.Item label="Dernier contôle ITAC" span={1}>
                    {record.itacChecks?.length >> 0 ? (
                      <Button
                        type="link"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          height: "0px",
                        }}
                        onClick={() => setItacChecksTimeline(record.id)}
                      >
                        {dayjs(new Date(record.itacChecks[record.itacChecks.length - 1].checkDate)).format("DD/MM/YYYY HH:mm")}
                      </Button>
                    ) : (
                      "Jamais"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {record.emails[0]?.address ? (
                      <>
                        {record.emails[0].address + " "}
                        <Badge style={{ marginLeft: "5px" }} status={record.emails[0].verified ? "success" : "error"} />
                      </>
                    ) : (
                      "Pas d'email"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Nombre de venues A.E.C">{record.annualAttendCount}</Descriptions.Item>
                  <Descriptions.Item label="Nb contrôles ITAC A.E.C" span={1}>
                    {record.annualItacChecks.length}
                  </Descriptions.Item>
                </TableRowExpandedDescription>
              ),
              rowExpandable: (record) => !!record,
            }}
            dataSource={data.users}
          />
        )}
      </Card>
    </>
  );
};
