
import styled from "styled-components";
import { Alert, Typography } from "antd";

export const DocSection = styled.section`
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  height: calc(100% + 40px);
`;

export const WidthLimitedParagraph = styled(Typography.Paragraph)<{ width?: number }>`
  max-width: ${(p) => p.width || 800}px;
`;

export const WarnTag = styled(Alert)`
  margin-top: 10px;
  margin-bottom: 10px;
  width: fit-content;
`;
