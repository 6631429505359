import React, { useContext } from "react";
import { Alert, Checkbox, Col, Row, Typography } from "antd";
import { SubscrOption, useSwitchSubscriptionOptionMutation } from "../../api/gqlTypes";
import { CurrentContext } from "../../App";
import styled from "styled-components";

const OptSelectorCheckbox = styled(Checkbox)`
  line-height: 32px;
`;

export const ClubOptionSelector = () => {
  const current = useContext(CurrentContext);
  const [switchSubscrOption] = useSwitchSubscriptionOptionMutation({ refetchQueries: ["Current"] });

  return (
    <>
      <Alert message="Profitez d'un essai gratuit jusqu'au 31 mars 2021; vous pouvez essayer toutes les options que vous souhaitez pendant cette période!" />
      <br />
      <Alert message={<>La date de validité de votre abonnement s'affichera ici !</>} />
      <br />
      <Typography.Text>Activez / désactivez des options pendant toute la période d'essai ici:</Typography.Text>
      <br /> <br />
      <Checkbox.Group value={current.currentClub.actualOptions}>
        <Row>
          <Col span={12}>
            <OptSelectorCheckbox
              value={SubscrOption.Be}
              onClick={() => {
                switchSubscrOption({ variables: { option: SubscrOption.Be } });
              }}
            >
              Gestion des entrées et sorties
            </OptSelectorCheckbox>
          </Col>
          <Col span={12}>
            <OptSelectorCheckbox
              value={SubscrOption.Ra}
              onClick={() => {
                switchSubscrOption({ variables: { option: SubscrOption.Ra } });
              }}
            >
              Gestion des pas de tir
            </OptSelectorCheckbox>
          </Col>
          <Col span={12}>
            <OptSelectorCheckbox
              value={SubscrOption.Ca}
              onClick={() => {
                switchSubscrOption({ variables: { option: SubscrOption.Ca } });
              }}
            >
              Gestion de calibres (Pas de tir nécéssaires
            </OptSelectorCheckbox>
          </Col>
          <Col span={12}>
            <OptSelectorCheckbox
              value={SubscrOption.Gu}
              onClick={() => {
                switchSubscrOption({ variables: { option: SubscrOption.Gu } });
              }}
            >
              Gestion d'armes de prêt
            </OptSelectorCheckbox>
          </Col>
        </Row>
      </Checkbox.Group>
    </>
  );
};
