import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { readStore } from "./Common";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter } from "react-router-dom";

const RootDiv = createGlobalStyle`    
    div#root{
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }

    .ant-card{
      box-shadow: 0 2px 8px #ebebeb;
    }

    .ant-card-body > .ant-card {
      box-shadow: none;
    }
`;

const httpLink = createHttpLink({
  uri: "https://x4q8fkgpfg.execute-api.eu-west-3.amazonaws.com/prod/graphql",
  //uri: "http://127.0.0.1:4000/api",
});

const authLink = setContext((_, { headers }) => {
  const token = readStore("loginToken");
  return {
    headers: {
      ...headers,
      authorization: token ?? null,
    },
  };
});

export const apolloClient = new ApolloClient({
  uri: "https://x4q8fkgpfg.execute-api.eu-west-3.amazonaws.com/prod/graphql",
  //uri: "http://127.0.0.1:4000/api",
  credentials: "include",
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  link: authLink.concat(httpLink),
});

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <RootDiv />
      <App apolloClient={apolloClient} />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
