import React, { useContext, useEffect, useRef } from "react";
import { Alert, Button, Col, Form, Input, notification } from "antd";
import { useAddClubMutation, useEditClubMutation } from "../../api/gqlTypes";
import { LeftMargedButton, LoadingCircle, UnmargedButton, CenteredWidthLimitedCard } from "../CommonStyledCMP";
import styled from "styled-components";
import { CurrentContext } from "../../App";
import { FormInstance } from "antd/es/form";
import { useNavigate } from "react-router-dom";
import { errNot, UrlRoutes } from "../../Common";

export const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
export const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

type inputValue = string;

interface addClubValues {
  name: inputValue;
  fedNb: inputValue;
  street: inputValue;
  zip: inputValue;
  city: inputValue;
  country: inputValue;
}

const MargedAlert = styled(Alert)`
  margin-bottom: 30px;
`;

type Props = {
  edit: Boolean;
  setEdit?(arg: boolean): void;
};

export const ClubForm = (props: Props) => {
  const { currentClub } = useContext(CurrentContext);
  const clubFormRef = useRef<FormInstance>(null);
  const navigate = useNavigate();

  const [addClub, { loading: addClubLoading }] = useAddClubMutation({
    onCompleted: () => {
      notification.success({
        message: "Club",
        description: "Votre club de tir a été créé, vous en êtes le seul administrateur pour le moment",
      });

      navigate("/");
    },
    onError: (err) => errNot(err),
    refetchQueries: ["Current"],
  });

  const [editClub, { loading: editClubLoading }] = useEditClubMutation({
    onCompleted: () => {
      notification.success({
        message: "Club",
        description: "Vos modifications sont prises en compte",
      });
      props.setEdit(false);
    },
    onError: (err) => errNot(err),
    refetchQueries: ["Current"],
  });

  useEffect(() => {
    if (props.edit)
      clubFormRef.current.setFieldsValue({
        name: currentClub.name,
        fedNb: currentClub.fedNb,
        street: currentClub.address.street,
        zip: currentClub.address.zip,
        city: currentClub.address.city,
      });
  }, [currentClub, props.edit]);

  const onFinish = (values: addClubValues) => {
    if (props.edit)
      editClub({
        variables: {
          name: values.name,
          fedNb: parseInt(values.fedNb),
          street: values.street,
          zip: parseInt(values.zip),
          city: values.city,
          country: values.country,
        },
      });
    else
      addClub({
        variables: {
          name: values.name,
          fedNb: parseInt(values.fedNb),
          street: values.street,
          zip: parseInt(values.zip),
          city: values.city,
          country: values.country,
        },
      });
  };
  return (
    <Col span={24}>
      <CenteredWidthLimitedCard width={800} title={props.edit ? "Modification du club de tir" : "Nouveau club de tir:"}>
        {!props.edit && (
          <>
            <MargedAlert
              message="Matériel Requis:"
              description={
                <ul>
                  <li>Veuillez noter qu'il vous faudra impérativement un lecteur code-barres 1d et 2d pour utiliser correctement ShootRecord.</li>{" "}
                  <li>
                    {" "}
                    Vous pouvez me contacter{" "}
                    <UnmargedButton type="link" onClick={() => navigate("/" + UrlRoutes.Ct)}>
                      ici
                    </UnmargedButton>{" "}
                    pour aquérir un modèle compatible avec shootRecord.
                  </li>
                </ul>
              }
              type="warning"
              showIcon
            />
            <MargedAlert
              message="Ajout de club:"
              description="Après avoir ajouté votre club dans ShootRecord, vous en serez le seul administrateur. Vous pourrez donner des droits à d'autres personnes dans le tableau de membres."
              type="info"
              showIcon
            />
          </>
        )}
        <Form onFinish={onFinish} initialValues={{ country: "France" }} {...layout} ref={clubFormRef}>
          <Form.Item name="name" label="Nom du club:" rules={[{ required: true, message: "Veuillez renseigner le nom de votre club" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="fedNb" label="Numéro d'affiliation" rules={[{ required: true, message: "Veuillez renseigner le n° d'affiliation" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="street" label="Adresse" rules={[{ required: true, message: "Veuillez renseigner l'adresse de votre club" }]}>
            <Input placeholder="n° et rue" />
          </Form.Item>
          <Form.Item name="zip" label="Code postal" rules={[{ required: true, message: "Veuillez renseigner le code postal de votre club" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="city" label="Ville" rules={[{ required: true, message: "Veuillez renseigner la ville de votre club" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="country" label="Pays" rules={[{ required: true, message: "Veuillez renseigner le pays de votre club" }]}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            {addClubLoading || editClubLoading ? (
              <LoadingCircle spin />
            ) : (
              <>
                <Button type="primary" htmlType="submit">
                  Valider
                </Button>
                <LeftMargedButton
                  onClick={() => {
                    props.edit ? props.setEdit(false) : navigate(-1);
                  }}
                >
                  {props.edit ? "Annuler" : "Retour"}
                </LeftMargedButton>
              </>
            )}
          </Form.Item>
        </Form>
      </CenteredWidthLimitedCard>
    </Col>
  );
};
