import React from "react";
import { DocSection, WidthLimitedParagraph } from "./Reusable";
import { Typography } from "antd";
import { PriceMaker } from "../Landing/PriceMaker";

export const Tarification = () => {
  return (
    <DocSection>
      <Typography.Title level={2}> ShootRecord est -t- il payant? </Typography.Title>
      <WidthLimitedParagraph width={1200}>
        L'accès à shootRecord est gratuit. Tout tireur sportif affilié FFT peut s'inscrire et conserver gratuitement son compte sur shootRecord. La
        création d'un club sur ShootRecord est également gratuite, en revanche l'usage de l'inscription sur le cahier de présence est payant.
        <br />
        <br />
        <ul>
          <li>Vous ne payez qu'après avoir créé un club depuis un compte tireur.</li>
          <li>
            La prestation est facturée au Club et non au tireur. Si vous venez à céder votre place au comité, l'abonnement reste toujours valide, et
            un autre administrateur pourra le renouveller.
          </li>
          <li>Si nous ne renouvellez pas l'abonnement, votre club ne pourra plus inscrire de tireurs sur le cahier de présence</li>
          <li>Toutefois les données accumulées pendant toutes les periodes payées resteront consultables ad vitam æternam</li>
          <li>
            L'abonnement de base permet juste d'inscrire des tireus sur le cahier de présence sans tenir compte de l'heure de départ. Les options
            ajoutent d'autres fonctionalités décrites plus en détail dans cette documentation.
          </li>
        </ul>
      </WidthLimitedParagraph>
      <Typography.Title level={4}>Combien vais-je payer?</Typography.Title>
      <WidthLimitedParagraph>
        Le prix de ShootRecord dépend de options souscrites et de la durée de l'abonnement. Un simulateur de tarif vous est mis à disposition pour
        évaluer le budjet:
      </WidthLimitedParagraph>

      <PriceMaker />
      <br />
      <br />
    </DocSection>
  );
};
