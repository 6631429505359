import React, { useState } from "react";
import { Button, Descriptions, Form, Input, notification } from "antd";
import { GetConnexionQuery, useSetPwdMutation } from "../../api/gqlTypes";

import dayjs from "dayjs";
import { sha256 } from "js-sha256";
import { errNot } from "../../Common";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
`;

type Props = {
  current: GetConnexionQuery["current"];
};

export const SetPwd = (props: Props) => {
  const [pwdForm, setPwdForm] = useState(false);

  const [setPwd] = useSetPwdMutation({
    onCompleted: () => {
      notification.success({
        message: "Succès",
        description: "Votre mot de passe a bien été remplacé!",
      });
      setPwdForm(false);
    },
    onError: errNot,
  });

  const onFinish = (values: any) => {
    setPwd({
      variables: {
        oldPwdHash: sha256(values.oldPwd),
        newPwdHash: sha256(values.newPwd),
        newPwdHash2: sha256(values.newPwd2),
      },
    });
  };

  return !pwdForm ? (
    <Descriptions>
      <Descriptions.Item label="Depuis">
        {dayjs(props?.current?.user?.passwords?.[0]?.createdAt).format(
          "DD/MM/YYYY"
        )}
      </Descriptions.Item>
      <Descriptions.Item>
        <Button type="link" size="small" onClick={() => setPwdForm(true)}>
          Changer le mot de passe
        </Button>
      </Descriptions.Item>
    </Descriptions>
  ) : (
    <Form
      layout="inline"
      title="Remplacez votre mot de passe:"
      onFinish={onFinish}
    >
      <Form.Item name="oldPwd">
        <Input type="password" placeholder="Ancien mot de passe" />
      </Form.Item>
      <Form.Item name="newPwd">
        <Input type="password" placeholder="Nouveau mot de passe" />
      </Form.Item>
      <Form.Item name="newPwd2">
        <Input type="password" placeholder="Confirmation du mot de passe" />
      </Form.Item>

      <br />
      <ButtonsWrapper>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Valider
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            onClick={() => {
              setPwdForm(false);
            }}
          >
            Annuler
          </Button>
        </Form.Item>
      </ButtonsWrapper>
    </Form>
  );
  /*if (error) return <Loader type={"error"} error={error} />;
  return <Loader type="loader" />;*/
};
