import React from "react";
import { DatePicker, Descriptions, Typography } from "antd";
import { useGetConnexionQuery, useSetUserInfoMutation } from "../../api/gqlTypes";
import { Loader } from "../Loader";
import { errNot } from "../../Common";
import moment from "moment";
import styled from "styled-components";

const { Paragraph } = Typography;

const ProfileDatePicker = styled(DatePicker)`
  padding: 0;
  max-width: 100px;
`;

export const UserProfile = () => {
  const { data, error } = useGetConnexionQuery({
    onError: (err) => errNot(err),
  });

  const [setUserInfo] = useSetUserInfoMutation({
    onError: (err) => errNot(err),
    refetchQueries: ["GetUserInfo"],
  });

  const setLName = (value: string) => {
    setUserInfo({
      variables: {
        lname: value,
      },
    });
  };
  const setFName = (value: string) => {
    setUserInfo({
      variables: {
        fname: value,
      },
    });
  };
  const setBDate = (momentDate: moment.Moment) => {
    setUserInfo({
      variables: {
        bdate: momentDate?.toDate().toString() ?? null,
      },
    });
  };
  const setLicence = (value: string) => {
    const licNumber = parseInt(value);
    if (!licNumber) {
      errNot({
        message: "Votre numéro de licence ne doit comporter que des chiffres",
      });
    }

    setUserInfo({
      variables: {
        licence: licNumber,
      },
    });
  };

  if (data)
    return (
      <Descriptions column={2}>
        <Descriptions.Item label="Nom">
          <Paragraph editable={{ onChange: setLName }}>{data.current.user?.lname}</Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Prenom">
          <Paragraph editable={{ onChange: setFName }}>{data.current.user?.fname}</Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Date de naissance">
          {data.current.user ? (
            <ProfileDatePicker
              bordered={false}
              value={data.current.user.bdate ? moment(data.current.user.bdate) : undefined}
              onChange={(value) => setBDate(value)}
            />
          ) : (
            "NC"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Numéro de Licence FFT">
          <Paragraph editable={{ onChange: setLicence }}>{data.current.user?.licence.toString()}</Paragraph>
        </Descriptions.Item>
      </Descriptions>
    );
  if (error) return <Loader embedded={true} error={error} />;
  return <Loader embedded={true} />;
};
