import React, { useState } from "react";
import { AimOutlined, CalendarOutlined, ShopOutlined } from "@ant-design/icons";
import { setCase } from "../../Common";
import { Checkbox, DatePicker, Divider, Drawer, Skeleton, Tag, Timeline } from "antd";
import { UserTimelineQuery, useUserTimelineQuery } from "../../api/gqlTypes";
import { Loader } from "../Loader";
import moment from "moment";
import styled from "styled-components";
import { SmallMargedP } from "../CommonStyledCMP";

const { RangePicker } = DatePicker;

type Props = {
  userId: string;
  setTimeline(userId: string | null): void;
};

const StyledTimeline = styled(Timeline)`
  && li > .ant-timeline-item-label {
    width: calc(25% - 12px);
  }
  && li > .ant-timeline-item-tail {
    left: 25%;
  }
  && li > .ant-timeline-item-head {
    left: 25%;
  }
  &&& li > .ant-timeline-item-content {
    left: calc(25% - 4px);
    width: calc(75% - 14px);
    min-height: 10px;
  }
`;

export const MemberTimelineDrawer = (props: Props) => {
  const { loading, error, data } = useUserTimelineQuery({
    variables: { userId: props.userId },
  });

  return (
    <Drawer
      title={
        data ? (
          <>
            <CalendarOutlined /> {" Timeline de " + data.user.lname + " " + (data && setCase(data.user.fname))}
          </>
        ) : (
          <Skeleton />
        )
      }
      placement={"right"}
      width={512}
      visible={true}
      onClose={() => props.setTimeline(null)}
    >
      {loading && <Skeleton />}
      {error && <Loader embedded={true} error={error} />}
      {data && <MemberTimeline user={data.user} />}
    </Drawer>
  );
};

type MBTimelineProps = {
  user: UserTimelineQuery["user"];
};

export const MemberTimeline = (props: MBTimelineProps) => {
  const [wDetails, setWithDetails] = useState(true);
  return (
    <>
      <div>
        <span>Intervalle:</span> <RangePicker size="small" />{" "}
        <Checkbox children="Détails" checked={wDetails} onChange={() => setWithDetails(!wDetails)} />
      </div>
      <Divider />
      <StyledTimeline mode="left">
        {props.user.register.map((value, index) => {
          return (
            <Timeline.Item key={index} label={moment(value.beginDate).format("DD/MM/YYYY")}>
              {wDetails && (
                <>
                  {(value.usedRange || value.usedGun) && (
                    <SmallMargedP>
                      {value.usedRange && (
                        <>
                          <ShopOutlined />
                          {" " + value.usedRange.rangeName}
                        </>
                      )}
                      {value.usedRange && value.usedGun && <Divider type="vertical" />}
                      {value.usedGun && (
                        <>
                          {" "}
                          <AimOutlined /> {" " + [value.usedGun.brand, value.usedGun.model, value.usedGun.serial].join(" ")}
                        </>
                      )}
                    </SmallMargedP>
                  )}
                  {value.usedCalibers.length > 0 && (
                    <p>
                      {value.usedCalibers.map((caliber) => (
                        <Tag>{caliber.caliberName}</Tag>
                      ))}
                    </p>
                  )}
                </>
              )}
            </Timeline.Item>
          );
        })}
      </StyledTimeline>
    </>
  );
};

//Popover, if ever a time we can use it!!
/*<Popover
  content={
    <List
      itemLayout="horizontal"
      dataSource={[value.beginDate, value.endDate]}
      renderItem={(date, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar icon={index ? <LogoutOutlined /> : <LoginOutlined />} />}
            title={(index ? "Départ" : "Arrivée") + dayjs(date).format("HH:mm:ss")}
          />
        </List.Item>
      )}
    />
  }
></Popover>;*/
