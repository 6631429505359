import { useValidateEmailMutation } from "../../api/gqlTypes";
import { Result, Button } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  token: string;
};

export const ValidateEmail = (props: Props) => {
  const [validateEmail, { error, data, called }] = useValidateEmailMutation({ fetchPolicy: "no-cache", variables: { token: props.token } });
  const navigate = useNavigate();

  useEffect(() => {
    if (!called) validateEmail();
  }, [called, validateEmail]);

  useEffect(() => {
    if (data) {
      const timeout = setTimeout(() => {
        navigate("/");
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  });

  return (
    <Result
      title="Confirmation de votre adresse email"
      status={(error && "error") || (data && "success") || "info"}
      icon={(error && <CloseCircleOutlined />) || (data && <CheckCircleOutlined />) || <LoadingOutlined />}
      subTitle={
        (data && "Addresse vérifiée, vous allez être redirigé(e)...") || (error && error.message) || "Verification de votre email en cours..."
      }
      extra={<Button type="primary">Retour</Button>}
    />
  );
};
