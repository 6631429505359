import React, { useContext } from "react";
import { Dropdown, Menu, Tag } from "antd";
import { useStore } from "../../Common";
import { BankOutlined, CaretRightOutlined, DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CurrentContext } from "../../App";
import styled from "styled-components";
import { useSetCurrentClubMutation } from "../../api/gqlTypes";

//some types
interface MenuInfo {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
}

const ClubTag = styled(Tag)`
  font-size: 1.1em;
  line-height: 30px;
`;

export const ClubSelector = () => {
  const { clubs, currentClub, kiosk } = useContext(CurrentContext);

  const [, setToken] = useStore("loginToken");
  const [setCurrentClub] = useSetCurrentClubMutation({
    onCompleted: ({ setCurrentClub }) => {
      setToken(setCurrentClub);
    },
  });

  const selectClub = ({ key }: MenuInfo) => {
    if (key !== "add") setCurrentClub({ variables: { id: key.toString() } });
  };

  //generated menu for the dropDown
  const clubMenu = (
    <Menu onClick={selectClub}>
      {clubs &&
        clubs.map((club) => (
          <Menu.Item key={club.id} icon={<CaretRightOutlined />}>
            {club.name}
          </Menu.Item>
        ))}

      <Menu.Item key="add" icon={<BankOutlined />}>
        <Link to="/addClub">Ajouter un club</Link>
      </Menu.Item>
    </Menu>
  );

  return kiosk ? (
    <ClubTag color="processing">
      <BankOutlined /> {currentClub.name}
    </ClubTag>
  ) : (
    <Dropdown.Button overlay={clubMenu} icon={<DownOutlined style={{ margin: "auto" }} />}>
      {clubs.find((club) => club.id === currentClub?.id)?.name || "Sélection du club"}
    </Dropdown.Button>
  );
};
