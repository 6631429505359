import React from "react";
import { Card, Image, Space, Tabs, Typography } from "antd";
import point1 from "../../assets/point1.png";
import point2 from "../../assets/point2.png";
import rappelO from "../../assets/rappelO.png";
import rappel1 from "../../assets/rappel1.png";
import sortie from "../../assets/sortie.png";
import { DocSection, WarnTag, WidthLimitedParagraph } from "./Reusable";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Meta } = Card;
const { TabPane } = Tabs;

export const Pointage = () => {
  return (
    <DocSection>
      <Typography.Title level={2}>1. Etape:</Typography.Title>
      <WidthLimitedParagraph>
        Cliquez sur l'onglet "inscrire au Registre" dans le menu supérieur pour accéder à la page de badgeage.
        <WarnTag
          icon={<ExclamationCircleOutlined />}
          message="La page est accessible seulement si vous gérez un club et que ce dernier est sélectionné"
          type="warning"
          showIcon
        />
        <Card cover={<Image alt="point1" src={point1} />}>
          <Meta description="Prodécure de pointage" />
        </Card>
        <br />
        <br />
        Une fois sur la page, le formulaire capture automatiquement le curseur et attend que vous scanniez une licence, soit par le code barre 1d,
        soit par le Qr-code
      </WidthLimitedParagraph>
      <Typography.Title level={2}>2. Etape:</Typography.Title>
      <WidthLimitedParagraph>
        Une fois le code-barres scanné, le formulaire se complète automatiquement.
        <Tabs type="line" defaultActiveKey="S">
          <TabPane tab="Sans options" key="S">
            <>
              Vous n'avez plus rien à faire. Une fenêtre de rappel s'ouvre pour vous informer du bon fonctionnement de l'opération, et redonne des
              informations sur le tireur qui a pointé. Cette fenêtre se ferme automatiquement au bout de 20 secondes.
              <br />
              <br />
              <Card cover={<Image src={point2} width="800px" />}>
                <Meta description="Ecran de rappel après un pointage" />
              </Card>
            </>
          </TabPane>
          <TabPane tab="Avec options" key="O">
            <>
              Selon les options souscrites avec ShootRecord, un fomulaire s'ouvre lorsque vous scannez une liccene:
              <Space direction="vertical">
                <ul>
                  <li>Vous devez renseigner le pas de tir emprunté par le tireur pour l'option Pas de tir (1).</li>
                  <li>Vous devez renseigner le(s) calibre(s) utilisé(s) par le tireur pour l'option calibre (2).</li>
                </ul>
                <Card hoverable cover={<Image src={rappelO} />} style={{ width: "70%" }}>
                  <Meta description="Remplir le formulaire et valider suffit à enregistrer la présence sur le registre" />
                </Card>
                <ul>
                  <li>Après validation, une fenêtre vous confirme l'inscription au registre</li>
                </ul>
                <Card hoverable cover={<Image src={rappel1} />} style={{ width: "70%" }}>
                  <Meta description="Une fois le questionnaire rempli, le pointage est pris en compte" />
                </Card>
                <ul>
                  <li>
                    Si vous avez souscrit à l'option entrées/sorties, il suffit de rescanner la licence d'un tireur ayant scanné au préalable pour
                    ajouter son heure de départ au registre.
                  </li>
                </ul>
                <Card hoverable cover={<Image src={sortie} />} style={{ width: "70%" }}>
                  <Meta description="Rescan de la licence pour quitter le club" />
                </Card>
              </Space>
            </>
          </TabPane>
        </Tabs>
      </WidthLimitedParagraph>
    </DocSection>
  );
};
