import React, { useState } from "react";

import { AttendByLicenceMutation } from "../../api/gqlTypes";
import { ApolloError } from "@apollo/client";
import { useStore } from "../../Common";
import { RegisterTable } from "./RegisterTable";
import { AttendResult } from "../Attend/AttendResult";
import { Col } from "antd";

export const RegisterManager = () => {
  //feedback of attend or pre-attend
  const [attendFeedback, setAttendFeedback] = useState<AttendByLicenceMutation["attendByLicence"] | ApolloError | null | undefined>(null);
  const [inputSize] = useStore<boolean>("inputSize");
  //UID of the itacQuery to validate
  return (
    <>
      {attendFeedback && !(attendFeedback instanceof ApolloError) && (
        <AttendResult attendFeedback={attendFeedback} setAttendFeedback={setAttendFeedback} inputSize={inputSize} />
      )}
      <Col span={24}>
        <RegisterTable today={false} setAttendFeedback={setAttendFeedback} />
      </Col>
    </>
  );
};
