import React from "react";
import { Calendar, Card, Col, Skeleton, Tag } from "antd";
import { Loader } from "../Loader";
import { useByDateRegisterQuery, useByMonthRegisterQuery } from "../../api/gqlTypes";
import moment from "moment";
import styled from "styled-components";


const AttendTag = styled(Tag)`
text-align: right;
`

export const RegisterCalendar = () => {
  const byDateReg = useByDateRegisterQuery();
  const byMonthReg = useByMonthRegisterQuery();

  /*  const dayRegister = useByDateRegisterLazyQuery({
    fetchPolicy: "no-cache",
  });*/

  const dateCellRender = (date: moment.Moment) => {
    const dayAttend = byDateReg.data?.byDateRegister.find((item) => date.isSame(item.date, "day"));
    if (dayAttend)
      return (
        <AttendTag color="green" >
          {dayAttend.count + " présents"}
        </AttendTag>
      );
  };

  const monthCellRender = (month: moment.Moment) => {
    const monthAttend = byMonthReg.data?.byMonthRegister.find((item) => month.isSame(item.month, "month"));
    if (monthAttend)
      return (
        <AttendTag color="green" >
          {monthAttend.count + " présents"}
        </AttendTag>
      );
  };

  return (
    <Col span={24}>
      <Card title="Répartition des présences par dates:">
        {byDateReg.data && byMonthReg.data && <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />}
        {(byDateReg.loading || byMonthReg.loading) && <Skeleton active />}
        {(byDateReg.error || byMonthReg.error) && <Loader embedded={true} error={byDateReg.error || byMonthReg.error} />}
      </Card>
    </Col>
  );
};
