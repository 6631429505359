import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Input, notification, Row, Transfer } from "antd";

import { errNot, layout, tailLayout } from "../../Common";
import { FormInstance } from "antd/lib/form";
import { useAddRangeMutation, useEditRangeMutation, useRangeFormCalListQuery, useRangeQuery } from "../../api/gqlTypes";

type Props = {
  rangeId: string;
  setForm(arg: string | null): void;
};

export const RangesForm = (props: Props) => {
  const [form] = Form.useForm();
  const caliberFormRef = useRef<FormInstance>(null);
  const [allowedCalibers, setAllowedCalibers] = useState<string[]>([]);

  useEffect(() => {
    if (props.rangeId === "add") form.resetFields();
  }, [props.rangeId, form]);

  useRangeQuery({
    variables: { id: props.rangeId },
    skip: props.rangeId === "add",
    onCompleted: (data) => {
      caliberFormRef.current?.setFieldsValue({
        rangeName: data.range.rangeName,
        shootingStations: data.range.shootingStations,
      });
      setAllowedCalibers(data.range.allowedCalibers.map(({ id }) => id));
    },
  });

  const allCalibers = useRangeFormCalListQuery();

  const [addRange] = useAddRangeMutation({
    refetchQueries: ["Ranges", "Calibers"],
    onCompleted: () => {
      notification.success({
        message: "Succès: ",
        description: "Pas de tir ajouté.",
      });
      form.resetFields();
    },
    onError: (err) => errNot(err),
  });

  const [editRange] = useEditRangeMutation({
    refetchQueries: ["Ranges", "Calibers"],
    onCompleted: () => {
      notification.success({
        message: "Succès: ",
        description: "Pas de tir modifié.",
      });
      props.setForm(null);
    },
    onError: (err) => errNot(err),
  });

  const handleAllowedCalibers = (newAllowedCalibers: string[]) => {
    setAllowedCalibers(newAllowedCalibers);
  };

  const onFinish = (values: { rangeName: string; shootingStations: string }) => {
    if (props.rangeId === "add") {
      addRange({
        variables: {
          rangeName: values.rangeName,
          shootingStations: parseInt(values.shootingStations),
          allowedCalibers: allowedCalibers.map((caliber) => ({
            id: caliber,
          })),
        },
      });
    } else {
      editRange({
        variables: {
          id: props.rangeId,
          rangeName: values.rangeName,
          shootingStations: parseInt(values.shootingStations),
          allowedCalibers: allowedCalibers.map((caliber) => ({
            id: caliber,
          })),
        },
      });
    }
  };

  return (
    <Card title={props.rangeId === "add" ? "Ajouter un pas de tir :" : "Editer ce pas de tir :"} size="small">
      <Form {...layout} name="SRForm" onFinish={onFinish} form={form} ref={caliberFormRef}>
        <Form.Item label="Dénomination du pas de tir" name="rangeName" rules={[{ required: true, message: "Une dénomination est requise!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Nombre de postes:"
          name="shootingStations"
          getValueFromEvent={(value) => {
            const res = Number(value.currentTarget.value);
            if (res) return res;
            return value.currentTarget.value;
          }}
          rules={[
            { required: true, message: "Le nombre de postes est requis" },
            {
              type: "number",
              message: "Un nombre ne peut comporter de lettres",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Calibres autorisés" name="allowedCalibers">
          <Transfer
            dataSource={allCalibers?.data?.calibers}
            targetKeys={allowedCalibers}
            listStyle={{
              width: 250,
              height: 300,
            }}
            onChange={handleAllowedCalibers}
            operations={["Autoriser", "Interdire"]}
            render={(item) => item.caliberName}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Row gutter={[1, 0]}>
            <Col offset={0}>
              <Button type="primary" htmlType="submit">
                {props.rangeId === "add" ? "Ajouter" : "Modifier"}
              </Button>
            </Col>
            <Col offset={1}>
              <Button htmlType="button" onClick={() => props.setForm(null)}>
                Fermer
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
