import React from "react";
import { Col, Divider, Row, Skeleton } from "antd";
import { ConnexionManager } from "./ConnexionManager";
import { EmailManager } from "./EmailManager";
import { SetPwd } from "./SetPwd";
import { UserProfile } from "./UserProfile";
import { useGetConnexionQuery } from "../../api/gqlTypes";
import { CenteredWidthLimitedCard } from "../CommonStyledCMP";

export const UserParams = () => {
  const { data } = useGetConnexionQuery();

  return (
    <Col span={24}>
      <CenteredWidthLimitedCard>
        <Row gutter={[40, 40]}>
          <Col span={24}>
            <Divider orientation="left">A propos de Moi:</Divider>
            {data ? <UserProfile /> : <Skeleton />}
          </Col>
          <Col span={24}>{data ? <EmailManager current={data.current} /> : <Skeleton />}</Col>
          <Col span={24}>
            <Divider orientation="left">Mot de passe</Divider>
            {data ? <SetPwd current={data.current} /> : <Skeleton />}
          </Col>
          <Col span={24}>
            <Divider orientation="left">Gérer mes connexions</Divider>
            {data?.current.user?.credentials ? <ConnexionManager credentials={data.current.user.credentials} /> : <Skeleton />}
          </Col>
        </Row>
      </CenteredWidthLimitedCard>
    </Col>
  );
};
