import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Button, Input, notification, Space } from "antd";
import { ColumnFilterItem } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { createStore } from "react-stored";
import { ApolloError } from "@apollo/client";

export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

//
export enum UrlRoutes {
  At = "attend",
  Rg = "register",
  Ca = "calendar",
  Mb = "members",
  Cal = "calibers",
  Ra = "ranges",
  Ke = "keys",
  Gu = "guns",
  Sm = "summary",
  Us = "user",
  Si = "signin",
  Su = "signup",
  Dc = "doc",
  Cm = "ClubMgmt",
  Ev = "emailval",
  Cc = "addClub",
  Lp = "landing",
  Ct = "contact",
}

//Error notification
export const errNot = (err: ApolloError | { message: string }) => {
  notification.error({
    message: "Erreur",
    description: err.message,
  });
};

export const { useStore, readStore } = createStore({
  keyPrefix: "sr_",
  schemas: [
    {
      key: "rgpd",
      init: false,
      assert: (data) => typeof data === "boolean",
    },
    {
      key: "loginToken",
      init: null,
      assert: (data) => typeof data === "string",
    },
    {
      key: "itacValidator",
      init: null,
      assert: (data) => typeof data === "string",
    },
    {
      key: "inputSize",
      init: false,
      assert: (data) => typeof data === "boolean",
    },
  ],
});

//Check for array intersections and return boolean value.
export function arrInter(ar1: Array<string | number>, ar2: Array<string | number>): boolean {
  for (const element of ar1) {
    if (ar2.includes(element)) return true;
  }
  return false;
}

//React hook that makes search box on table components.
//types:
export interface FilterDropdownProps {
  prefixCls: string;
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  selectedKeys: React.Key[];
  confirm: () => void;
  clearFilters: () => void;
  filters?: ColumnFilterItem[];
  visible: boolean;
}

export function wDecimalRound(value: number, nbOfdec: number) {
  return Math.round(10 ** nbOfdec * value) / 10 ** nbOfdec;
}

export const useElementSize = (ref: React.MutableRefObject<any>) => {
  const [size, setSize] = useState({ height: 0, width: 0 });

  useEffect(() => {
    if (ref.current) setSize({ height: 0.7 * ref.current.clientWidth, width: ref.current.clientWidth });
  }, [ref?.current?.clientHeight, ref?.current?.clientWidth, ref]);
  return size;
};

//Hook starts here
export function useSearchProps() {
  const [searchText, setSearchText] = useState<React.Key>("");
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const searchInput = useRef<Input>(null);

  const handleSearch = (selectedKeys: React.Key[], confirm: () => void, dataIndex: string) => {
    confirm();

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  return ([dataIndex, subIndex]: string[], inputName: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Chercher ${inputName ? inputName : subIndex ? subIndex : dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 95 }}
            >
              Chercher
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 85 }}>
              Effacer
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: ReactNode): ReactNode => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: string | number | boolean, record: any) =>
      subIndex
        ? record[dataIndex][subIndex]
            ?.toString()
            ?.toLowerCase()
            ?.includes(typeof value === "string" ? value.toLowerCase() : "")
        : record[dataIndex]
            ?.toString()
            ?.toLowerCase()
            ?.includes(typeof value === "string" ? value.toLowerCase() : ""),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select());
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText.toString()]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : text === null ? (
        "-- Aucun --"
      ) : (
        text
      ),
  });
}
//function to set uppercase for First character of a string
export function setCase(string: string): string {
  return string
    .split("")
    .map((chr, ind) => {
      return ind < 1 ? chr.toUpperCase() : chr.toLowerCase();
    })
    .join("");
}
