import { Button, Checkbox, Col, Divider, Row, Slider, Typography } from "antd";
import React, { useReducer, useState } from "react";
import styled from "styled-components";
import { UrlRoutes } from "../../Common";
import { useNavigate } from "react-router-dom";
import { WidthLimitedCard } from "../CommonStyledCMP";

const OptSelectorCheckbox = styled(Checkbox)`
  line-height: 32px;
`;

const SpreadCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const reducer = (state: boolean) => {
  return !state;
};

const tooltipFormatter = (val: number) => {
  switch (val) {
    case 1:
      return "Abonnement de 1 mois";
    case 4:
      return "Abonnement quadrimestriel";
    case 6:
      return "Abonnement semestriel";
    case 12:
      return "Abonnement annuel";
  }
};

export const PriceMaker = () => {
  const navigate = useNavigate();
  const [duration, setDuration] = useState(12);
  const [guns, setGuns] = useReducer(reducer, true);
  const [end, setEnd] = useReducer(reducer, true);
  const [ranges, setRanges] = useReducer(reducer, true);
  const [calibers, setCalibers] = useReducer(reducer, true);
  const marks = {
    1: "1 mois",
    4: "4 mois",
    6: "6 mois",
    12: "1 an",
  };

  return (
    <WidthLimitedCard width={1200} title="Offres:">
      <Slider
        step={null}
        marks={marks}
        min={1}
        max={12}
        tipFormatter={tooltipFormatter}
        defaultValue={100}
        onChange={(val: number) => setDuration(val)}
      />
      <br />
      <Divider />
      <Row>
        <Col span={12}>
          <OptSelectorCheckbox value={true} disabled defaultChecked={true}>
            ShootRecord, base
          </OptSelectorCheckbox>
        </Col>
        <Col span={6}>
          {duration < 12 ? (
            "30€ / mois"
          ) : (
            <>
              <Typography.Text delete>30€/mois</Typography.Text> 28.5€/mois
            </>
          )}
        </Col>
        <Col span={6}>ss total: {duration > 12 ? duration * 30 : duration * 30 * 0.95} €</Col>

        <Col span={12}>
          <OptSelectorCheckbox
            checked={end}
            onClick={() => {
              setEnd();
            }}
          >
            Gestion des entrées et sorties
          </OptSelectorCheckbox>
        </Col>
        <Col span={6}>2€ / mois</Col>
        <Col span={6}>ss total: {end ? duration * 2 : 0} €</Col>

        <Col span={12}>
          <OptSelectorCheckbox
            checked={ranges}
            onClick={() => {
              setRanges();
            }}
          >
            Gestion des pas de tir
          </OptSelectorCheckbox>
        </Col>
        <Col span={6}>5€ / mois</Col>
        <Col span={6}>ss total: {ranges ? duration * 5 : 0} €</Col>

        <Col span={12}>
          <OptSelectorCheckbox
            checked={calibers}
            onClick={() => {
              setCalibers();
            }}
          >
            Gestion de calibres (Pas de tir nécessaires)
          </OptSelectorCheckbox>
        </Col>
        <Col span={6}>5€ / mois</Col>
        <Col span={6}>ss total: {calibers ? duration * 5 : 0} €</Col>

        <Col span={12}>
          <OptSelectorCheckbox
            checked={guns}
            onClick={() => {
              setGuns();
            }}
          >
            Gestion d'armes de prêt
          </OptSelectorCheckbox>
        </Col>
        <Col span={6}>5€ / mois</Col>
        <Col span={6}>ss total: {guns ? duration * 5 : 0} €</Col>
        <Divider />
        <SpreadCol span={24}>
          <Typography.Paragraph style={{ width: "fit-content" }}>
            Total: {duration * ((guns ? 5 : 0) + (calibers ? 5 : 0) + (end ? 2 : 0) + (ranges ? 5 : 0) + (duration < 12 ? 30 : 30 * 0.95))}
            {" €"}
          </Typography.Paragraph>
          <Button
            type="primary"
            onClick={() => {
              navigate("/" + UrlRoutes.Su);
            }}
          >
            Démarrer l'essai gratuit de 60 jours!
          </Button>
        </SpreadCol>
      </Row>
    </WidthLimitedCard>
  );
};
