import React, { useRef } from "react";
import { Axis, Chart, Geom, Tooltip } from "bizcharts";
import { Col } from "antd";
import { UserStatsQuery } from "../../api/gqlTypes";
import { useElementSize } from "../../Common";

type Props = {
  userStats: UserStatsQuery["userStats"];
};

const cols = {
  club: {
    tickInterval: 20,
  },
};

export const AttendBarGraph = (props: Props) => {
  const ref = useRef(null);
  const size = useElementSize(ref);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} ref={ref}>
      <Chart height={size.height - 30} data={props.userStats} scale={cols} forceFit>
        <Axis name="club" />
        <Axis name="count" />
        <Tooltip
        // crosshairs用于设置 tooltip 的辅助线或者辅助框
        // crosshairs={{
        //  type: "y"
        // }}
        />
        <Geom type="interval" position="club*count" />
      </Chart>
    </Col>
  );
};
