import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Caliber = {
  __typename?: 'Caliber';
  id: Scalars['ID'];
  key: Scalars['ID'];
  caliberName: Scalars['String'];
  allowedRanges: Array<Range>;
  club: Club;
};

export type Query = {
  __typename?: 'Query';
  availableGuns: Array<Gun>;
  availableRanges: Array<Range>;
  byCaliberRanges: Array<Range>;
  byDateRegister: Array<ByDateRegister>;
  byMonthRegister: Array<ByMonthRegister>;
  byRangeCalibers: Array<Caliber>;
  caliber?: Maybe<Caliber>;
  calibers: Array<Caliber>;
  club: Club;
  clubs: Array<Maybe<Club>>;
  current: Current;
  emailValFromToken: EmailValidation;
  gun: Gun;
  guns: Array<Gun>;
  itacQueries: Array<ItacQuery>;
  itacQuery: ItacQuery;
  range: Range;
  ranges: Array<Range>;
  register: Array<Register>;
  registerDoc: Register;
  ticket: Ticket;
  tickets: Array<Ticket>;
  user: User;
  userExists: Scalars['Int'];
  userStats: Array<UserByClubReg>;
  users: Array<User>;
  usersCount: Scalars['Int'];
};


export type QueryByCaliberRangesArgs = {
  caliberId: Scalars['ID'];
};


export type QueryByDateRegisterArgs = {
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryByMonthRegisterArgs = {
  beginDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryByRangeCalibersArgs = {
  rangeId: Scalars['ID'];
};


export type QueryCaliberArgs = {
  id: Scalars['ID'];
};


export type QueryClubArgs = {
  id: Scalars['ID'];
};


export type QueryEmailValFromTokenArgs = {
  token: Scalars['String'];
};


export type QueryGunArgs = {
  id: Scalars['ID'];
};


export type QueryItacQueryArgs = {
  id: Scalars['ID'];
};


export type QueryRangeArgs = {
  id: Scalars['ID'];
};


export type QueryRegisterArgs = {
  date?: Maybe<Scalars['String']>;
  enroute?: Maybe<Scalars['Boolean']>;
};


export type QueryRegisterDocArgs = {
  id: Scalars['String'];
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserExistsArgs = {
  licence: Scalars['Int'];
};


export type QueryUserStatsArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type AllowedRange = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCaliber?: Maybe<Caliber>;
  addClub: Club;
  addGun: Gun;
  addRange: Range;
  addUser: User;
  answerTicket: Scalars['Boolean'];
  attendByLicence: AttendFeedBack;
  attendByUserId: AttendFeedBack;
  closeKiosk: Scalars['String'];
  editCaliber?: Maybe<Caliber>;
  editClub?: Maybe<Club>;
  editGun: Gun;
  editRange: Scalars['Boolean'];
  editUser: User;
  login: Scalars['String'];
  logout: Scalars['Boolean'];
  newItacQuery: Scalars['String'];
  openKiosk: Scalars['String'];
  openTicket: Scalars['Boolean'];
  removeCaliber?: Maybe<Scalars['Boolean']>;
  removeConnection: Scalars['Boolean'];
  removeGun: Scalars['Boolean'];
  removeRange: Scalars['Boolean'];
  removeUser: Scalars['Boolean'];
  resetPwd: Scalars['String'];
  sendResetPwdEmail: Scalars['Boolean'];
  sendValidationEmail?: Maybe<Scalars['Boolean']>;
  setCurrentClub: Scalars['String'];
  setPwd: Scalars['Boolean'];
  setUserInfo?: Maybe<Scalars['Boolean']>;
  signUp: Scalars['String'];
  switchSubscriptionOption: Scalars['Boolean'];
  validateEmail: Scalars['Boolean'];
  validateQuery?: Maybe<AttendFeedBack>;
};


export type MutationAddCaliberArgs = {
  caliberName: Scalars['String'];
  allowedRanges: Array<AllowedRange>;
};


export type MutationAddClubArgs = {
  name: Scalars['String'];
  fedNb: Scalars['Int'];
  street: Scalars['String'];
  zip: Scalars['Int'];
  city: Scalars['String'];
  country: Scalars['String'];
};


export type MutationAddGunArgs = {
  brand: Scalars['String'];
  model: Scalars['String'];
  serial: Scalars['String'];
  available: Scalars['Boolean'];
};


export type MutationAddRangeArgs = {
  rangeName: Scalars['String'];
  shootingStations: Scalars['Int'];
  allowedCalibers: Array<AllowedCaliber>;
};


export type MutationAddUserArgs = {
  fname: Scalars['String'];
  lname: Scalars['String'];
  bdate?: Maybe<Scalars['String']>;
  licence?: Maybe<Scalars['Int']>;
  roles: Array<Role>;
  email: Scalars['String'];
};


export type MutationAnswerTicketArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationAttendByLicenceArgs = {
  licNumber: Scalars['Int'];
};


export type MutationAttendByUserIdArgs = {
  id: Scalars['ID'];
  usedRange?: Maybe<Scalars['ID']>;
  usedCalibers?: Maybe<Array<UsedCaliber>>;
  usedGun?: Maybe<Scalars['ID']>;
  punch: Scalars['Boolean'];
};


export type MutationCloseKioskArgs = {
  pwdHash: Scalars['String'];
};


export type MutationEditCaliberArgs = {
  id: Scalars['ID'];
  caliberName: Scalars['String'];
  allowedRanges: Array<AllowedRange>;
};


export type MutationEditClubArgs = {
  name: Scalars['String'];
  fedNb: Scalars['Int'];
  street: Scalars['String'];
  zip: Scalars['Int'];
  city: Scalars['String'];
  country: Scalars['String'];
};


export type MutationEditGunArgs = {
  id: Scalars['ID'];
  brand: Scalars['String'];
  model: Scalars['String'];
  serial: Scalars['String'];
  available: Scalars['Boolean'];
};


export type MutationEditRangeArgs = {
  id: Scalars['ID'];
  rangeName: Scalars['String'];
  allowedCalibers: Array<AllowedCaliber>;
  shootingStations: Scalars['Int'];
};


export type MutationEditUserArgs = {
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  bdate?: Maybe<Scalars['String']>;
  licence?: Maybe<Scalars['Int']>;
  roles: Array<Role>;
  email?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  username: Scalars['String'];
  pwdHash: Scalars['String'];
};


export type MutationNewItacQueryArgs = {
  link: Scalars['String'];
};


export type MutationOpenTicketArgs = {
  email?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  message: Scalars['String'];
};


export type MutationRemoveCaliberArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveConnectionArgs = {
  connectionId: Scalars['ID'];
};


export type MutationRemoveGunArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveRangeArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
};


export type MutationResetPwdArgs = {
  token: Scalars['String'];
  pwdHash: Scalars['String'];
  pwdHash2: Scalars['String'];
};


export type MutationSendResetPwdEmailArgs = {
  username: Scalars['String'];
  reset: Scalars['Boolean'];
};


export type MutationSendValidationEmailArgs = {
  address?: Maybe<Scalars['String']>;
};


export type MutationSetCurrentClubArgs = {
  id: Scalars['ID'];
};


export type MutationSetPwdArgs = {
  oldPwdHash: Scalars['String'];
  newPwdHash: Scalars['String'];
  newPwdHash2: Scalars['String'];
};


export type MutationSetUserInfoArgs = {
  lname?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  bdate?: Maybe<Scalars['String']>;
  licence?: Maybe<Scalars['Int']>;
};


export type MutationSignUpArgs = {
  username?: Maybe<Scalars['String']>;
  pwdHash: Scalars['String'];
  pwdHash2: Scalars['String'];
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  licence?: Maybe<Scalars['Int']>;
  itacLink?: Maybe<Scalars['String']>;
};


export type MutationSwitchSubscriptionOptionArgs = {
  option: SubscrOption;
};


export type MutationValidateEmailArgs = {
  token: Scalars['String'];
};


export type MutationValidateQueryArgs = {
  queryId: Scalars['ID'];
  punch: Scalars['Boolean'];
};

export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  zip: Scalars['Int'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type Manager = {
  __typename?: 'Manager';
  userId: Scalars['ID'];
  roles: Array<Role>;
};

export enum SubscrOption {
  Be = 'BE',
  Ca = 'CA',
  Ra = 'RA',
  Gu = 'GU',
  Ke = 'KE'
}

export type Subscription = {
  __typename?: 'Subscription';
  expDate: Scalars['String'];
  beginDate: Scalars['String'];
  options: Array<SubscrOption>;
};

export enum Role {
  At = 'AT',
  Rg = 'RG',
  Cm = 'CM',
  Gu = 'GU',
  Ra = 'RA',
  Ma = 'MA',
  Me = 'ME',
  Ad = 'AD'
}

export type Club = {
  __typename?: 'Club';
  id: Scalars['ID'];
  fedNb: Scalars['Int'];
  name: Scalars['String'];
  address: Address;
  managers: Array<Manager>;
  subscriptions: Array<Subscription>;
  actualOptions: Array<Maybe<SubscrOption>>;
};

export type Ticket = {
  __typename?: 'Ticket';
  id: Scalars['ID'];
  member?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  message: Scalars['String'];
  answered: Scalars['Boolean'];
};

export type Current = {
  __typename?: 'Current';
  user?: Maybe<User>;
  clubs: Array<Club>;
  currentClub?: Maybe<Club>;
  currentRoles: Array<Role>;
  kiosk: Scalars['Boolean'];
};

export enum EmailValPurpose {
  EmailConfirm = 'emailConfirm',
  SetPwd = 'setPwd',
  ResetPwd = 'resetPwd'
}

export type EmailValidation = {
  __typename?: 'EmailValidation';
  id: Scalars['ID'];
  userId: User;
  token: Scalars['String'];
  purpose?: Maybe<EmailValPurpose>;
  usable: Scalars['Boolean'];
};

export type Gun = {
  __typename?: 'Gun';
  id: Scalars['ID'];
  brand: Scalars['String'];
  model: Scalars['String'];
  serial: Scalars['String'];
  available: Scalars['Boolean'];
  borrower?: Maybe<User>;
  club: Club;
};

export type ItacQuery = {
  __typename?: 'ItacQuery';
  id: Scalars['ID'];
  isValid: Scalars['Boolean'];
  checkDate: Scalars['String'];
  lname: Scalars['String'];
  fname: Scalars['String'];
  licence: Scalars['Int'];
  registered?: Maybe<User>;
  club?: Maybe<Club>;
};

export type Range = {
  __typename?: 'Range';
  id: Scalars['ID'];
  rangeName: Scalars['String'];
  allowedCalibers: Array<Caliber>;
  shootingStations: Scalars['Int'];
  availableStations: Scalars['Int'];
  membersOnRange: Array<User>;
  club: Club;
};

export type AllowedCaliber = {
  id: Scalars['ID'];
};

export type AttendFeedBack = {
  __typename?: 'AttendFeedBack';
  userId: Scalars['ID'];
  lname: Scalars['String'];
  fname: Scalars['String'];
  licence?: Maybe<Scalars['Int']>;
  onGoingRegister?: Maybe<Register>;
};

export type UsedCaliber = {
  id: Scalars['ID'];
};

export type Register = {
  __typename?: 'Register';
  id: Scalars['ID'];
  beginDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  usedRange?: Maybe<Range>;
  usedGun?: Maybe<Gun>;
  usedCalibers: Array<Caliber>;
  member: User;
  regBy: User;
  club: Club;
};

export type ByDateRegister = {
  __typename?: 'ByDateRegister';
  date: Scalars['String'];
  count: Scalars['Int'];
  Register: Array<Register>;
};

export type ByMonthRegister = {
  __typename?: 'ByMonthRegister';
  month: Scalars['String'];
  count: Scalars['Int'];
  Register: Array<Register>;
};

export type UserByClubReg = {
  __typename?: 'UserByClubReg';
  club: Scalars['String'];
  register: Array<Register>;
  count: Scalars['Int'];
};

export type Password = {
  __typename?: 'Password';
  createdAt: Scalars['String'];
};

export type Email = {
  __typename?: 'Email';
  address: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type Credential = {
  __typename?: 'Credential';
  id: Scalars['ID'];
  token: Scalars['String'];
  ipv4: Scalars['String'];
  userAgent: Scalars['String'];
  lastUsed: Scalars['String'];
  isThisConnection: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  passwords: Array<Password>;
  credentials: Array<Credential>;
  kiosk: Scalars['Boolean'];
  lname: Scalars['String'];
  fname: Scalars['String'];
  bdate?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  emails: Array<Email>;
  licence: Scalars['Int'];
  siteAdmin: Scalars['Boolean'];
  linkedClubs: Array<Club>;
  trustedData: Scalars['Boolean'];
  onGoingRegister?: Maybe<Register>;
  register: Array<Register>;
  clubRegister: Array<Register>;
  totalAttendCount: Scalars['Int'];
  annualAttendCount: Scalars['Int'];
  itacChecks: Array<ItacQuery>;
  annualItacChecks: Array<ItacQuery>;
  actualClubRoles: Array<Role>;
};

export type AvailableRangesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableRangesQuery = (
  { __typename?: 'Query' }
  & { availableRanges: Array<(
    { __typename?: 'Range' }
    & Pick<Range, 'id' | 'rangeName' | 'shootingStations' | 'availableStations'>
    & { allowedCalibers: Array<(
      { __typename?: 'Caliber' }
      & Pick<Caliber, 'id' | 'caliberName'>
    )> }
  )> }
);

export type AvailableGunsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableGunsQuery = (
  { __typename?: 'Query' }
  & { availableGuns: Array<(
    { __typename?: 'Gun' }
    & Pick<Gun, 'id' | 'brand' | 'model' | 'serial'>
  )> }
);

export type AttendByLicenceMutationVariables = Exact<{
  licNumber: Scalars['Int'];
}>;


export type AttendByLicenceMutation = (
  { __typename?: 'Mutation' }
  & { attendByLicence: (
    { __typename?: 'AttendFeedBack' }
    & Pick<AttendFeedBack, 'userId' | 'fname' | 'lname' | 'licence'>
    & { onGoingRegister?: Maybe<(
      { __typename?: 'Register' }
      & Pick<Register, 'beginDate' | 'endDate'>
      & { usedRange?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'rangeName'>
      )>, usedCalibers: Array<(
        { __typename?: 'Caliber' }
        & Pick<Caliber, 'id' | 'caliberName'>
      )> }
    )> }
  ) }
);

export type AttendByUserIdMutationVariables = Exact<{
  id: Scalars['ID'];
  usedRange?: Maybe<Scalars['ID']>;
  usedCalibers?: Maybe<Array<UsedCaliber>>;
  usedGun?: Maybe<Scalars['ID']>;
  punch: Scalars['Boolean'];
}>;


export type AttendByUserIdMutation = (
  { __typename?: 'Mutation' }
  & { attendByUserId: (
    { __typename?: 'AttendFeedBack' }
    & Pick<AttendFeedBack, 'userId' | 'lname' | 'fname' | 'licence'>
    & { onGoingRegister?: Maybe<(
      { __typename?: 'Register' }
      & Pick<Register, 'beginDate' | 'endDate'>
      & { usedRange?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'rangeName'>
      )>, usedCalibers: Array<(
        { __typename?: 'Caliber' }
        & Pick<Caliber, 'id' | 'caliberName'>
      )> }
    )> }
  ) }
);

export type CalibersQueryVariables = Exact<{ [key: string]: never; }>;


export type CalibersQuery = (
  { __typename?: 'Query' }
  & { calibers: Array<(
    { __typename?: 'Caliber' }
    & Pick<Caliber, 'id' | 'caliberName'>
    & { allowedRanges: Array<(
      { __typename?: 'Range' }
      & Pick<Range, 'rangeName'>
    )>, club: (
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name'>
    ) }
  )> }
);

export type CaliberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CaliberQuery = (
  { __typename?: 'Query' }
  & { caliber?: Maybe<(
    { __typename?: 'Caliber' }
    & Pick<Caliber, 'id' | 'caliberName'>
    & { allowedRanges: Array<(
      { __typename?: 'Range' }
      & Pick<Range, 'id' | 'rangeName'>
    )>, club: (
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name'>
    ) }
  )> }
);

export type RangeFormCalListQueryVariables = Exact<{ [key: string]: never; }>;


export type RangeFormCalListQuery = (
  { __typename?: 'Query' }
  & { calibers: Array<(
    { __typename?: 'Caliber' }
    & Pick<Caliber, 'id' | 'key' | 'caliberName'>
  )> }
);

export type AllowedCalibersQueryVariables = Exact<{
  rangeId: Scalars['ID'];
}>;


export type AllowedCalibersQuery = (
  { __typename?: 'Query' }
  & { byRangeCalibers: Array<(
    { __typename?: 'Caliber' }
    & Pick<Caliber, 'id'>
  )> }
);

export type AddCaliberMutationVariables = Exact<{
  caliberName: Scalars['String'];
  allowedRanges: Array<AllowedRange>;
}>;


export type AddCaliberMutation = (
  { __typename?: 'Mutation' }
  & { addCaliber?: Maybe<(
    { __typename?: 'Caliber' }
    & Pick<Caliber, 'id' | 'caliberName'>
  )> }
);

export type EditCaliberMutationVariables = Exact<{
  id: Scalars['ID'];
  caliberName: Scalars['String'];
  allowedRanges: Array<AllowedRange>;
}>;


export type EditCaliberMutation = (
  { __typename?: 'Mutation' }
  & { editCaliber?: Maybe<(
    { __typename?: 'Caliber' }
    & Pick<Caliber, 'id' | 'caliberName'>
  )> }
);

export type RemoveCaliberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCaliberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCaliber'>
);

export type AddClubMutationVariables = Exact<{
  name: Scalars['String'];
  fedNb: Scalars['Int'];
  street: Scalars['String'];
  zip: Scalars['Int'];
  city: Scalars['String'];
  country: Scalars['String'];
}>;


export type AddClubMutation = (
  { __typename?: 'Mutation' }
  & { addClub: (
    { __typename?: 'Club' }
    & Pick<Club, 'name' | 'fedNb'>
  ) }
);

export type EditClubMutationVariables = Exact<{
  name: Scalars['String'];
  fedNb: Scalars['Int'];
  street: Scalars['String'];
  zip: Scalars['Int'];
  city: Scalars['String'];
  country: Scalars['String'];
}>;


export type EditClubMutation = (
  { __typename?: 'Mutation' }
  & { editClub?: Maybe<(
    { __typename?: 'Club' }
    & Pick<Club, 'name' | 'fedNb'>
  )> }
);

export type SetCurrentClubMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetCurrentClubMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setCurrentClub'>
);

export type SwitchSubscriptionOptionMutationVariables = Exact<{
  option: SubscrOption;
}>;


export type SwitchSubscriptionOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'switchSubscriptionOption'>
);

export type CurrentQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentQuery = (
  { __typename?: 'Query' }
  & { current: (
    { __typename?: 'Current' }
    & Pick<Current, 'currentRoles' | 'kiosk'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'lname' | 'fname'>
      & { emails: Array<(
        { __typename?: 'Email' }
        & Pick<Email, 'address'>
      )> }
    )>, clubs: Array<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name' | 'fedNb'>
    )>, currentClub?: Maybe<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name' | 'fedNb' | 'actualOptions'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'zip' | 'city' | 'country'>
      ) }
    )> }
  ) }
);

export type SetUserInfoMutationVariables = Exact<{
  lname?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  bdate?: Maybe<Scalars['String']>;
  licence?: Maybe<Scalars['Int']>;
}>;


export type SetUserInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUserInfo'>
);

export type GetConnexionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConnexionQuery = (
  { __typename?: 'Query' }
  & { current: (
    { __typename?: 'Current' }
    & Pick<Current, 'currentRoles' | 'kiosk'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'lname' | 'fname' | 'licence' | 'bdate'>
      & { credentials: Array<(
        { __typename?: 'Credential' }
        & Pick<Credential, 'id' | 'ipv4' | 'userAgent' | 'lastUsed' | 'isThisConnection'>
      )>, passwords: Array<(
        { __typename?: 'Password' }
        & Pick<Password, 'createdAt'>
      )>, emails: Array<(
        { __typename?: 'Email' }
        & Pick<Email, 'address' | 'verified'>
      )> }
    )>, clubs: Array<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name' | 'fedNb'>
    )>, currentClub?: Maybe<(
      { __typename?: 'Club' }
      & Pick<Club, 'name' | 'fedNb' | 'actualOptions'>
    )> }
  ) }
);

export type GetUserEmailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserEmailQuery = (
  { __typename?: 'Query' }
  & { current: (
    { __typename?: 'Current' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & { emails: Array<(
        { __typename?: 'Email' }
        & Pick<Email, 'address' | 'verified'>
      )> }
    )> }
  ) }
);

export type SendValidationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendValidationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendValidationEmail'>
);

export type ValidateEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidateEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'validateEmail'>
);

export type SendResetPwdEmailMutationVariables = Exact<{
  username: Scalars['String'];
  reset: Scalars['Boolean'];
}>;


export type SendResetPwdEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetPwdEmail'>
);

export type ResetPwdMutationVariables = Exact<{
  token: Scalars['String'];
  pwdHash: Scalars['String'];
  pwdHash2: Scalars['String'];
}>;


export type ResetPwdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPwd'>
);

export type EmailValFromTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type EmailValFromTokenQuery = (
  { __typename?: 'Query' }
  & { emailValFromToken: (
    { __typename?: 'EmailValidation' }
    & Pick<EmailValidation, 'purpose'>
  ) }
);

export type GunsQueryVariables = Exact<{ [key: string]: never; }>;


export type GunsQuery = (
  { __typename?: 'Query' }
  & { guns: Array<(
    { __typename?: 'Gun' }
    & Pick<Gun, 'id' | 'brand' | 'model' | 'serial' | 'available'>
    & { borrower?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'lname' | 'fname'>
    )> }
  )> }
);

export type GunQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GunQuery = (
  { __typename?: 'Query' }
  & { gun: (
    { __typename?: 'Gun' }
    & Pick<Gun, 'id' | 'brand' | 'model' | 'serial' | 'available'>
  ) }
);

export type AddGunMutationVariables = Exact<{
  brand: Scalars['String'];
  model: Scalars['String'];
  serial: Scalars['String'];
  available: Scalars['Boolean'];
}>;


export type AddGunMutation = (
  { __typename?: 'Mutation' }
  & { addGun: (
    { __typename?: 'Gun' }
    & Pick<Gun, 'id'>
  ) }
);

export type EditGunMutationVariables = Exact<{
  id: Scalars['ID'];
  brand: Scalars['String'];
  model: Scalars['String'];
  serial: Scalars['String'];
  available: Scalars['Boolean'];
}>;


export type EditGunMutation = (
  { __typename?: 'Mutation' }
  & { editGun: (
    { __typename?: 'Gun' }
    & Pick<Gun, 'id'>
  ) }
);

export type RemoveGunMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveGunMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGun'>
);

export type UserItacChecksTimelineQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserItacChecksTimelineQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'lname' | 'fname'>
    & { itacChecks: Array<(
      { __typename?: 'ItacQuery' }
      & Pick<ItacQuery, 'checkDate' | 'isValid'>
    )> }
  ) }
);

export type IqvQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IqvQuery = (
  { __typename?: 'Query' }
  & { itacQuery: (
    { __typename?: 'ItacQuery' }
    & Pick<ItacQuery, 'id' | 'isValid' | 'checkDate' | 'lname' | 'fname' | 'licence'>
    & { registered?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'lname' | 'fname' | 'licence'>
    )> }
  ) }
);

export type NewItacQueryMutationVariables = Exact<{
  link: Scalars['String'];
}>;


export type NewItacQueryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'newItacQuery'>
);

export type ValidateQueryMutationVariables = Exact<{
  queryId: Scalars['ID'];
  punch: Scalars['Boolean'];
}>;


export type ValidateQueryMutation = (
  { __typename?: 'Mutation' }
  & { validateQuery?: Maybe<(
    { __typename?: 'AttendFeedBack' }
    & Pick<AttendFeedBack, 'userId' | 'lname' | 'fname' | 'licence'>
    & { onGoingRegister?: Maybe<(
      { __typename?: 'Register' }
      & Pick<Register, 'beginDate' | 'endDate'>
      & { usedRange?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'rangeName'>
      )>, usedCalibers: Array<(
        { __typename?: 'Caliber' }
        & Pick<Caliber, 'id' | 'caliberName'>
      )> }
    )> }
  )> }
);

export type OpenKioskMutationVariables = Exact<{ [key: string]: never; }>;


export type OpenKioskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'openKiosk'>
);

export type CloseKioskMutationVariables = Exact<{
  pwdHash: Scalars['String'];
}>;


export type CloseKioskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'closeKiosk'>
);

export type SetPwdMutationVariables = Exact<{
  oldPwdHash: Scalars['String'];
  newPwdHash: Scalars['String'];
  newPwdHash2: Scalars['String'];
}>;


export type SetPwdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setPwd'>
);

export type RangesQueryVariables = Exact<{ [key: string]: never; }>;


export type RangesQuery = (
  { __typename?: 'Query' }
  & { ranges: Array<(
    { __typename?: 'Range' }
    & Pick<Range, 'id' | 'rangeName' | 'shootingStations' | 'availableStations'>
    & { membersOnRange: Array<(
      { __typename?: 'User' }
      & Pick<User, 'lname' | 'fname' | 'licence'>
      & { onGoingRegister?: Maybe<(
        { __typename?: 'Register' }
        & Pick<Register, 'beginDate'>
        & { usedGun?: Maybe<(
          { __typename?: 'Gun' }
          & Pick<Gun, 'brand' | 'model' | 'serial'>
        )> }
      )> }
    )>, allowedCalibers: Array<(
      { __typename?: 'Caliber' }
      & Pick<Caliber, 'id' | 'caliberName'>
    )> }
  )> }
);

export type RangeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RangeQuery = (
  { __typename?: 'Query' }
  & { range: (
    { __typename?: 'Range' }
    & Pick<Range, 'id' | 'rangeName' | 'shootingStations'>
    & { allowedCalibers: Array<(
      { __typename?: 'Caliber' }
      & Pick<Caliber, 'id'>
    )> }
  ) }
);

export type CalibersFormRangeListQueryVariables = Exact<{ [key: string]: never; }>;


export type CalibersFormRangeListQuery = (
  { __typename?: 'Query' }
  & { ranges: Array<(
    { __typename?: 'Range' }
    & Pick<Range, 'id' | 'rangeName'>
  )> }
);

export type AllowedRangesQueryVariables = Exact<{
  caliberId: Scalars['ID'];
}>;


export type AllowedRangesQuery = (
  { __typename?: 'Query' }
  & { byCaliberRanges: Array<(
    { __typename?: 'Range' }
    & Pick<Range, 'id'>
  )> }
);

export type AddRangeMutationVariables = Exact<{
  rangeName: Scalars['String'];
  allowedCalibers: Array<AllowedCaliber>;
  shootingStations: Scalars['Int'];
}>;


export type AddRangeMutation = (
  { __typename?: 'Mutation' }
  & { addRange: (
    { __typename?: 'Range' }
    & Pick<Range, 'id'>
  ) }
);

export type EditRangeMutationVariables = Exact<{
  id: Scalars['ID'];
  rangeName: Scalars['String'];
  allowedCalibers: Array<AllowedCaliber>;
  shootingStations: Scalars['Int'];
}>;


export type EditRangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editRange'>
);

export type RemoveRangeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveRangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRange'>
);

export type RegisterQueryVariables = Exact<{
  date?: Maybe<Scalars['String']>;
  enroute?: Maybe<Scalars['Boolean']>;
}>;


export type RegisterQuery = (
  { __typename?: 'Query' }
  & { register: Array<(
    { __typename?: 'Register' }
    & Pick<Register, 'id' | 'beginDate' | 'endDate'>
    & { member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'lname' | 'fname' | 'licence'>
    ), usedGun?: Maybe<(
      { __typename?: 'Gun' }
      & Pick<Gun, 'brand' | 'model' | 'serial'>
    )>, usedCalibers: Array<(
      { __typename?: 'Caliber' }
      & Pick<Caliber, 'id' | 'caliberName'>
    )>, usedRange?: Maybe<(
      { __typename?: 'Range' }
      & Pick<Range, 'rangeName'>
    )> }
  )> }
);

export type ByDateRegisterQueryVariables = Exact<{ [key: string]: never; }>;


export type ByDateRegisterQuery = (
  { __typename?: 'Query' }
  & { byDateRegister: Array<(
    { __typename?: 'ByDateRegister' }
    & Pick<ByDateRegister, 'date' | 'count'>
  )> }
);

export type ByMonthRegisterQueryVariables = Exact<{ [key: string]: never; }>;


export type ByMonthRegisterQuery = (
  { __typename?: 'Query' }
  & { byMonthRegister: Array<(
    { __typename?: 'ByMonthRegister' }
    & Pick<ByMonthRegister, 'month' | 'count'>
  )> }
);

export type UserExistsQueryVariables = Exact<{
  licence: Scalars['Int'];
}>;


export type UserExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userExists'>
);

export type SignUpMutationVariables = Exact<{
  lname?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  licence?: Maybe<Scalars['Int']>;
  itacLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  pwdHash: Scalars['String'];
  pwdHash2: Scalars['String'];
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signUp'>
);

export type UserTimelineQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserTimelineQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'lname' | 'fname'>
    & { register: Array<(
      { __typename?: 'Register' }
      & Pick<Register, 'beginDate' | 'endDate'>
      & { usedRange?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'rangeName'>
      )>, usedCalibers: Array<(
        { __typename?: 'Caliber' }
        & Pick<Caliber, 'caliberName'>
      )>, usedGun?: Maybe<(
        { __typename?: 'Gun' }
        & Pick<Gun, 'brand' | 'model' | 'serial'>
      )> }
    )> }
  ) }
);

export type UserSummaryQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type UserSummaryQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'lname' | 'fname' | 'bdate' | 'licence' | 'createdAt' | 'totalAttendCount' | 'annualAttendCount' | 'trustedData'>
    & { emails: Array<(
      { __typename?: 'Email' }
      & Pick<Email, 'address' | 'verified'>
    )>, register: Array<(
      { __typename?: 'Register' }
      & Pick<Register, 'beginDate' | 'endDate'>
      & { usedRange?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'rangeName'>
      )>, usedCalibers: Array<(
        { __typename?: 'Caliber' }
        & Pick<Caliber, 'caliberName'>
      )>, usedGun?: Maybe<(
        { __typename?: 'Gun' }
        & Pick<Gun, 'brand' | 'model' | 'serial'>
      )>, club: (
        { __typename?: 'Club' }
        & Pick<Club, 'name'>
      ) }
    )>, itacChecks: Array<(
      { __typename?: 'ItacQuery' }
      & Pick<ItacQuery, 'checkDate' | 'isValid'>
    )>, annualItacChecks: Array<(
      { __typename?: 'ItacQuery' }
      & Pick<ItacQuery, 'checkDate' | 'isValid'>
    )> }
  ) }
);

export type UserStatsQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type UserStatsQuery = (
  { __typename?: 'Query' }
  & { userStats: Array<(
    { __typename?: 'UserByClubReg' }
    & Pick<UserByClubReg, 'club' | 'count'>
    & { register: Array<(
      { __typename?: 'Register' }
      & Pick<Register, 'beginDate' | 'endDate'>
    )> }
  )> }
);

export type SignInMutationVariables = Exact<{
  username: Scalars['String'];
  pwdHash: Scalars['String'];
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type UsersTableQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersTableQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'lname' | 'fname' | 'bdate' | 'licence' | 'totalAttendCount' | 'annualAttendCount'>
    & { emails: Array<(
      { __typename?: 'Email' }
      & Pick<Email, 'address' | 'verified'>
    )>, itacChecks: Array<(
      { __typename?: 'ItacQuery' }
      & Pick<ItacQuery, 'checkDate' | 'isValid'>
    )>, annualItacChecks: Array<(
      { __typename?: 'ItacQuery' }
      & Pick<ItacQuery, 'checkDate' | 'isValid'>
    )> }
  )> }
);

export type UserFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserFormQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'lname' | 'fname' | 'licence' | 'bdate' | 'actualClubRoles' | 'totalAttendCount' | 'trustedData'>
    & { emails: Array<(
      { __typename?: 'Email' }
      & Pick<Email, 'address' | 'verified'>
    )>, passwords: Array<(
      { __typename?: 'Password' }
      & Pick<Password, 'createdAt'>
    )> }
  ) }
);

export type AddUserMutationVariables = Exact<{
  email: Scalars['String'];
  lname: Scalars['String'];
  fname: Scalars['String'];
  licence?: Maybe<Scalars['Int']>;
  bdate?: Maybe<Scalars['String']>;
  roles: Array<Role>;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type EditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lname: Scalars['String'];
  fname: Scalars['String'];
  licence?: Maybe<Scalars['Int']>;
  bdate?: Maybe<Scalars['String']>;
  roles: Array<Role>;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type RemoveConnectionMutationVariables = Exact<{
  connectionId: Scalars['ID'];
}>;


export type RemoveConnectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeConnection'>
);

export type OpenTicketMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  message: Scalars['String'];
}>;


export type OpenTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'openTicket'>
);

export type TicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type TicketsQuery = (
  { __typename?: 'Query' }
  & { tickets: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'id' | 'object' | 'message'>
  )> }
);


export const AvailableRangesDocument = gql`
    query AvailableRanges {
  availableRanges {
    id
    rangeName
    shootingStations
    availableStations
    allowedCalibers {
      id
      caliberName
    }
  }
}
    `;

/**
 * __useAvailableRangesQuery__
 *
 * To run a query within a React component, call `useAvailableRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableRangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableRangesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableRangesQuery, AvailableRangesQueryVariables>) {
        return Apollo.useQuery<AvailableRangesQuery, AvailableRangesQueryVariables>(AvailableRangesDocument, baseOptions);
      }
export function useAvailableRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableRangesQuery, AvailableRangesQueryVariables>) {
          return Apollo.useLazyQuery<AvailableRangesQuery, AvailableRangesQueryVariables>(AvailableRangesDocument, baseOptions);
        }
export type AvailableRangesQueryHookResult = ReturnType<typeof useAvailableRangesQuery>;
export type AvailableRangesLazyQueryHookResult = ReturnType<typeof useAvailableRangesLazyQuery>;
export type AvailableRangesQueryResult = Apollo.QueryResult<AvailableRangesQuery, AvailableRangesQueryVariables>;
export const AvailableGunsDocument = gql`
    query AvailableGuns {
  availableGuns {
    id
    brand
    model
    serial
  }
}
    `;

/**
 * __useAvailableGunsQuery__
 *
 * To run a query within a React component, call `useAvailableGunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableGunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableGunsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableGunsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableGunsQuery, AvailableGunsQueryVariables>) {
        return Apollo.useQuery<AvailableGunsQuery, AvailableGunsQueryVariables>(AvailableGunsDocument, baseOptions);
      }
export function useAvailableGunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableGunsQuery, AvailableGunsQueryVariables>) {
          return Apollo.useLazyQuery<AvailableGunsQuery, AvailableGunsQueryVariables>(AvailableGunsDocument, baseOptions);
        }
export type AvailableGunsQueryHookResult = ReturnType<typeof useAvailableGunsQuery>;
export type AvailableGunsLazyQueryHookResult = ReturnType<typeof useAvailableGunsLazyQuery>;
export type AvailableGunsQueryResult = Apollo.QueryResult<AvailableGunsQuery, AvailableGunsQueryVariables>;
export const AttendByLicenceDocument = gql`
    mutation AttendByLicence($licNumber: Int!) {
  attendByLicence(licNumber: $licNumber) {
    userId
    fname
    lname
    licence
    onGoingRegister {
      beginDate
      endDate
      usedRange {
        rangeName
      }
      usedCalibers {
        id
        caliberName
      }
    }
  }
}
    `;
export type AttendByLicenceMutationFn = Apollo.MutationFunction<AttendByLicenceMutation, AttendByLicenceMutationVariables>;

/**
 * __useAttendByLicenceMutation__
 *
 * To run a mutation, you first call `useAttendByLicenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendByLicenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendByLicenceMutation, { data, loading, error }] = useAttendByLicenceMutation({
 *   variables: {
 *      licNumber: // value for 'licNumber'
 *   },
 * });
 */
export function useAttendByLicenceMutation(baseOptions?: Apollo.MutationHookOptions<AttendByLicenceMutation, AttendByLicenceMutationVariables>) {
        return Apollo.useMutation<AttendByLicenceMutation, AttendByLicenceMutationVariables>(AttendByLicenceDocument, baseOptions);
      }
export type AttendByLicenceMutationHookResult = ReturnType<typeof useAttendByLicenceMutation>;
export type AttendByLicenceMutationResult = Apollo.MutationResult<AttendByLicenceMutation>;
export type AttendByLicenceMutationOptions = Apollo.BaseMutationOptions<AttendByLicenceMutation, AttendByLicenceMutationVariables>;
export const AttendByUserIdDocument = gql`
    mutation AttendByUserId($id: ID!, $usedRange: ID, $usedCalibers: [UsedCaliber!], $usedGun: ID, $punch: Boolean!) {
  attendByUserId(
    id: $id
    usedRange: $usedRange
    usedCalibers: $usedCalibers
    usedGun: $usedGun
    punch: $punch
  ) {
    userId
    lname
    fname
    licence
    onGoingRegister {
      beginDate
      endDate
      usedRange {
        rangeName
      }
      usedCalibers {
        id
        caliberName
      }
    }
  }
}
    `;
export type AttendByUserIdMutationFn = Apollo.MutationFunction<AttendByUserIdMutation, AttendByUserIdMutationVariables>;

/**
 * __useAttendByUserIdMutation__
 *
 * To run a mutation, you first call `useAttendByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendByUserIdMutation, { data, loading, error }] = useAttendByUserIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      usedRange: // value for 'usedRange'
 *      usedCalibers: // value for 'usedCalibers'
 *      usedGun: // value for 'usedGun'
 *      punch: // value for 'punch'
 *   },
 * });
 */
export function useAttendByUserIdMutation(baseOptions?: Apollo.MutationHookOptions<AttendByUserIdMutation, AttendByUserIdMutationVariables>) {
        return Apollo.useMutation<AttendByUserIdMutation, AttendByUserIdMutationVariables>(AttendByUserIdDocument, baseOptions);
      }
export type AttendByUserIdMutationHookResult = ReturnType<typeof useAttendByUserIdMutation>;
export type AttendByUserIdMutationResult = Apollo.MutationResult<AttendByUserIdMutation>;
export type AttendByUserIdMutationOptions = Apollo.BaseMutationOptions<AttendByUserIdMutation, AttendByUserIdMutationVariables>;
export const CalibersDocument = gql`
    query Calibers {
  calibers {
    id
    caliberName
    allowedRanges {
      rangeName
    }
    club {
      id
      name
    }
  }
}
    `;

/**
 * __useCalibersQuery__
 *
 * To run a query within a React component, call `useCalibersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalibersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalibersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalibersQuery(baseOptions?: Apollo.QueryHookOptions<CalibersQuery, CalibersQueryVariables>) {
        return Apollo.useQuery<CalibersQuery, CalibersQueryVariables>(CalibersDocument, baseOptions);
      }
export function useCalibersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalibersQuery, CalibersQueryVariables>) {
          return Apollo.useLazyQuery<CalibersQuery, CalibersQueryVariables>(CalibersDocument, baseOptions);
        }
export type CalibersQueryHookResult = ReturnType<typeof useCalibersQuery>;
export type CalibersLazyQueryHookResult = ReturnType<typeof useCalibersLazyQuery>;
export type CalibersQueryResult = Apollo.QueryResult<CalibersQuery, CalibersQueryVariables>;
export const CaliberDocument = gql`
    query Caliber($id: ID!) {
  caliber(id: $id) {
    id
    caliberName
    allowedRanges {
      id
      rangeName
    }
    club {
      id
      name
    }
  }
}
    `;

/**
 * __useCaliberQuery__
 *
 * To run a query within a React component, call `useCaliberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaliberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaliberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaliberQuery(baseOptions?: Apollo.QueryHookOptions<CaliberQuery, CaliberQueryVariables>) {
        return Apollo.useQuery<CaliberQuery, CaliberQueryVariables>(CaliberDocument, baseOptions);
      }
export function useCaliberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaliberQuery, CaliberQueryVariables>) {
          return Apollo.useLazyQuery<CaliberQuery, CaliberQueryVariables>(CaliberDocument, baseOptions);
        }
export type CaliberQueryHookResult = ReturnType<typeof useCaliberQuery>;
export type CaliberLazyQueryHookResult = ReturnType<typeof useCaliberLazyQuery>;
export type CaliberQueryResult = Apollo.QueryResult<CaliberQuery, CaliberQueryVariables>;
export const RangeFormCalListDocument = gql`
    query RangeFormCalList {
  calibers {
    id
    key
    caliberName
  }
}
    `;

/**
 * __useRangeFormCalListQuery__
 *
 * To run a query within a React component, call `useRangeFormCalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangeFormCalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangeFormCalListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRangeFormCalListQuery(baseOptions?: Apollo.QueryHookOptions<RangeFormCalListQuery, RangeFormCalListQueryVariables>) {
        return Apollo.useQuery<RangeFormCalListQuery, RangeFormCalListQueryVariables>(RangeFormCalListDocument, baseOptions);
      }
export function useRangeFormCalListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RangeFormCalListQuery, RangeFormCalListQueryVariables>) {
          return Apollo.useLazyQuery<RangeFormCalListQuery, RangeFormCalListQueryVariables>(RangeFormCalListDocument, baseOptions);
        }
export type RangeFormCalListQueryHookResult = ReturnType<typeof useRangeFormCalListQuery>;
export type RangeFormCalListLazyQueryHookResult = ReturnType<typeof useRangeFormCalListLazyQuery>;
export type RangeFormCalListQueryResult = Apollo.QueryResult<RangeFormCalListQuery, RangeFormCalListQueryVariables>;
export const AllowedCalibersDocument = gql`
    query AllowedCalibers($rangeId: ID!) {
  byRangeCalibers(rangeId: $rangeId) {
    id
  }
}
    `;

/**
 * __useAllowedCalibersQuery__
 *
 * To run a query within a React component, call `useAllowedCalibersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedCalibersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedCalibersQuery({
 *   variables: {
 *      rangeId: // value for 'rangeId'
 *   },
 * });
 */
export function useAllowedCalibersQuery(baseOptions?: Apollo.QueryHookOptions<AllowedCalibersQuery, AllowedCalibersQueryVariables>) {
        return Apollo.useQuery<AllowedCalibersQuery, AllowedCalibersQueryVariables>(AllowedCalibersDocument, baseOptions);
      }
export function useAllowedCalibersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedCalibersQuery, AllowedCalibersQueryVariables>) {
          return Apollo.useLazyQuery<AllowedCalibersQuery, AllowedCalibersQueryVariables>(AllowedCalibersDocument, baseOptions);
        }
export type AllowedCalibersQueryHookResult = ReturnType<typeof useAllowedCalibersQuery>;
export type AllowedCalibersLazyQueryHookResult = ReturnType<typeof useAllowedCalibersLazyQuery>;
export type AllowedCalibersQueryResult = Apollo.QueryResult<AllowedCalibersQuery, AllowedCalibersQueryVariables>;
export const AddCaliberDocument = gql`
    mutation AddCaliber($caliberName: String!, $allowedRanges: [AllowedRange!]!) {
  addCaliber(caliberName: $caliberName, allowedRanges: $allowedRanges) {
    id
    caliberName
  }
}
    `;
export type AddCaliberMutationFn = Apollo.MutationFunction<AddCaliberMutation, AddCaliberMutationVariables>;

/**
 * __useAddCaliberMutation__
 *
 * To run a mutation, you first call `useAddCaliberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCaliberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCaliberMutation, { data, loading, error }] = useAddCaliberMutation({
 *   variables: {
 *      caliberName: // value for 'caliberName'
 *      allowedRanges: // value for 'allowedRanges'
 *   },
 * });
 */
export function useAddCaliberMutation(baseOptions?: Apollo.MutationHookOptions<AddCaliberMutation, AddCaliberMutationVariables>) {
        return Apollo.useMutation<AddCaliberMutation, AddCaliberMutationVariables>(AddCaliberDocument, baseOptions);
      }
export type AddCaliberMutationHookResult = ReturnType<typeof useAddCaliberMutation>;
export type AddCaliberMutationResult = Apollo.MutationResult<AddCaliberMutation>;
export type AddCaliberMutationOptions = Apollo.BaseMutationOptions<AddCaliberMutation, AddCaliberMutationVariables>;
export const EditCaliberDocument = gql`
    mutation EditCaliber($id: ID!, $caliberName: String!, $allowedRanges: [AllowedRange!]!) {
  editCaliber(id: $id, caliberName: $caliberName, allowedRanges: $allowedRanges) {
    id
    caliberName
  }
}
    `;
export type EditCaliberMutationFn = Apollo.MutationFunction<EditCaliberMutation, EditCaliberMutationVariables>;

/**
 * __useEditCaliberMutation__
 *
 * To run a mutation, you first call `useEditCaliberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCaliberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCaliberMutation, { data, loading, error }] = useEditCaliberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      caliberName: // value for 'caliberName'
 *      allowedRanges: // value for 'allowedRanges'
 *   },
 * });
 */
export function useEditCaliberMutation(baseOptions?: Apollo.MutationHookOptions<EditCaliberMutation, EditCaliberMutationVariables>) {
        return Apollo.useMutation<EditCaliberMutation, EditCaliberMutationVariables>(EditCaliberDocument, baseOptions);
      }
export type EditCaliberMutationHookResult = ReturnType<typeof useEditCaliberMutation>;
export type EditCaliberMutationResult = Apollo.MutationResult<EditCaliberMutation>;
export type EditCaliberMutationOptions = Apollo.BaseMutationOptions<EditCaliberMutation, EditCaliberMutationVariables>;
export const RemoveCaliberDocument = gql`
    mutation RemoveCaliber($id: ID!) {
  removeCaliber(id: $id)
}
    `;
export type RemoveCaliberMutationFn = Apollo.MutationFunction<RemoveCaliberMutation, RemoveCaliberMutationVariables>;

/**
 * __useRemoveCaliberMutation__
 *
 * To run a mutation, you first call `useRemoveCaliberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCaliberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCaliberMutation, { data, loading, error }] = useRemoveCaliberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCaliberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCaliberMutation, RemoveCaliberMutationVariables>) {
        return Apollo.useMutation<RemoveCaliberMutation, RemoveCaliberMutationVariables>(RemoveCaliberDocument, baseOptions);
      }
export type RemoveCaliberMutationHookResult = ReturnType<typeof useRemoveCaliberMutation>;
export type RemoveCaliberMutationResult = Apollo.MutationResult<RemoveCaliberMutation>;
export type RemoveCaliberMutationOptions = Apollo.BaseMutationOptions<RemoveCaliberMutation, RemoveCaliberMutationVariables>;
export const AddClubDocument = gql`
    mutation AddClub($name: String!, $fedNb: Int!, $street: String!, $zip: Int!, $city: String!, $country: String!) {
  addClub(
    name: $name
    fedNb: $fedNb
    street: $street
    zip: $zip
    city: $city
    country: $country
  ) {
    name
    fedNb
  }
}
    `;
export type AddClubMutationFn = Apollo.MutationFunction<AddClubMutation, AddClubMutationVariables>;

/**
 * __useAddClubMutation__
 *
 * To run a mutation, you first call `useAddClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClubMutation, { data, loading, error }] = useAddClubMutation({
 *   variables: {
 *      name: // value for 'name'
 *      fedNb: // value for 'fedNb'
 *      street: // value for 'street'
 *      zip: // value for 'zip'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useAddClubMutation(baseOptions?: Apollo.MutationHookOptions<AddClubMutation, AddClubMutationVariables>) {
        return Apollo.useMutation<AddClubMutation, AddClubMutationVariables>(AddClubDocument, baseOptions);
      }
export type AddClubMutationHookResult = ReturnType<typeof useAddClubMutation>;
export type AddClubMutationResult = Apollo.MutationResult<AddClubMutation>;
export type AddClubMutationOptions = Apollo.BaseMutationOptions<AddClubMutation, AddClubMutationVariables>;
export const EditClubDocument = gql`
    mutation EditClub($name: String!, $fedNb: Int!, $street: String!, $zip: Int!, $city: String!, $country: String!) {
  editClub(
    name: $name
    fedNb: $fedNb
    street: $street
    zip: $zip
    city: $city
    country: $country
  ) {
    name
    fedNb
  }
}
    `;
export type EditClubMutationFn = Apollo.MutationFunction<EditClubMutation, EditClubMutationVariables>;

/**
 * __useEditClubMutation__
 *
 * To run a mutation, you first call `useEditClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClubMutation, { data, loading, error }] = useEditClubMutation({
 *   variables: {
 *      name: // value for 'name'
 *      fedNb: // value for 'fedNb'
 *      street: // value for 'street'
 *      zip: // value for 'zip'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useEditClubMutation(baseOptions?: Apollo.MutationHookOptions<EditClubMutation, EditClubMutationVariables>) {
        return Apollo.useMutation<EditClubMutation, EditClubMutationVariables>(EditClubDocument, baseOptions);
      }
export type EditClubMutationHookResult = ReturnType<typeof useEditClubMutation>;
export type EditClubMutationResult = Apollo.MutationResult<EditClubMutation>;
export type EditClubMutationOptions = Apollo.BaseMutationOptions<EditClubMutation, EditClubMutationVariables>;
export const SetCurrentClubDocument = gql`
    mutation SetCurrentClub($id: ID!) {
  setCurrentClub(id: $id)
}
    `;
export type SetCurrentClubMutationFn = Apollo.MutationFunction<SetCurrentClubMutation, SetCurrentClubMutationVariables>;

/**
 * __useSetCurrentClubMutation__
 *
 * To run a mutation, you first call `useSetCurrentClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentClubMutation, { data, loading, error }] = useSetCurrentClubMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetCurrentClubMutation(baseOptions?: Apollo.MutationHookOptions<SetCurrentClubMutation, SetCurrentClubMutationVariables>) {
        return Apollo.useMutation<SetCurrentClubMutation, SetCurrentClubMutationVariables>(SetCurrentClubDocument, baseOptions);
      }
export type SetCurrentClubMutationHookResult = ReturnType<typeof useSetCurrentClubMutation>;
export type SetCurrentClubMutationResult = Apollo.MutationResult<SetCurrentClubMutation>;
export type SetCurrentClubMutationOptions = Apollo.BaseMutationOptions<SetCurrentClubMutation, SetCurrentClubMutationVariables>;
export const SwitchSubscriptionOptionDocument = gql`
    mutation SwitchSubscriptionOption($option: SubscrOption!) {
  switchSubscriptionOption(option: $option)
}
    `;
export type SwitchSubscriptionOptionMutationFn = Apollo.MutationFunction<SwitchSubscriptionOptionMutation, SwitchSubscriptionOptionMutationVariables>;

/**
 * __useSwitchSubscriptionOptionMutation__
 *
 * To run a mutation, you first call `useSwitchSubscriptionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchSubscriptionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchSubscriptionOptionMutation, { data, loading, error }] = useSwitchSubscriptionOptionMutation({
 *   variables: {
 *      option: // value for 'option'
 *   },
 * });
 */
export function useSwitchSubscriptionOptionMutation(baseOptions?: Apollo.MutationHookOptions<SwitchSubscriptionOptionMutation, SwitchSubscriptionOptionMutationVariables>) {
        return Apollo.useMutation<SwitchSubscriptionOptionMutation, SwitchSubscriptionOptionMutationVariables>(SwitchSubscriptionOptionDocument, baseOptions);
      }
export type SwitchSubscriptionOptionMutationHookResult = ReturnType<typeof useSwitchSubscriptionOptionMutation>;
export type SwitchSubscriptionOptionMutationResult = Apollo.MutationResult<SwitchSubscriptionOptionMutation>;
export type SwitchSubscriptionOptionMutationOptions = Apollo.BaseMutationOptions<SwitchSubscriptionOptionMutation, SwitchSubscriptionOptionMutationVariables>;
export const CurrentDocument = gql`
    query Current {
  current {
    user {
      lname
      fname
      emails {
        address
      }
    }
    clubs {
      id
      name
      fedNb
    }
    currentClub {
      id
      name
      fedNb
      address {
        street
        zip
        city
        country
      }
      actualOptions
    }
    currentRoles
    kiosk
  }
}
    `;

/**
 * __useCurrentQuery__
 *
 * To run a query within a React component, call `useCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentQuery(baseOptions?: Apollo.QueryHookOptions<CurrentQuery, CurrentQueryVariables>) {
        return Apollo.useQuery<CurrentQuery, CurrentQueryVariables>(CurrentDocument, baseOptions);
      }
export function useCurrentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentQuery, CurrentQueryVariables>) {
          return Apollo.useLazyQuery<CurrentQuery, CurrentQueryVariables>(CurrentDocument, baseOptions);
        }
export type CurrentQueryHookResult = ReturnType<typeof useCurrentQuery>;
export type CurrentLazyQueryHookResult = ReturnType<typeof useCurrentLazyQuery>;
export type CurrentQueryResult = Apollo.QueryResult<CurrentQuery, CurrentQueryVariables>;
export const SetUserInfoDocument = gql`
    mutation SetUserInfo($lname: String, $fname: String, $bdate: String, $licence: Int) {
  setUserInfo(lname: $lname, fname: $fname, bdate: $bdate, licence: $licence)
}
    `;
export type SetUserInfoMutationFn = Apollo.MutationFunction<SetUserInfoMutation, SetUserInfoMutationVariables>;

/**
 * __useSetUserInfoMutation__
 *
 * To run a mutation, you first call `useSetUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserInfoMutation, { data, loading, error }] = useSetUserInfoMutation({
 *   variables: {
 *      lname: // value for 'lname'
 *      fname: // value for 'fname'
 *      bdate: // value for 'bdate'
 *      licence: // value for 'licence'
 *   },
 * });
 */
export function useSetUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<SetUserInfoMutation, SetUserInfoMutationVariables>) {
        return Apollo.useMutation<SetUserInfoMutation, SetUserInfoMutationVariables>(SetUserInfoDocument, baseOptions);
      }
export type SetUserInfoMutationHookResult = ReturnType<typeof useSetUserInfoMutation>;
export type SetUserInfoMutationResult = Apollo.MutationResult<SetUserInfoMutation>;
export type SetUserInfoMutationOptions = Apollo.BaseMutationOptions<SetUserInfoMutation, SetUserInfoMutationVariables>;
export const GetConnexionDocument = gql`
    query GetConnexion {
  current {
    user {
      lname
      fname
      licence
      bdate
      credentials {
        id
        ipv4
        userAgent
        lastUsed
        isThisConnection
      }
      passwords {
        createdAt
      }
      emails {
        address
        verified
      }
    }
    clubs {
      id
      name
      fedNb
    }
    currentClub {
      name
      fedNb
      actualOptions
    }
    currentRoles
    kiosk
  }
}
    `;

/**
 * __useGetConnexionQuery__
 *
 * To run a query within a React component, call `useGetConnexionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnexionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnexionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConnexionQuery(baseOptions?: Apollo.QueryHookOptions<GetConnexionQuery, GetConnexionQueryVariables>) {
        return Apollo.useQuery<GetConnexionQuery, GetConnexionQueryVariables>(GetConnexionDocument, baseOptions);
      }
export function useGetConnexionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnexionQuery, GetConnexionQueryVariables>) {
          return Apollo.useLazyQuery<GetConnexionQuery, GetConnexionQueryVariables>(GetConnexionDocument, baseOptions);
        }
export type GetConnexionQueryHookResult = ReturnType<typeof useGetConnexionQuery>;
export type GetConnexionLazyQueryHookResult = ReturnType<typeof useGetConnexionLazyQuery>;
export type GetConnexionQueryResult = Apollo.QueryResult<GetConnexionQuery, GetConnexionQueryVariables>;
export const GetUserEmailDocument = gql`
    query GetUserEmail {
  current {
    user {
      emails {
        address
        verified
      }
    }
  }
}
    `;

/**
 * __useGetUserEmailQuery__
 *
 * To run a query within a React component, call `useGetUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEmailQuery, GetUserEmailQueryVariables>) {
        return Apollo.useQuery<GetUserEmailQuery, GetUserEmailQueryVariables>(GetUserEmailDocument, baseOptions);
      }
export function useGetUserEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEmailQuery, GetUserEmailQueryVariables>) {
          return Apollo.useLazyQuery<GetUserEmailQuery, GetUserEmailQueryVariables>(GetUserEmailDocument, baseOptions);
        }
export type GetUserEmailQueryHookResult = ReturnType<typeof useGetUserEmailQuery>;
export type GetUserEmailLazyQueryHookResult = ReturnType<typeof useGetUserEmailLazyQuery>;
export type GetUserEmailQueryResult = Apollo.QueryResult<GetUserEmailQuery, GetUserEmailQueryVariables>;
export const SendValidationEmailDocument = gql`
    mutation SendValidationEmail {
  sendValidationEmail
}
    `;
export type SendValidationEmailMutationFn = Apollo.MutationFunction<SendValidationEmailMutation, SendValidationEmailMutationVariables>;

/**
 * __useSendValidationEmailMutation__
 *
 * To run a mutation, you first call `useSendValidationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendValidationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendValidationEmailMutation, { data, loading, error }] = useSendValidationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendValidationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendValidationEmailMutation, SendValidationEmailMutationVariables>) {
        return Apollo.useMutation<SendValidationEmailMutation, SendValidationEmailMutationVariables>(SendValidationEmailDocument, baseOptions);
      }
export type SendValidationEmailMutationHookResult = ReturnType<typeof useSendValidationEmailMutation>;
export type SendValidationEmailMutationResult = Apollo.MutationResult<SendValidationEmailMutation>;
export type SendValidationEmailMutationOptions = Apollo.BaseMutationOptions<SendValidationEmailMutation, SendValidationEmailMutationVariables>;
export const ValidateEmailDocument = gql`
    mutation ValidateEmail($token: String!) {
  validateEmail(token: $token)
}
    `;
export type ValidateEmailMutationFn = Apollo.MutationFunction<ValidateEmailMutation, ValidateEmailMutationVariables>;

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateEmailMutation(baseOptions?: Apollo.MutationHookOptions<ValidateEmailMutation, ValidateEmailMutationVariables>) {
        return Apollo.useMutation<ValidateEmailMutation, ValidateEmailMutationVariables>(ValidateEmailDocument, baseOptions);
      }
export type ValidateEmailMutationHookResult = ReturnType<typeof useValidateEmailMutation>;
export type ValidateEmailMutationResult = Apollo.MutationResult<ValidateEmailMutation>;
export type ValidateEmailMutationOptions = Apollo.BaseMutationOptions<ValidateEmailMutation, ValidateEmailMutationVariables>;
export const SendResetPwdEmailDocument = gql`
    mutation SendResetPwdEmail($username: String!, $reset: Boolean!) {
  sendResetPwdEmail(username: $username, reset: $reset)
}
    `;
export type SendResetPwdEmailMutationFn = Apollo.MutationFunction<SendResetPwdEmailMutation, SendResetPwdEmailMutationVariables>;

/**
 * __useSendResetPwdEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPwdEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPwdEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPwdEmailMutation, { data, loading, error }] = useSendResetPwdEmailMutation({
 *   variables: {
 *      username: // value for 'username'
 *      reset: // value for 'reset'
 *   },
 * });
 */
export function useSendResetPwdEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPwdEmailMutation, SendResetPwdEmailMutationVariables>) {
        return Apollo.useMutation<SendResetPwdEmailMutation, SendResetPwdEmailMutationVariables>(SendResetPwdEmailDocument, baseOptions);
      }
export type SendResetPwdEmailMutationHookResult = ReturnType<typeof useSendResetPwdEmailMutation>;
export type SendResetPwdEmailMutationResult = Apollo.MutationResult<SendResetPwdEmailMutation>;
export type SendResetPwdEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPwdEmailMutation, SendResetPwdEmailMutationVariables>;
export const ResetPwdDocument = gql`
    mutation ResetPwd($token: String!, $pwdHash: String!, $pwdHash2: String!) {
  resetPwd(token: $token, pwdHash: $pwdHash, pwdHash2: $pwdHash2)
}
    `;
export type ResetPwdMutationFn = Apollo.MutationFunction<ResetPwdMutation, ResetPwdMutationVariables>;

/**
 * __useResetPwdMutation__
 *
 * To run a mutation, you first call `useResetPwdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPwdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPwdMutation, { data, loading, error }] = useResetPwdMutation({
 *   variables: {
 *      token: // value for 'token'
 *      pwdHash: // value for 'pwdHash'
 *      pwdHash2: // value for 'pwdHash2'
 *   },
 * });
 */
export function useResetPwdMutation(baseOptions?: Apollo.MutationHookOptions<ResetPwdMutation, ResetPwdMutationVariables>) {
        return Apollo.useMutation<ResetPwdMutation, ResetPwdMutationVariables>(ResetPwdDocument, baseOptions);
      }
export type ResetPwdMutationHookResult = ReturnType<typeof useResetPwdMutation>;
export type ResetPwdMutationResult = Apollo.MutationResult<ResetPwdMutation>;
export type ResetPwdMutationOptions = Apollo.BaseMutationOptions<ResetPwdMutation, ResetPwdMutationVariables>;
export const EmailValFromTokenDocument = gql`
    query EmailValFromToken($token: String!) {
  emailValFromToken(token: $token) {
    purpose
  }
}
    `;

/**
 * __useEmailValFromTokenQuery__
 *
 * To run a query within a React component, call `useEmailValFromTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailValFromTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailValFromTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEmailValFromTokenQuery(baseOptions?: Apollo.QueryHookOptions<EmailValFromTokenQuery, EmailValFromTokenQueryVariables>) {
        return Apollo.useQuery<EmailValFromTokenQuery, EmailValFromTokenQueryVariables>(EmailValFromTokenDocument, baseOptions);
      }
export function useEmailValFromTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailValFromTokenQuery, EmailValFromTokenQueryVariables>) {
          return Apollo.useLazyQuery<EmailValFromTokenQuery, EmailValFromTokenQueryVariables>(EmailValFromTokenDocument, baseOptions);
        }
export type EmailValFromTokenQueryHookResult = ReturnType<typeof useEmailValFromTokenQuery>;
export type EmailValFromTokenLazyQueryHookResult = ReturnType<typeof useEmailValFromTokenLazyQuery>;
export type EmailValFromTokenQueryResult = Apollo.QueryResult<EmailValFromTokenQuery, EmailValFromTokenQueryVariables>;
export const GunsDocument = gql`
    query Guns {
  guns {
    id
    brand
    model
    serial
    available
    borrower {
      lname
      fname
    }
  }
}
    `;

/**
 * __useGunsQuery__
 *
 * To run a query within a React component, call `useGunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGunsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGunsQuery(baseOptions?: Apollo.QueryHookOptions<GunsQuery, GunsQueryVariables>) {
        return Apollo.useQuery<GunsQuery, GunsQueryVariables>(GunsDocument, baseOptions);
      }
export function useGunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GunsQuery, GunsQueryVariables>) {
          return Apollo.useLazyQuery<GunsQuery, GunsQueryVariables>(GunsDocument, baseOptions);
        }
export type GunsQueryHookResult = ReturnType<typeof useGunsQuery>;
export type GunsLazyQueryHookResult = ReturnType<typeof useGunsLazyQuery>;
export type GunsQueryResult = Apollo.QueryResult<GunsQuery, GunsQueryVariables>;
export const GunDocument = gql`
    query Gun($id: ID!) {
  gun(id: $id) {
    id
    brand
    model
    serial
    available
  }
}
    `;

/**
 * __useGunQuery__
 *
 * To run a query within a React component, call `useGunQuery` and pass it any options that fit your needs.
 * When your component renders, `useGunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGunQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGunQuery(baseOptions?: Apollo.QueryHookOptions<GunQuery, GunQueryVariables>) {
        return Apollo.useQuery<GunQuery, GunQueryVariables>(GunDocument, baseOptions);
      }
export function useGunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GunQuery, GunQueryVariables>) {
          return Apollo.useLazyQuery<GunQuery, GunQueryVariables>(GunDocument, baseOptions);
        }
export type GunQueryHookResult = ReturnType<typeof useGunQuery>;
export type GunLazyQueryHookResult = ReturnType<typeof useGunLazyQuery>;
export type GunQueryResult = Apollo.QueryResult<GunQuery, GunQueryVariables>;
export const AddGunDocument = gql`
    mutation AddGun($brand: String!, $model: String!, $serial: String!, $available: Boolean!) {
  addGun(brand: $brand, model: $model, serial: $serial, available: $available) {
    id
  }
}
    `;
export type AddGunMutationFn = Apollo.MutationFunction<AddGunMutation, AddGunMutationVariables>;

/**
 * __useAddGunMutation__
 *
 * To run a mutation, you first call `useAddGunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGunMutation, { data, loading, error }] = useAddGunMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      serial: // value for 'serial'
 *      available: // value for 'available'
 *   },
 * });
 */
export function useAddGunMutation(baseOptions?: Apollo.MutationHookOptions<AddGunMutation, AddGunMutationVariables>) {
        return Apollo.useMutation<AddGunMutation, AddGunMutationVariables>(AddGunDocument, baseOptions);
      }
export type AddGunMutationHookResult = ReturnType<typeof useAddGunMutation>;
export type AddGunMutationResult = Apollo.MutationResult<AddGunMutation>;
export type AddGunMutationOptions = Apollo.BaseMutationOptions<AddGunMutation, AddGunMutationVariables>;
export const EditGunDocument = gql`
    mutation EditGun($id: ID!, $brand: String!, $model: String!, $serial: String!, $available: Boolean!) {
  editGun(
    id: $id
    brand: $brand
    model: $model
    serial: $serial
    available: $available
  ) {
    id
  }
}
    `;
export type EditGunMutationFn = Apollo.MutationFunction<EditGunMutation, EditGunMutationVariables>;

/**
 * __useEditGunMutation__
 *
 * To run a mutation, you first call `useEditGunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGunMutation, { data, loading, error }] = useEditGunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      serial: // value for 'serial'
 *      available: // value for 'available'
 *   },
 * });
 */
export function useEditGunMutation(baseOptions?: Apollo.MutationHookOptions<EditGunMutation, EditGunMutationVariables>) {
        return Apollo.useMutation<EditGunMutation, EditGunMutationVariables>(EditGunDocument, baseOptions);
      }
export type EditGunMutationHookResult = ReturnType<typeof useEditGunMutation>;
export type EditGunMutationResult = Apollo.MutationResult<EditGunMutation>;
export type EditGunMutationOptions = Apollo.BaseMutationOptions<EditGunMutation, EditGunMutationVariables>;
export const RemoveGunDocument = gql`
    mutation RemoveGun($id: ID!) {
  removeGun(id: $id)
}
    `;
export type RemoveGunMutationFn = Apollo.MutationFunction<RemoveGunMutation, RemoveGunMutationVariables>;

/**
 * __useRemoveGunMutation__
 *
 * To run a mutation, you first call `useRemoveGunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGunMutation, { data, loading, error }] = useRemoveGunMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGunMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGunMutation, RemoveGunMutationVariables>) {
        return Apollo.useMutation<RemoveGunMutation, RemoveGunMutationVariables>(RemoveGunDocument, baseOptions);
      }
export type RemoveGunMutationHookResult = ReturnType<typeof useRemoveGunMutation>;
export type RemoveGunMutationResult = Apollo.MutationResult<RemoveGunMutation>;
export type RemoveGunMutationOptions = Apollo.BaseMutationOptions<RemoveGunMutation, RemoveGunMutationVariables>;
export const UserItacChecksTimelineDocument = gql`
    query UserItacChecksTimeline($userId: ID!) {
  user(id: $userId) {
    lname
    fname
    itacChecks {
      checkDate
      isValid
    }
  }
}
    `;

/**
 * __useUserItacChecksTimelineQuery__
 *
 * To run a query within a React component, call `useUserItacChecksTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserItacChecksTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserItacChecksTimelineQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserItacChecksTimelineQuery(baseOptions?: Apollo.QueryHookOptions<UserItacChecksTimelineQuery, UserItacChecksTimelineQueryVariables>) {
        return Apollo.useQuery<UserItacChecksTimelineQuery, UserItacChecksTimelineQueryVariables>(UserItacChecksTimelineDocument, baseOptions);
      }
export function useUserItacChecksTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserItacChecksTimelineQuery, UserItacChecksTimelineQueryVariables>) {
          return Apollo.useLazyQuery<UserItacChecksTimelineQuery, UserItacChecksTimelineQueryVariables>(UserItacChecksTimelineDocument, baseOptions);
        }
export type UserItacChecksTimelineQueryHookResult = ReturnType<typeof useUserItacChecksTimelineQuery>;
export type UserItacChecksTimelineLazyQueryHookResult = ReturnType<typeof useUserItacChecksTimelineLazyQuery>;
export type UserItacChecksTimelineQueryResult = Apollo.QueryResult<UserItacChecksTimelineQuery, UserItacChecksTimelineQueryVariables>;
export const IqvDocument = gql`
    query IQV($id: ID!) {
  itacQuery(id: $id) {
    id
    isValid
    checkDate
    lname
    fname
    licence
    registered {
      lname
      fname
      licence
    }
  }
}
    `;

/**
 * __useIqvQuery__
 *
 * To run a query within a React component, call `useIqvQuery` and pass it any options that fit your needs.
 * When your component renders, `useIqvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIqvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIqvQuery(baseOptions?: Apollo.QueryHookOptions<IqvQuery, IqvQueryVariables>) {
        return Apollo.useQuery<IqvQuery, IqvQueryVariables>(IqvDocument, baseOptions);
      }
export function useIqvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IqvQuery, IqvQueryVariables>) {
          return Apollo.useLazyQuery<IqvQuery, IqvQueryVariables>(IqvDocument, baseOptions);
        }
export type IqvQueryHookResult = ReturnType<typeof useIqvQuery>;
export type IqvLazyQueryHookResult = ReturnType<typeof useIqvLazyQuery>;
export type IqvQueryResult = Apollo.QueryResult<IqvQuery, IqvQueryVariables>;
export const NewItacQueryDocument = gql`
    mutation NewItacQuery($link: String!) {
  newItacQuery(link: $link)
}
    `;
export type NewItacQueryMutationFn = Apollo.MutationFunction<NewItacQueryMutation, NewItacQueryMutationVariables>;

/**
 * __useNewItacQueryMutation__
 *
 * To run a mutation, you first call `useNewItacQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewItacQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newItacQueryMutation, { data, loading, error }] = useNewItacQueryMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useNewItacQueryMutation(baseOptions?: Apollo.MutationHookOptions<NewItacQueryMutation, NewItacQueryMutationVariables>) {
        return Apollo.useMutation<NewItacQueryMutation, NewItacQueryMutationVariables>(NewItacQueryDocument, baseOptions);
      }
export type NewItacQueryMutationHookResult = ReturnType<typeof useNewItacQueryMutation>;
export type NewItacQueryMutationResult = Apollo.MutationResult<NewItacQueryMutation>;
export type NewItacQueryMutationOptions = Apollo.BaseMutationOptions<NewItacQueryMutation, NewItacQueryMutationVariables>;
export const ValidateQueryDocument = gql`
    mutation ValidateQuery($queryId: ID!, $punch: Boolean!) {
  validateQuery(queryId: $queryId, punch: $punch) {
    userId
    lname
    fname
    licence
    onGoingRegister {
      beginDate
      endDate
      usedRange {
        rangeName
      }
      usedCalibers {
        id
        caliberName
      }
    }
  }
}
    `;
export type ValidateQueryMutationFn = Apollo.MutationFunction<ValidateQueryMutation, ValidateQueryMutationVariables>;

/**
 * __useValidateQueryMutation__
 *
 * To run a mutation, you first call `useValidateQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateQueryMutation, { data, loading, error }] = useValidateQueryMutation({
 *   variables: {
 *      queryId: // value for 'queryId'
 *      punch: // value for 'punch'
 *   },
 * });
 */
export function useValidateQueryMutation(baseOptions?: Apollo.MutationHookOptions<ValidateQueryMutation, ValidateQueryMutationVariables>) {
        return Apollo.useMutation<ValidateQueryMutation, ValidateQueryMutationVariables>(ValidateQueryDocument, baseOptions);
      }
export type ValidateQueryMutationHookResult = ReturnType<typeof useValidateQueryMutation>;
export type ValidateQueryMutationResult = Apollo.MutationResult<ValidateQueryMutation>;
export type ValidateQueryMutationOptions = Apollo.BaseMutationOptions<ValidateQueryMutation, ValidateQueryMutationVariables>;
export const OpenKioskDocument = gql`
    mutation OpenKiosk {
  openKiosk
}
    `;
export type OpenKioskMutationFn = Apollo.MutationFunction<OpenKioskMutation, OpenKioskMutationVariables>;

/**
 * __useOpenKioskMutation__
 *
 * To run a mutation, you first call `useOpenKioskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenKioskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openKioskMutation, { data, loading, error }] = useOpenKioskMutation({
 *   variables: {
 *   },
 * });
 */
export function useOpenKioskMutation(baseOptions?: Apollo.MutationHookOptions<OpenKioskMutation, OpenKioskMutationVariables>) {
        return Apollo.useMutation<OpenKioskMutation, OpenKioskMutationVariables>(OpenKioskDocument, baseOptions);
      }
export type OpenKioskMutationHookResult = ReturnType<typeof useOpenKioskMutation>;
export type OpenKioskMutationResult = Apollo.MutationResult<OpenKioskMutation>;
export type OpenKioskMutationOptions = Apollo.BaseMutationOptions<OpenKioskMutation, OpenKioskMutationVariables>;
export const CloseKioskDocument = gql`
    mutation CloseKiosk($pwdHash: String!) {
  closeKiosk(pwdHash: $pwdHash)
}
    `;
export type CloseKioskMutationFn = Apollo.MutationFunction<CloseKioskMutation, CloseKioskMutationVariables>;

/**
 * __useCloseKioskMutation__
 *
 * To run a mutation, you first call `useCloseKioskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseKioskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeKioskMutation, { data, loading, error }] = useCloseKioskMutation({
 *   variables: {
 *      pwdHash: // value for 'pwdHash'
 *   },
 * });
 */
export function useCloseKioskMutation(baseOptions?: Apollo.MutationHookOptions<CloseKioskMutation, CloseKioskMutationVariables>) {
        return Apollo.useMutation<CloseKioskMutation, CloseKioskMutationVariables>(CloseKioskDocument, baseOptions);
      }
export type CloseKioskMutationHookResult = ReturnType<typeof useCloseKioskMutation>;
export type CloseKioskMutationResult = Apollo.MutationResult<CloseKioskMutation>;
export type CloseKioskMutationOptions = Apollo.BaseMutationOptions<CloseKioskMutation, CloseKioskMutationVariables>;
export const SetPwdDocument = gql`
    mutation SetPwd($oldPwdHash: String!, $newPwdHash: String!, $newPwdHash2: String!) {
  setPwd(
    oldPwdHash: $oldPwdHash
    newPwdHash: $newPwdHash
    newPwdHash2: $newPwdHash2
  )
}
    `;
export type SetPwdMutationFn = Apollo.MutationFunction<SetPwdMutation, SetPwdMutationVariables>;

/**
 * __useSetPwdMutation__
 *
 * To run a mutation, you first call `useSetPwdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPwdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPwdMutation, { data, loading, error }] = useSetPwdMutation({
 *   variables: {
 *      oldPwdHash: // value for 'oldPwdHash'
 *      newPwdHash: // value for 'newPwdHash'
 *      newPwdHash2: // value for 'newPwdHash2'
 *   },
 * });
 */
export function useSetPwdMutation(baseOptions?: Apollo.MutationHookOptions<SetPwdMutation, SetPwdMutationVariables>) {
        return Apollo.useMutation<SetPwdMutation, SetPwdMutationVariables>(SetPwdDocument, baseOptions);
      }
export type SetPwdMutationHookResult = ReturnType<typeof useSetPwdMutation>;
export type SetPwdMutationResult = Apollo.MutationResult<SetPwdMutation>;
export type SetPwdMutationOptions = Apollo.BaseMutationOptions<SetPwdMutation, SetPwdMutationVariables>;
export const RangesDocument = gql`
    query Ranges {
  ranges {
    id
    rangeName
    shootingStations
    availableStations
    membersOnRange {
      lname
      fname
      licence
      onGoingRegister {
        beginDate
        usedGun {
          brand
          model
          serial
        }
      }
    }
    allowedCalibers {
      id
      caliberName
    }
  }
}
    `;

/**
 * __useRangesQuery__
 *
 * To run a query within a React component, call `useRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRangesQuery(baseOptions?: Apollo.QueryHookOptions<RangesQuery, RangesQueryVariables>) {
        return Apollo.useQuery<RangesQuery, RangesQueryVariables>(RangesDocument, baseOptions);
      }
export function useRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RangesQuery, RangesQueryVariables>) {
          return Apollo.useLazyQuery<RangesQuery, RangesQueryVariables>(RangesDocument, baseOptions);
        }
export type RangesQueryHookResult = ReturnType<typeof useRangesQuery>;
export type RangesLazyQueryHookResult = ReturnType<typeof useRangesLazyQuery>;
export type RangesQueryResult = Apollo.QueryResult<RangesQuery, RangesQueryVariables>;
export const RangeDocument = gql`
    query Range($id: ID!) {
  range(id: $id) {
    id
    rangeName
    shootingStations
    allowedCalibers {
      id
    }
  }
}
    `;

/**
 * __useRangeQuery__
 *
 * To run a query within a React component, call `useRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRangeQuery(baseOptions?: Apollo.QueryHookOptions<RangeQuery, RangeQueryVariables>) {
        return Apollo.useQuery<RangeQuery, RangeQueryVariables>(RangeDocument, baseOptions);
      }
export function useRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RangeQuery, RangeQueryVariables>) {
          return Apollo.useLazyQuery<RangeQuery, RangeQueryVariables>(RangeDocument, baseOptions);
        }
export type RangeQueryHookResult = ReturnType<typeof useRangeQuery>;
export type RangeLazyQueryHookResult = ReturnType<typeof useRangeLazyQuery>;
export type RangeQueryResult = Apollo.QueryResult<RangeQuery, RangeQueryVariables>;
export const CalibersFormRangeListDocument = gql`
    query CalibersFormRangeList {
  ranges {
    id
    rangeName
  }
}
    `;

/**
 * __useCalibersFormRangeListQuery__
 *
 * To run a query within a React component, call `useCalibersFormRangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalibersFormRangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalibersFormRangeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalibersFormRangeListQuery(baseOptions?: Apollo.QueryHookOptions<CalibersFormRangeListQuery, CalibersFormRangeListQueryVariables>) {
        return Apollo.useQuery<CalibersFormRangeListQuery, CalibersFormRangeListQueryVariables>(CalibersFormRangeListDocument, baseOptions);
      }
export function useCalibersFormRangeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalibersFormRangeListQuery, CalibersFormRangeListQueryVariables>) {
          return Apollo.useLazyQuery<CalibersFormRangeListQuery, CalibersFormRangeListQueryVariables>(CalibersFormRangeListDocument, baseOptions);
        }
export type CalibersFormRangeListQueryHookResult = ReturnType<typeof useCalibersFormRangeListQuery>;
export type CalibersFormRangeListLazyQueryHookResult = ReturnType<typeof useCalibersFormRangeListLazyQuery>;
export type CalibersFormRangeListQueryResult = Apollo.QueryResult<CalibersFormRangeListQuery, CalibersFormRangeListQueryVariables>;
export const AllowedRangesDocument = gql`
    query AllowedRanges($caliberId: ID!) {
  byCaliberRanges(caliberId: $caliberId) {
    id
  }
}
    `;

/**
 * __useAllowedRangesQuery__
 *
 * To run a query within a React component, call `useAllowedRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedRangesQuery({
 *   variables: {
 *      caliberId: // value for 'caliberId'
 *   },
 * });
 */
export function useAllowedRangesQuery(baseOptions?: Apollo.QueryHookOptions<AllowedRangesQuery, AllowedRangesQueryVariables>) {
        return Apollo.useQuery<AllowedRangesQuery, AllowedRangesQueryVariables>(AllowedRangesDocument, baseOptions);
      }
export function useAllowedRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedRangesQuery, AllowedRangesQueryVariables>) {
          return Apollo.useLazyQuery<AllowedRangesQuery, AllowedRangesQueryVariables>(AllowedRangesDocument, baseOptions);
        }
export type AllowedRangesQueryHookResult = ReturnType<typeof useAllowedRangesQuery>;
export type AllowedRangesLazyQueryHookResult = ReturnType<typeof useAllowedRangesLazyQuery>;
export type AllowedRangesQueryResult = Apollo.QueryResult<AllowedRangesQuery, AllowedRangesQueryVariables>;
export const AddRangeDocument = gql`
    mutation AddRange($rangeName: String!, $allowedCalibers: [AllowedCaliber!]!, $shootingStations: Int!) {
  addRange(
    rangeName: $rangeName
    allowedCalibers: $allowedCalibers
    shootingStations: $shootingStations
  ) {
    id
  }
}
    `;
export type AddRangeMutationFn = Apollo.MutationFunction<AddRangeMutation, AddRangeMutationVariables>;

/**
 * __useAddRangeMutation__
 *
 * To run a mutation, you first call `useAddRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRangeMutation, { data, loading, error }] = useAddRangeMutation({
 *   variables: {
 *      rangeName: // value for 'rangeName'
 *      allowedCalibers: // value for 'allowedCalibers'
 *      shootingStations: // value for 'shootingStations'
 *   },
 * });
 */
export function useAddRangeMutation(baseOptions?: Apollo.MutationHookOptions<AddRangeMutation, AddRangeMutationVariables>) {
        return Apollo.useMutation<AddRangeMutation, AddRangeMutationVariables>(AddRangeDocument, baseOptions);
      }
export type AddRangeMutationHookResult = ReturnType<typeof useAddRangeMutation>;
export type AddRangeMutationResult = Apollo.MutationResult<AddRangeMutation>;
export type AddRangeMutationOptions = Apollo.BaseMutationOptions<AddRangeMutation, AddRangeMutationVariables>;
export const EditRangeDocument = gql`
    mutation EditRange($id: ID!, $rangeName: String!, $allowedCalibers: [AllowedCaliber!]!, $shootingStations: Int!) {
  editRange(
    id: $id
    rangeName: $rangeName
    allowedCalibers: $allowedCalibers
    shootingStations: $shootingStations
  )
}
    `;
export type EditRangeMutationFn = Apollo.MutationFunction<EditRangeMutation, EditRangeMutationVariables>;

/**
 * __useEditRangeMutation__
 *
 * To run a mutation, you first call `useEditRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRangeMutation, { data, loading, error }] = useEditRangeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rangeName: // value for 'rangeName'
 *      allowedCalibers: // value for 'allowedCalibers'
 *      shootingStations: // value for 'shootingStations'
 *   },
 * });
 */
export function useEditRangeMutation(baseOptions?: Apollo.MutationHookOptions<EditRangeMutation, EditRangeMutationVariables>) {
        return Apollo.useMutation<EditRangeMutation, EditRangeMutationVariables>(EditRangeDocument, baseOptions);
      }
export type EditRangeMutationHookResult = ReturnType<typeof useEditRangeMutation>;
export type EditRangeMutationResult = Apollo.MutationResult<EditRangeMutation>;
export type EditRangeMutationOptions = Apollo.BaseMutationOptions<EditRangeMutation, EditRangeMutationVariables>;
export const RemoveRangeDocument = gql`
    mutation RemoveRange($id: ID!) {
  removeRange(id: $id)
}
    `;
export type RemoveRangeMutationFn = Apollo.MutationFunction<RemoveRangeMutation, RemoveRangeMutationVariables>;

/**
 * __useRemoveRangeMutation__
 *
 * To run a mutation, you first call `useRemoveRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRangeMutation, { data, loading, error }] = useRemoveRangeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRangeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRangeMutation, RemoveRangeMutationVariables>) {
        return Apollo.useMutation<RemoveRangeMutation, RemoveRangeMutationVariables>(RemoveRangeDocument, baseOptions);
      }
export type RemoveRangeMutationHookResult = ReturnType<typeof useRemoveRangeMutation>;
export type RemoveRangeMutationResult = Apollo.MutationResult<RemoveRangeMutation>;
export type RemoveRangeMutationOptions = Apollo.BaseMutationOptions<RemoveRangeMutation, RemoveRangeMutationVariables>;
export const RegisterDocument = gql`
    query Register($date: String, $enroute: Boolean) {
  register(date: $date, enroute: $enroute) {
    id
    member {
      id
      lname
      fname
      licence
    }
    beginDate
    endDate
    usedGun {
      brand
      model
      serial
    }
    usedCalibers {
      id
      caliberName
    }
    usedRange {
      rangeName
    }
  }
}
    `;

/**
 * __useRegisterQuery__
 *
 * To run a query within a React component, call `useRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterQuery({
 *   variables: {
 *      date: // value for 'date'
 *      enroute: // value for 'enroute'
 *   },
 * });
 */
export function useRegisterQuery(baseOptions?: Apollo.QueryHookOptions<RegisterQuery, RegisterQueryVariables>) {
        return Apollo.useQuery<RegisterQuery, RegisterQueryVariables>(RegisterDocument, baseOptions);
      }
export function useRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterQuery, RegisterQueryVariables>) {
          return Apollo.useLazyQuery<RegisterQuery, RegisterQueryVariables>(RegisterDocument, baseOptions);
        }
export type RegisterQueryHookResult = ReturnType<typeof useRegisterQuery>;
export type RegisterLazyQueryHookResult = ReturnType<typeof useRegisterLazyQuery>;
export type RegisterQueryResult = Apollo.QueryResult<RegisterQuery, RegisterQueryVariables>;
export const ByDateRegisterDocument = gql`
    query ByDateRegister {
  byDateRegister {
    date
    count
  }
}
    `;

/**
 * __useByDateRegisterQuery__
 *
 * To run a query within a React component, call `useByDateRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useByDateRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useByDateRegisterQuery({
 *   variables: {
 *   },
 * });
 */
export function useByDateRegisterQuery(baseOptions?: Apollo.QueryHookOptions<ByDateRegisterQuery, ByDateRegisterQueryVariables>) {
        return Apollo.useQuery<ByDateRegisterQuery, ByDateRegisterQueryVariables>(ByDateRegisterDocument, baseOptions);
      }
export function useByDateRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ByDateRegisterQuery, ByDateRegisterQueryVariables>) {
          return Apollo.useLazyQuery<ByDateRegisterQuery, ByDateRegisterQueryVariables>(ByDateRegisterDocument, baseOptions);
        }
export type ByDateRegisterQueryHookResult = ReturnType<typeof useByDateRegisterQuery>;
export type ByDateRegisterLazyQueryHookResult = ReturnType<typeof useByDateRegisterLazyQuery>;
export type ByDateRegisterQueryResult = Apollo.QueryResult<ByDateRegisterQuery, ByDateRegisterQueryVariables>;
export const ByMonthRegisterDocument = gql`
    query ByMonthRegister {
  byMonthRegister {
    month
    count
  }
}
    `;

/**
 * __useByMonthRegisterQuery__
 *
 * To run a query within a React component, call `useByMonthRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useByMonthRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useByMonthRegisterQuery({
 *   variables: {
 *   },
 * });
 */
export function useByMonthRegisterQuery(baseOptions?: Apollo.QueryHookOptions<ByMonthRegisterQuery, ByMonthRegisterQueryVariables>) {
        return Apollo.useQuery<ByMonthRegisterQuery, ByMonthRegisterQueryVariables>(ByMonthRegisterDocument, baseOptions);
      }
export function useByMonthRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ByMonthRegisterQuery, ByMonthRegisterQueryVariables>) {
          return Apollo.useLazyQuery<ByMonthRegisterQuery, ByMonthRegisterQueryVariables>(ByMonthRegisterDocument, baseOptions);
        }
export type ByMonthRegisterQueryHookResult = ReturnType<typeof useByMonthRegisterQuery>;
export type ByMonthRegisterLazyQueryHookResult = ReturnType<typeof useByMonthRegisterLazyQuery>;
export type ByMonthRegisterQueryResult = Apollo.QueryResult<ByMonthRegisterQuery, ByMonthRegisterQueryVariables>;
export const UserExistsDocument = gql`
    query UserExists($licence: Int!) {
  userExists(licence: $licence)
}
    `;

/**
 * __useUserExistsQuery__
 *
 * To run a query within a React component, call `useUserExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExistsQuery({
 *   variables: {
 *      licence: // value for 'licence'
 *   },
 * });
 */
export function useUserExistsQuery(baseOptions?: Apollo.QueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
        return Apollo.useQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, baseOptions);
      }
export function useUserExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
          return Apollo.useLazyQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, baseOptions);
        }
export type UserExistsQueryHookResult = ReturnType<typeof useUserExistsQuery>;
export type UserExistsLazyQueryHookResult = ReturnType<typeof useUserExistsLazyQuery>;
export type UserExistsQueryResult = Apollo.QueryResult<UserExistsQuery, UserExistsQueryVariables>;
export const SignUpDocument = gql`
    mutation SignUp($lname: String, $fname: String, $email: String, $licence: Int, $itacLink: String, $username: String, $pwdHash: String!, $pwdHash2: String!) {
  signUp(
    lname: $lname
    fname: $fname
    email: $email
    licence: $licence
    username: $username
    pwdHash: $pwdHash
    pwdHash2: $pwdHash2
    itacLink: $itacLink
  )
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      lname: // value for 'lname'
 *      fname: // value for 'fname'
 *      email: // value for 'email'
 *      licence: // value for 'licence'
 *      itacLink: // value for 'itacLink'
 *      username: // value for 'username'
 *      pwdHash: // value for 'pwdHash'
 *      pwdHash2: // value for 'pwdHash2'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UserTimelineDocument = gql`
    query UserTimeline($userId: ID!) {
  user(id: $userId) {
    lname
    fname
    register {
      beginDate
      endDate
      usedRange {
        rangeName
      }
      usedCalibers {
        caliberName
      }
      usedGun {
        brand
        model
        serial
      }
    }
  }
}
    `;

/**
 * __useUserTimelineQuery__
 *
 * To run a query within a React component, call `useUserTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTimelineQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTimelineQuery(baseOptions?: Apollo.QueryHookOptions<UserTimelineQuery, UserTimelineQueryVariables>) {
        return Apollo.useQuery<UserTimelineQuery, UserTimelineQueryVariables>(UserTimelineDocument, baseOptions);
      }
export function useUserTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTimelineQuery, UserTimelineQueryVariables>) {
          return Apollo.useLazyQuery<UserTimelineQuery, UserTimelineQueryVariables>(UserTimelineDocument, baseOptions);
        }
export type UserTimelineQueryHookResult = ReturnType<typeof useUserTimelineQuery>;
export type UserTimelineLazyQueryHookResult = ReturnType<typeof useUserTimelineLazyQuery>;
export type UserTimelineQueryResult = Apollo.QueryResult<UserTimelineQuery, UserTimelineQueryVariables>;
export const UserSummaryDocument = gql`
    query UserSummary($id: ID) {
  user(id: $id) {
    id
    username
    lname
    fname
    bdate
    licence
    createdAt
    emails {
      address
      verified
    }
    register {
      beginDate
      endDate
      usedRange {
        rangeName
      }
      usedCalibers {
        caliberName
      }
      usedGun {
        brand
        model
        serial
      }
      club {
        name
      }
    }
    itacChecks {
      checkDate
      isValid
    }
    totalAttendCount
    annualAttendCount
    annualItacChecks {
      checkDate
      isValid
    }
    trustedData
  }
}
    `;

/**
 * __useUserSummaryQuery__
 *
 * To run a query within a React component, call `useUserSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSummaryQuery(baseOptions?: Apollo.QueryHookOptions<UserSummaryQuery, UserSummaryQueryVariables>) {
        return Apollo.useQuery<UserSummaryQuery, UserSummaryQueryVariables>(UserSummaryDocument, baseOptions);
      }
export function useUserSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSummaryQuery, UserSummaryQueryVariables>) {
          return Apollo.useLazyQuery<UserSummaryQuery, UserSummaryQueryVariables>(UserSummaryDocument, baseOptions);
        }
export type UserSummaryQueryHookResult = ReturnType<typeof useUserSummaryQuery>;
export type UserSummaryLazyQueryHookResult = ReturnType<typeof useUserSummaryLazyQuery>;
export type UserSummaryQueryResult = Apollo.QueryResult<UserSummaryQuery, UserSummaryQueryVariables>;
export const UserStatsDocument = gql`
    query UserStats($userId: ID) {
  userStats(userId: $userId) {
    club
    count
    register {
      beginDate
      endDate
    }
  }
}
    `;

/**
 * __useUserStatsQuery__
 *
 * To run a query within a React component, call `useUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserStatsQuery(baseOptions?: Apollo.QueryHookOptions<UserStatsQuery, UserStatsQueryVariables>) {
        return Apollo.useQuery<UserStatsQuery, UserStatsQueryVariables>(UserStatsDocument, baseOptions);
      }
export function useUserStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserStatsQuery, UserStatsQueryVariables>) {
          return Apollo.useLazyQuery<UserStatsQuery, UserStatsQueryVariables>(UserStatsDocument, baseOptions);
        }
export type UserStatsQueryHookResult = ReturnType<typeof useUserStatsQuery>;
export type UserStatsLazyQueryHookResult = ReturnType<typeof useUserStatsLazyQuery>;
export type UserStatsQueryResult = Apollo.QueryResult<UserStatsQuery, UserStatsQueryVariables>;
export const SignInDocument = gql`
    mutation SignIn($username: String!, $pwdHash: String!) {
  login(username: $username, pwdHash: $pwdHash)
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      username: // value for 'username'
 *      pwdHash: // value for 'pwdHash'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, baseOptions);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
  logout
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, baseOptions);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const UsersTableDocument = gql`
    query UsersTable {
  users {
    id
    username
    lname
    fname
    bdate
    licence
    emails {
      address
      verified
    }
    itacChecks {
      checkDate
      isValid
    }
    totalAttendCount
    annualAttendCount
    annualItacChecks {
      checkDate
      isValid
    }
  }
}
    `;

/**
 * __useUsersTableQuery__
 *
 * To run a query within a React component, call `useUsersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersTableQuery(baseOptions?: Apollo.QueryHookOptions<UsersTableQuery, UsersTableQueryVariables>) {
        return Apollo.useQuery<UsersTableQuery, UsersTableQueryVariables>(UsersTableDocument, baseOptions);
      }
export function useUsersTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersTableQuery, UsersTableQueryVariables>) {
          return Apollo.useLazyQuery<UsersTableQuery, UsersTableQueryVariables>(UsersTableDocument, baseOptions);
        }
export type UsersTableQueryHookResult = ReturnType<typeof useUsersTableQuery>;
export type UsersTableLazyQueryHookResult = ReturnType<typeof useUsersTableLazyQuery>;
export type UsersTableQueryResult = Apollo.QueryResult<UsersTableQuery, UsersTableQueryVariables>;
export const UserFormDocument = gql`
    query UserForm($id: ID!) {
  user(id: $id) {
    id
    username
    emails {
      address
      verified
    }
    lname
    fname
    licence
    bdate
    actualClubRoles
    totalAttendCount
    trustedData
    passwords {
      createdAt
    }
  }
}
    `;

/**
 * __useUserFormQuery__
 *
 * To run a query within a React component, call `useUserFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserFormQuery(baseOptions?: Apollo.QueryHookOptions<UserFormQuery, UserFormQueryVariables>) {
        return Apollo.useQuery<UserFormQuery, UserFormQueryVariables>(UserFormDocument, baseOptions);
      }
export function useUserFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFormQuery, UserFormQueryVariables>) {
          return Apollo.useLazyQuery<UserFormQuery, UserFormQueryVariables>(UserFormDocument, baseOptions);
        }
export type UserFormQueryHookResult = ReturnType<typeof useUserFormQuery>;
export type UserFormLazyQueryHookResult = ReturnType<typeof useUserFormLazyQuery>;
export type UserFormQueryResult = Apollo.QueryResult<UserFormQuery, UserFormQueryVariables>;
export const AddUserDocument = gql`
    mutation AddUser($email: String!, $lname: String!, $fname: String!, $licence: Int, $bdate: String, $roles: [Role!]!) {
  addUser(
    email: $email
    lname: $lname
    fname: $fname
    licence: $licence
    bdate: $bdate
    roles: $roles
  ) {
    id
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      lname: // value for 'lname'
 *      fname: // value for 'fname'
 *      licence: // value for 'licence'
 *      bdate: // value for 'bdate'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, baseOptions);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($id: ID!, $username: String, $email: String, $lname: String!, $fname: String!, $licence: Int, $bdate: String, $roles: [Role!]!) {
  editUser(
    id: $id
    username: $username
    email: $email
    lname: $lname
    fname: $fname
    licence: $licence
    bdate: $bdate
    roles: $roles
  ) {
    id
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      email: // value for 'email'
 *      lname: // value for 'lname'
 *      fname: // value for 'fname'
 *      licence: // value for 'licence'
 *      bdate: // value for 'bdate'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($id: ID!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RemoveConnectionDocument = gql`
    mutation RemoveConnection($connectionId: ID!) {
  removeConnection(connectionId: $connectionId)
}
    `;
export type RemoveConnectionMutationFn = Apollo.MutationFunction<RemoveConnectionMutation, RemoveConnectionMutationVariables>;

/**
 * __useRemoveConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConnectionMutation, { data, loading, error }] = useRemoveConnectionMutation({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useRemoveConnectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConnectionMutation, RemoveConnectionMutationVariables>) {
        return Apollo.useMutation<RemoveConnectionMutation, RemoveConnectionMutationVariables>(RemoveConnectionDocument, baseOptions);
      }
export type RemoveConnectionMutationHookResult = ReturnType<typeof useRemoveConnectionMutation>;
export type RemoveConnectionMutationResult = Apollo.MutationResult<RemoveConnectionMutation>;
export type RemoveConnectionMutationOptions = Apollo.BaseMutationOptions<RemoveConnectionMutation, RemoveConnectionMutationVariables>;
export const OpenTicketDocument = gql`
    mutation OpenTicket($email: String, $object: String!, $message: String!) {
  openTicket(email: $email, object: $object, message: $message)
}
    `;
export type OpenTicketMutationFn = Apollo.MutationFunction<OpenTicketMutation, OpenTicketMutationVariables>;

/**
 * __useOpenTicketMutation__
 *
 * To run a mutation, you first call `useOpenTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openTicketMutation, { data, loading, error }] = useOpenTicketMutation({
 *   variables: {
 *      email: // value for 'email'
 *      object: // value for 'object'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useOpenTicketMutation(baseOptions?: Apollo.MutationHookOptions<OpenTicketMutation, OpenTicketMutationVariables>) {
        return Apollo.useMutation<OpenTicketMutation, OpenTicketMutationVariables>(OpenTicketDocument, baseOptions);
      }
export type OpenTicketMutationHookResult = ReturnType<typeof useOpenTicketMutation>;
export type OpenTicketMutationResult = Apollo.MutationResult<OpenTicketMutation>;
export type OpenTicketMutationOptions = Apollo.BaseMutationOptions<OpenTicketMutation, OpenTicketMutationVariables>;
export const TicketsDocument = gql`
    query Tickets {
  tickets {
    id
    object
    message
  }
}
    `;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketsQuery(baseOptions?: Apollo.QueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
        return Apollo.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, baseOptions);
      }
export function useTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
          return Apollo.useLazyQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, baseOptions);
        }
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = Apollo.QueryResult<TicketsQuery, TicketsQueryVariables>;