import React from "react";
import { Spin, Alert } from "antd";
import { ApolloError } from "@apollo/client";

interface props {
  error?: Error | ApolloError;
  loadMsg?: string;
  embedded: boolean;
}

export const Loader = (props: props) => {
  const absStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-40px",
    marginLeft: "-225px",
    width: "450px",
    height: "80px",
    zIndex: 1000,
  };

  const embStyle: React.CSSProperties = {
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "450px",
    height: "80px",
  };

  return (
    <div style={props.embedded ? embStyle : absStyle}>
      {!props.error && (
        <Spin>
          <Alert message="ShootRecord" description={props.loadMsg || "Chargement de ShootRecord en cours, veuillez patienter..."} type="info" />
        </Spin>
      )}
      {props.error instanceof ApolloError && <Alert message="Erreur" description={props.error.message} showIcon type="error" />}
    </div>
  );
};
