import React, { useContext, useState } from "react";
import { AttendForm } from "./AttendForm";
import { AttendByLicenceMutation, Role } from "../../api/gqlTypes";
import { ApolloError } from "@apollo/client";
import { useStore } from "../../Common";
import { Validator } from "./ItacQueryValidator";
import { Col } from "antd";
import { RegisterTable } from "../Register/RegisterTable";
import { CurrentContext } from "../../App";

export const AttendManager = () => {
  const { currentRoles, kiosk } = useContext(CurrentContext);
  //feedback of attend or pre-attend
  const [attendFeedback, setAttendFeedback] = useState<AttendByLicenceMutation["attendByLicence"] | ApolloError | null | undefined>(null);

  //UID of the itacQuery to validate
  const [itacQueryId] = useStore<string | null>("itacValidator");

  if (itacQueryId) return <Validator setAttendFeedback={setAttendFeedback} />;
  return (
    <>
      <Col span={24}>
        <AttendForm attendFeedback={attendFeedback} setAttendFeedback={setAttendFeedback} />
      </Col>
      {currentRoles.includes(Role.Rg) && !kiosk && (
        <Col span={24}>
          <RegisterTable today={true} setAttendFeedback={setAttendFeedback} />
        </Col>
      )}
    </>
  );
};
