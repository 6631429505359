import React from "react";

import { Routes, Route } from "react-router-dom";
import { DocEntryPoint } from "./EntryPoint";
import { Pointage } from "./Pointage";
import { ItacCtrl } from "./ItacCtrl";
import { Register } from "./Register";
import { FctPrincipe } from "./FctPrincipe";
import { Inscription } from "./Inscription";
import { InscriptionClub } from "./InscriptionClub";
import { DocUrlRoutes } from "./DocMenu";
import { Tarification } from "./Tarification";

export const DocManager = () => {
  return (
    <>
      <Routes>
        <Route path={DocUrlRoutes.principe}>
          <FctPrincipe />
        </Route>

        <Route path={DocUrlRoutes.tarifs}>
          <Tarification />
        </Route>

        <Route path={DocUrlRoutes.inscription}>
          <Inscription />
        </Route>
        <Route path={DocUrlRoutes.inscriptionClub}>
          <InscriptionClub />
        </Route>

        <Route path={DocUrlRoutes.pointage}>
          <Pointage />
        </Route>

        <Route path={DocUrlRoutes.itac}>
          <ItacCtrl />
        </Route>

        <Route path={DocUrlRoutes.register}>
          <Register />
        </Route>

        <Route path={`/`}>
          <DocEntryPoint />
        </Route>
      </Routes>
    </>
  );
};
