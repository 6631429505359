import React, { useContext, useEffect, useState } from "react";
import { Button, Descriptions, Divider, Tag } from "antd";
import moment from "moment";

import { CheckCircleOutlined, RedoOutlined } from "@ant-design/icons";
import { AttendByLicenceMutation, SubscrOption } from "../../api/gqlTypes";
import { ApolloError } from "@apollo/client";
import { AttendSurvey } from "./AttendSurvey";
import { CurrentContext } from "../../App";
import { TopMargedCard } from "../CommonStyledCMP";

type Props = {
  attendFeedback: AttendByLicenceMutation["attendByLicence"];
  setAttendFeedback(attendFeedback: AttendByLicenceMutation["attendByLicence"] | ApolloError | null): void;
  inputSize: boolean;
};

export const AttendResult = (props: Props) => {
  const { currentClub } = useContext(CurrentContext);
  //Act date to show current time
  const [actualDate, setActualDate] = useState<Date>(new Date());
  const [feedbackContext, setFeedbackContext] = useState<"onArriving" | "onPunching" | "onLeaving">("onArriving");

  //For closing feedBack message after 10 seconds
  useEffect(() => {
    if (props.attendFeedback?.onGoingRegister) {
      const timeout = setTimeout(() => props.setAttendFeedback(null), 10000);
      return () => clearTimeout(timeout);
    }
  }, [props]);

  //for refreshing every seconds
  useEffect(() => {
    if (!props.attendFeedback?.onGoingRegister) {
      const timeout = setTimeout(() => setActualDate(new Date()), 1000);
      return () => clearTimeout(timeout);
    }
  }, [actualDate, props.attendFeedback?.onGoingRegister]);

  useEffect(() => {
    if (props.attendFeedback.onGoingRegister) {
      if (props.attendFeedback.onGoingRegister.endDate && currentClub?.actualOptions.includes(SubscrOption.Be)) {
        setFeedbackContext("onLeaving");
      } else {
        setFeedbackContext("onPunching");
      }
    } else setFeedbackContext("onArriving");
  }, [props.attendFeedback.onGoingRegister, currentClub?.actualOptions]);

  const onNewAttend = (oldAttendFeedback: AttendByLicenceMutation["attendByLicence"]) => {
    const newAttendFeedback = Object.assign({}, oldAttendFeedback);
    delete newAttendFeedback.onGoingRegister;
    return newAttendFeedback;
  };

  return (
    <TopMargedCard
      type="inner"
      size="small"
      title={
        <Tag icon={<CheckCircleOutlined />} color={feedbackContext === "onArriving" ? "processing" : "success"}>
          {feedbackContext === "onArriving" && "Veillez remplir le formulaire:"}
          {feedbackContext === "onPunching" && "Pointage effectué, bon tir!"}
          {feedbackContext === "onLeaving" && "Merci pour votre visite!"}
        </Tag>
      }
      extra={
        <Button type="link" onClick={() => props.setAttendFeedback(null)}>
          {feedbackContext === "onArriving" ? "Annuler" : "Fermer"}
        </Button>
      }
    >
      <Descriptions>
        <Descriptions.Item label="Nom">{props.attendFeedback.lname}</Descriptions.Item>
        <Descriptions.Item label="Prénom">{props.attendFeedback.fname}</Descriptions.Item>
        <Descriptions.Item label="Licence">{props.attendFeedback?.licence ?? <Tag color="red">Invité / Non licencié</Tag>}</Descriptions.Item>
        <Descriptions.Item label="Date d'arrivée">
          {props.attendFeedback?.onGoingRegister
            ? moment(props.attendFeedback.onGoingRegister.beginDate).format("DD/MM/YYYY")
            : moment(actualDate).format("DD/MM/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Heure d'arrivée" span={2}>
          {props.attendFeedback?.onGoingRegister
            ? new Date(props.attendFeedback.onGoingRegister.beginDate).toLocaleTimeString("en-GB")
            : actualDate?.toLocaleTimeString("en-GB")}
        </Descriptions.Item>
        {feedbackContext === "onLeaving" && (
          <>
            <Descriptions.Item label="Date de départ">
              {props.attendFeedback?.onGoingRegister && moment(props.attendFeedback.onGoingRegister.endDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Heure de départ" span={1}>
              {props.attendFeedback?.onGoingRegister?.endDate && new Date(props.attendFeedback.onGoingRegister.endDate).toLocaleTimeString("en-GB")}
            </Descriptions.Item>
            <Descriptions.Item>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  props.setAttendFeedback(onNewAttend(props.attendFeedback));
                }}
              >
                <RedoOutlined /> Nouvelle Séance
              </Button>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>

      {feedbackContext === "onArriving" && (
        <>
          <Divider />
          <AttendSurvey memberId={props.attendFeedback.userId} setAttendFeedback={props.setAttendFeedback} inputSize={props.inputSize} />
        </>
      )}
    </TopMargedCard>
  );
};
