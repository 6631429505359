import React from "react";
import { Button, notification, Table } from "antd";
import dayjs from "dayjs";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Credential, useRemoveConnectionMutation } from "../../api/gqlTypes";

type Props = {
  credentials: Array<{ __typename?: "Credential" } & Pick<Credential, "id" | "ipv4" | "userAgent" | "isThisConnection">>;
};

export const ConnexionManager = (props: Props) => {
  const [removeConnection] = useRemoveConnectionMutation({
    onCompleted: () => {
      notification.success({
        message: "Succès",
        description: "Connexion supprimée",
      });
    },
    onError: (err) =>
      notification.error({
        message: "Erreur: ",
        description: err.message,
      }),
    refetchQueries: ["GetConnexion"],
  });

  const columns = [
    {
      title: "Adresse Ipv4",
      dataIndex: ["ipv4"],
    },
    {
      title: "Dernière utilisation",
      dataIndex: ["lastUsed"],
      render: (lastSeenDate: string) => dayjs(lastSeenDate).format("DD/MM/YY HH:mm:ss"),
    },
    {
      title: "User Agent",
      dataIndex: ["userAgent"],
    },
    {
      title: "Déconnecter",
      dataIndex: ["id"],

      render: (_: string, record: { isThisConnection: boolean; id: string }) => (
        <Button
          type="link"
          disabled={record.isThisConnection}
          onClick={() =>
            removeConnection({
              variables: { connectionId: record.id },
            })
          }
        >
          <MinusCircleOutlined />
        </Button>
      ),
    },
  ];

  return <Table columns={columns} dataSource={props.credentials} rowKey={(record) => record.id} />;
};
