import React from "react";
import { DocSection, WidthLimitedParagraph } from "./Reusable";
import { Typography } from "antd";

export const FctPrincipe = () => {
  return (
    <DocSection>
      <Typography.Title level={2}> Comment fonctionne ShootRecord ? </Typography.Title>
      <WidthLimitedParagraph width={1200}>
        ShootRecord propose un cahier de présence pour les clubs de tir affilés FFT. Bien que chaque club de tir possède son propre cahier, les
        données sont mutualisées. Ainsi, chaque tireur affilié peut se connecter à l'application et accéder à son espace membre ou il verra ses
        données personnelles et des statistiques sur ses présences dans tous les clubs utilisant ShootRecord.
        <br />
        <br />
        <ul>
          <li>
            Que vous soyez simple tireur, ou que vous souhaitez utiliser ShootRecord pour gérer votre club, inscrivez vous via le formulaire
            d'iscription.
          </li>
          <li>Lorsque vous voulez utiliser les services de ShootRecord pour votre club, il sous suffit de cliquer sur le bouton "Ajouter un club"</li>
          <li>Après avoir rempli le formulaire, votre club est créé, et vous en êtes l'administrateur</li>
          <li>
            Vous pouvez badger les présences de vos tireurs, ces derniers s'ajoutent automatiquement à votre liste de membre dès la première présence.
          </li>
          <li>
            Les tireurs n'ayant pas encore de compte ShootRecord peuvent être importés depuis Itac, il vous suffit de scanner le Qr-Code des licences
          </li>
        </ul>
      </WidthLimitedParagraph>
    </DocSection>
  );
};
