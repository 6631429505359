import React from "react";
import { Card, Row, Skeleton } from "antd";
import { useUserStatsQuery } from "../../api/gqlTypes";
import { AttendBarGraph } from "./AttendBarGraph";
import { FreqRepart } from "./FreqRepart";
import statistics from "../../assets/stats.png";

export const UserStats = () => {
  const { data } = useUserStatsQuery();

  return (
    <Card title="Mes statistiques">
      {data ? (
        data.userStats.length > 0 ? (
          <Row gutter={[20, 20]}>
            <AttendBarGraph userStats={data.userStats} />
            <FreqRepart userStats={data.userStats} />
          </Row>
        ) : (
          <img src={statistics} alt={"statics"} width={"100%"} />
        )
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
