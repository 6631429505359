import React from "react";
import { Button, Card, notification, Popconfirm, Skeleton, Table, Tag } from "antd";
import { useQuery } from "@apollo/client";
import { calibersQuery } from "../../api/CalibersGql";
import { EditOutlined } from "@ant-design/icons";
import { Loader } from "../Loader";
import { ColumnsType } from "antd/es/table";
import { CalibersQuery, Range, useRemoveCaliberMutation } from "../../api/gqlTypes";
import { DeleteIcon } from "../CommonStyledCMP";

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
type inTableCaliber = ArrayElement<CalibersQuery["calibers"]>;

type Props = {
  setForm(arg: string): void;
};

export const CalibersTable = (props: Props) => {
  const [removeCaliber] = useRemoveCaliberMutation({
    onError: (err) => notification.error({ message: "Erreur :", description: err.message }),
    onCompleted: () => notification.success({ message: "Succès", description: "Calibre supprimé!" }),
    refetchQueries: ["Calibers", "Ranges"],
  });
  const { loading, error, data } = useQuery(calibersQuery, {});

  const columns: ColumnsType<inTableCaliber> = [
    {
      title: "Calibre",
      dataIndex: ["caliberName"],
      key: "cal",
    },
    {
      title: "Pas de tir autorisés",
      dataIndex: ["allowedRanges"],
      key: "allowedRanges",
      render: (ranges) => (
        <>
          {ranges.map((range: Range) => (
            <Tag key={range.rangeName}>{range.rangeName}</Tag>
          ))}
        </>
      ),
    },
    {
      title: "Editer / Supprimer",
      dataIndex: "id",
      align: "center",
      render: (caliberId) => (
        <>
          <Button type="link" onClick={() => props.setForm(caliberId)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            placement="topLeft"
            title="Voulez-vous vraiment supprimer ce calibre ?"
            onConfirm={() => {
              removeCaliber({ variables: { id: caliberId } });
            }}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">
              <DeleteIcon />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <Card
      title="Gestion des calibres"
      extra={
        <Button type="primary" onClick={() => props.setForm("add")}>
          Ajouter un Calibre
        </Button>
      }
      size="small"
    >
      {data && <Table columns={columns} dataSource={data?.calibers} rowKey={(record) => record.id} />}
      {error && <Loader embedded={true} error={error} />}
      {loading && !data && <Skeleton />}
    </Card>
  );
};
