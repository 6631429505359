import React, { useContext, useEffect } from "react";
import { Button, Card, Col, notification, Result, Row, Spin, Tag, Typography } from "antd";
import { ExclamationCircleOutlined, InfoCircleOutlined, SolutionOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import { Role, useIqvLazyQuery, useValidateQueryMutation, ValidateQueryMutation } from "../../api/gqlTypes";
import { useStore } from "../../Common";
import { CurrentContext } from "../../App";
import { Loader } from "../Loader";

const { Paragraph, Text } = Typography;

//Types
type Props = {
  setAttendFeedback(attendFeedback: ValidateQueryMutation["validateQuery"] | ApolloError | null | undefined): void;
};

//React component
export const Validator = (props: Props) => {
  const { currentRoles } = useContext(CurrentContext);
  const [itacQueryId, setItacQueryId] = useStore<string | null>("itacValidator");

  //Queries and Mutations definitions
  const [getIQVdata, { loading, data, error }] = useIqvLazyQuery({
    onError: (err) => notification.error({ message: "Erreur: ", description: err.message }),
  });

  const [validateQuery] = useValidateQueryMutation({
    fetchPolicy: "no-cache",
    refetchQueries: ["Register", "usersTable"],
    onCompleted: (data) => {
      props.setAttendFeedback(data?.validateQuery);
      setItacQueryId(null);
    },
    onError: (err) => {
      notification.error({ message: "Erreur", description: err.message });
    },
  });

  //Run the query only if itacQueryId is set
  useEffect(() => {
    if (itacQueryId)
      getIQVdata({
        variables: { id: itacQueryId },
      });
  }, [itacQueryId, getIQVdata]);

  //On Finish function, call validator with arguments and callback
  const onFinish = (arg: boolean) => {
    if (data?.itacQuery)
      validateQuery({
        variables: { queryId: data.itacQuery.id, punch: arg },
      });
  };

  function validatorButtons(alreadyRegistered: boolean, isValid: boolean) {
    if (alreadyRegistered) {
      if (isValid) {
        return [
          <Button
            type="primary"
            key="val"
            onClick={() => {
              onFinish(true);
            }}
          >
            Valider le contrôle et pointer
          </Button>,
          <Button
            key="val+point"
            onClick={() => {
              onFinish(false);
            }}
          >
            Valider le contrôle
          </Button>,
        ];
      } else {
        return [
          currentRoles.includes(Role.Me) && (
            <Button
              type="primary"
              key="val"
              onClick={() => {
                onFinish(true);
              }}
            >
              Valider le contrôle et pointer
            </Button>
          ),
          <Button
            key="lock"
            onClick={() => {
              onFinish(false);
            }}
          >
            Bloquer le membre!
          </Button>,
        ];
      }
    } else {
      if (isValid) {
        return [
          <Button
            key="add+point"
            onClick={() => {
              onFinish(true);
            }}
          >
            Ajouter et pointer
          </Button>,
          <Button
            key="add"
            onClick={() => {
              onFinish(false);
            }}
          >
            Ajouter
          </Button>,
        ];
      } else {
        return [
          currentRoles.includes(Role.Me) && (
            <Button
              key="add+point"
              onClick={() => {
                onFinish(true);
              }}
            >
              Ajouter et pointer
            </Button>
          ),
          <Button
            key="add"
            onClick={() => {
              onFinish(false);
            }}
          >
            Ajouter (bloqué!)
          </Button>,
        ];
      }
    }
  }

  return (
    <Card title="Résultat de la requête ITAC:" size="small">
      {loading && !data && <Spin />}
      {error && <Loader embedded={true} loadMsg={error.message} />}
      {data && (
        <Result
          status={data.itacQuery.isValid ? "success" : "error"}
          title={data.itacQuery.isValid ? "Licence valide" : "Licence invalide"}
          extra={validatorButtons(!!data.itacQuery.registered, data.itacQuery.isValid)}
        >
          <div className="desc">
            <Row>
              <Col span={12}>
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Informations concernant le Tireur:
                  </Text>
                </Paragraph>
                <Paragraph>
                  <SolutionOutlined className="site-result-demo-error-icon" /> N° Licence: {data.itacQuery.licence}
                </Paragraph>
                <Paragraph>
                  <SolutionOutlined className="site-result-demo-error-icon" /> Nom: {data.itacQuery.lname}
                </Paragraph>
                <Paragraph>
                  <SolutionOutlined className="site-result-demo-error-icon" /> Prénom: {data.itacQuery.fname}
                </Paragraph>
              </Col>
              <Col span={12} style={{ marginTop: "auto", marginBottom: "auto" }}>
                {loading && <Spin />}
                {data.itacQuery.registered ? (
                  <>
                    <Paragraph>
                      <Tag icon={<InfoCircleOutlined />} color="processing">
                        Ce tireur est déjà enregristré dans la base de donnée!
                      </Tag>
                    </Paragraph>
                    {!data.itacQuery.registered?.licence && (
                      <Paragraph>
                        <Tag icon={<ExclamationCircleOutlined />} color="warning">
                          Le numéro de licence de ce tireur sera ajouté!
                        </Tag>
                      </Paragraph>
                    )}
                  </>
                ) : (
                  <Paragraph>
                    <Tag icon={<InfoCircleOutlined />} color="success">
                      Ce tireur va être ajouté dans la base de donnée!
                    </Tag>
                  </Paragraph>
                )}
              </Col>
            </Row>
          </div>
        </Result>
      )}
    </Card>
  );
};
