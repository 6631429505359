import React, { useState } from "react";
import { MembersTable } from "./MembersTable";
import { MemberForm } from "./MemberForm";
import { Card, Col, notification, Switch } from "antd";
import { AttendByUserIdMutation, useAttendByUserIdMutation } from "../../api/gqlTypes";
import { ApolloError } from "@apollo/client";
import { AttendResult } from "../Attend/AttendResult";
import { useStore } from "../../Common";
import dayjs from "dayjs";

export const MembersManager = () => {
  //takes "add" for adding a new user, or the userId for edition, or null.
  const [form, setForm] = useState<string | null>(null);

  const [inputSize, setInputSize] = useStore<boolean>("inputSize");
  const [attendFeedback, setAttendFeedback] = useState<AttendByUserIdMutation["attendByUserId"] | ApolloError | null>(null);
  const [attendByUserId] = useAttendByUserIdMutation({
    refetchQueries: ["usersTable", "Register"],
    onCompleted: ({ attendByUserId }) => {
      setAttendFeedback(attendByUserId);
    },
    onError: (err) => {
      notification.error({ message: "Error :", description: err.message });
    },
  });

  return (
    <>
      {attendFeedback && !(attendFeedback instanceof ApolloError) && (
        <Col span={24}>
          <Card
            title={"Ajouter au présentiel du " + dayjs(new Date()).format("DD/MM/YYYY") + " :"}
            size="small"
            extra={<Switch checkedChildren="L" unCheckedChildren="S" checked={inputSize} onChange={() => setInputSize(!inputSize)} />}
          >
            <AttendResult setAttendFeedback={setAttendFeedback} attendFeedback={attendFeedback} inputSize={inputSize} />
          </Card>
        </Col>
      )}
      {form && (
        <Col span={24}>
          <MemberForm userId={form} setForm={setForm} />
        </Col>
      )}

      <Col span={24}>
        <MembersTable setForm={setForm} form={form} attendByUserId={attendByUserId} />
      </Col>
    </>
  );
};
