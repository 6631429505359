import { DocSection, WarnTag, WidthLimitedParagraph } from "./Reusable";
import { Typography } from "antd";

export const Inscription = () => {
  return (
    <DocSection>
      <Typography.Title level={2}>Suis-je déjà inscrit?</Typography.Title>
      <WidthLimitedParagraph>
        Plusieurs manières permettent à quelqu'un d'être enregistré dans ShootRecord. Ainsi selon le contexte, la manière de procéder pour accéder à
        votre espace diffèrera. Voici les différents cas: <br />
        <br />
        <ul>
          <li>Je ne me suis jamais inscrit sur le site et je n'ai jamais été dans un club dans lequel j'ai attesté ma présence par ShootRecord.</li>
          <li>J'ai déjà scanné mon code dans un club partenaire de ShootRecord pour attester ma présence</li>
          <li>Je ne suis pas licencié, mais le club qui m'a invité a renseigné mon identité dans ShootRecord</li>
        </ul>
      </WidthLimitedParagraph>

      <Typography.Title level={4}> Cas 1. Je ne me suis pas inscrit, et je n'ai pas utilisé dans un club partenaire</Typography.Title>
      <WidthLimitedParagraph>
        Dans ce cas de figure vous pouvez vous inscrire directement via le site internet. Cliquez sur "Créer un compte", puis laissez vous guider par
        le formulaire d'inscription. Votre numéro de licence est demandé en premier afin de vérifier si une entrée existe déjà dans la base de donnée.
        Il vous suffit ensuite de rentrer les informations demandées, avec deux possibilités: <br />
        <br />
        <ul>
          <li>Vous pouvez scanner votre lien Itac, et éviter ainsi de devoir renseigner votre Nom, Prénom, N° de licence</li>
          <li>
            Si vous n'arrivez pas à scanner votre lien Itac à l'aide d'un smartphone, ou autre dispositif, vous pouvez vous inscrire sans le lien en
            cliquant sur le bouton portant cette mention.
          </li>
        </ul>
        <WarnTag
          message="Sachez toutefois qu'il vous sera demandé ultérieurement de vérifier vos données avec le lien si vous ne le faites pas à l'inscription.
            L'avantage offert est que vous pouvez effecttuer cette verification avec le lecteur code-barre d'un club partenaire au moment de pointer"
        />
      </WidthLimitedParagraph>
      <Typography.Title level={4}>Cas. 2 J'ai déjà pointé, mais je n'ai pas de compte.</Typography.Title>
      <WidthLimitedParagraph>
        Vous pouvez tout à fait créer votre compte de la même manière que ci-dessus. En revanche il sera obligatoire de fournir le lien Itac pour
        vérifier votre identité. Si vous ne parvenez pas à effectuer cette étape, vous pouvez: <br />
        <br />
        <ul>
          <li>
            Demander au club qui dans lequel vous avez pointé que son gérant entre votre adresse email. Vous recevrez ainsi un mail contenant un lien
            permettant de créer votre mot de passe.
          </li>
          <li>Vous pouvez utiliser le formulaire de contact administrateur et je trouverai une solution pour vous.</li>
        </ul>
      </WidthLimitedParagraph>
    </DocSection>
  );
};
