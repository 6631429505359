import React, { useRef, useEffect } from "react";
import { useStore } from "../../Common";
import { layout, tailLayout } from "../../Common";
import { Button, Card, Form, Input, Switch } from "antd";
import dayjs from "dayjs";
import { AttendByLicenceMutation, useAttendByLicenceMutation, useNewItacQueryMutation } from "../../api/gqlTypes";
import { AttendResult } from "./AttendResult";
import { ApolloError } from "@apollo/client";
import { AttendError } from "./AttendError";
import { Loader } from "../Loader";

type Props = {
  attendFeedback: AttendByLicenceMutation["attendByLicence"] | ApolloError | null | undefined;
  setAttendFeedback(attendFeedback: AttendByLicenceMutation["attendByLicence"] | ApolloError | null | undefined): void;
};

export const AttendForm = (props: Props) => {
  //Ref and instances definitions
  const inputRef = useRef<Input>(null);
  const [form] = Form.useForm();
  useEffect(() => inputRef.current?.focus());
  const [inputSize, setInputSize] = useStore<boolean>("inputSize");

  const [, setItacQueryValidatorData] = useStore<string | null>("itacValidator");

  //mutations

  const [attendbyLicence] = useAttendByLicenceMutation({
    onCompleted: (feedBack) => props.setAttendFeedback(feedBack.attendByLicence),
    onError: (err) => {
      props.setAttendFeedback(err);
    },
    refetchQueries: ["Register"],
  });

  const [newItacQuery, { loading }] = useNewItacQueryMutation({
    refetchQueries: ["UsersTable"],
    onCompleted: ({ newItacQuery }) => setItacQueryValidatorData(newItacQuery),
    onError: (err) => {
      props.setAttendFeedback(err);
    },
  });

  const onFinish = (values: { input: string }) => {
    if (values.input.match(/https:\/\/itac\.pro\/F\.aspx\?N=.*/)) {
      newItacQuery({ variables: { link: values.input } });
    } else {
      form.resetFields();
      attendbyLicence({ variables: { licNumber: parseInt(values.input) } });
    }
  };

  return (
    <Card
      title={"Ajouter au présentiel du " + dayjs(new Date()).format("DD/MM/YYYY") + " :"}
      size="small"
      extra={<Switch checkedChildren="L" unCheckedChildren="S" checked={inputSize} onChange={() => setInputSize(!inputSize)} />}
    >
      {/*ScnForm must be shown only for post-attenting or end-attending feedback*/}
      {!loading ? (
        (!props.attendFeedback || props.attendFeedback instanceof ApolloError || props.attendFeedback.onGoingRegister) && (
          <Form
            {...layout}
            name="basic"
            form={form}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Veuillez scanner votre licence"
              name="input"
              rules={[
                {
                  required: true,
                  message: "Veuillez scanner un numéro de licence ou un lien Itac",
                },
              ]}
            >
              <Input ref={inputRef} placeholder="Code 1d ou QR code" size={inputSize ? "large" : "middle"} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Valider
              </Button>
            </Form.Item>
          </Form>
        )
      ) : (
        <Loader embedded={true} loadMsg="Consultation de Itac en cours, veuillez patienter" />
      )}
      {props.attendFeedback && !(props.attendFeedback instanceof ApolloError) && (
        <AttendResult setAttendFeedback={props.setAttendFeedback} attendFeedback={props.attendFeedback} inputSize={inputSize} />
      )}
      {props.attendFeedback instanceof ApolloError && (
        <AttendError attendFeedback={props.attendFeedback} setAttendFeedback={props.setAttendFeedback} />
      )}
    </Card>
  );
};
