import React, { useContext, useState } from "react";
import moment from "moment";
import { useSearchProps } from "../../Common";
import {
  AttendByLicenceMutation,
  RegisterQuery,
  SubscrOption,
  useAttendByUserIdMutation,
  useByDateRegisterQuery,
  useRegisterQuery,
} from "../../api/gqlTypes";

import { Button, Card, DatePicker, Descriptions, notification, Skeleton, Switch, Table, Tag } from "antd";
import { ColumnType } from "antd/es/table";
import { Loader } from "../Loader";
import dayjs from "dayjs";
import { LogoutOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import { CurrentContext } from "../../App";
import { TableRowExpandedDescription } from "../CommonStyledCMP";

type Props = {
  today: boolean | null;
  setAttendFeedback(arg: AttendByLicenceMutation["attendByLicence"] | ApolloError | null | undefined): void;
};

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
type RegisterDoc = ArrayElement<RegisterQuery["register"]>;

export const RegisterTable = (props: Props) => {
  const { currentClub } = useContext(CurrentContext);
  const [enroute, setEnroute] = useState<boolean>(false);
  const [date, setDate] = useState(new Date());
  const getColumnSearchProps = useSearchProps();

  const { data, loading, error, refetch } = useRegisterQuery({
    variables: {
      date: date.toString(),
      enroute,
    },
  });

  const [attendByUserId] = useAttendByUserIdMutation({
    refetchQueries: ["usersTable", "Register"],
    onCompleted: ({ attendByUserId }) => {
      props.setAttendFeedback(attendByUserId);
    },
    onError: (err) => {
      notification.error({ message: "Error :", description: err.message });
    },
  });

  const byDateRegister = useByDateRegisterQuery();

  const columns: ColumnType<RegisterDoc>[] = [
    {
      title: "Nom",
      dataIndex: ["member", "lname"],
      key: "member",
      sorter: (a, b) => a.member.lname.localeCompare(b.member.lname),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps(["member", "lname"], "Nom"),
    },
    {
      title: "Prénom",
      dataIndex: ["member", "fname"],
      key: "member.prénom",
      sorter: (a, b) => a.member.fname.localeCompare(b.member.fname),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps(["member", "fname"], "Prénom"),
    },
    {
      title: "N° Licence",
      dataIndex: ["member", "licence"],
      key: "licenceNumber",
      sorter: (a, b) => {
        if (a.member.licence && b.member.licence) return a.member?.licence - b.member?.licence;
        return 0;
      },
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps(["member", "licence"], "N° Licence"),
    },
    {
      title: "Date de pointage",
      dataIndex: "beginDate",
      key: "date",
      render: (date: string) => dayjs(new Date(date)).format("DD/MM/YYYY"),
      sorter: (a, b) => new Date(a?.beginDate).getTime() - new Date(b?.beginDate).getTime(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Heure de pointage",
      dataIndex: "beginDate",
      key: "date",
      render: (date: string) => new Date(date).toLocaleTimeString("en-GB"),
      sorter: (a, b) => new Date(a?.beginDate).getTime() - new Date(b?.beginDate).getTime(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Terminer",
      dataIndex: ["member", "id"],
      key: "exit",
      render: (id, record) => (
        <Button
          type="link"
          disabled={!!record.endDate}
          onClick={() =>
            attendByUserId({
              variables: {
                id,
                punch: true,
              },
            })
          }
        >
          <LogoutOutlined />
        </Button>
      ),
    },
  ];

  if (!enroute) {
    columns.splice(3, 1);
    columns.pop();
  }

  function onChange(date: moment.Moment | null, dateString: string) {
    setDate(new Date(dateString));
  }

  return (
    <Card
      title={enroute ? "Toutes les présences en cours: " : "Registre des présences du " + moment(date).format("DD/MM/YYYY") + " : "}
      size="small"
      extra={
        !props.today ? (
          <DatePicker
            style={{ width: "210px" }}
            dateRender={(current) => {
              if (byDateRegister.data) {
                const style: { border?: string; borderRadius?: string } = {};
                if ((byDateRegister.data.byDateRegister.find((item) => current.isSame(item.date, "day"))?.count ?? 0) >= 40) {
                  style.border = "1px solid #F16DB5";
                  style.borderRadius = current.isSame(new Date(), "day") ? "10%" : "50%";
                } else if ((byDateRegister.data.byDateRegister.find((item) => current.isSame(item.date, "day"))?.count ?? 0) >> 0) {
                  style.border = "1px solid #1890ff";
                  style.borderRadius = current.isSame(moment(new Date()), "day") ? "10%" : "50%";
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }
            }}
            onChange={onChange}
            allowClear={false}
          />
        ) : (
          currentClub?.actualOptions.includes(SubscrOption.Be) && (
            <Switch
              title="Affichage du registre"
              onClick={() => {
                setEnroute(!enroute);
                refetch();
              }}
              checked={enroute}
              checkedChildren="En cours"
              unCheckedChildren="Par date"
            />
          )
        )
      }
    >
      {loading && !data && <Skeleton active />}
      {error && <Loader embedded={true} error={error} />}
      {data && (
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data.register}
          expandable={{
            rowExpandable: (record) => !!record.usedRange || !!record.usedCalibers?.length || !!record.usedGun,
          }}
          expandedRowRender={(record) => (
            <TableRowExpandedDescription column={2} layout="horizontal">
              {record.usedRange && (
                <Descriptions.Item label="Pas de tir utilisé" span={record.usedCalibers ? 1 : 2}>
                  {<Tag color="blue">{record.usedRange.rangeName}</Tag>}
                </Descriptions.Item>
              )}

              {record.usedCalibers && (
                <Descriptions.Item label="Calibres utilisés" span={record.usedRange ? 1 : 2}>
                  {record.usedCalibers.map(({ caliberName, id }) => (
                    <Tag key={id}>{caliberName}</Tag>
                  ))}
                </Descriptions.Item>
              )}

              {record.usedGun && (
                <Descriptions.Item label="Arme empruntée" span={2}>
                  <Tag color={record.usedGun ? "magenta" : "blue"}>
                    {record.usedGun ? record.usedGun.brand + " - " + record.usedGun.model + " - " + record.usedGun.serial : "- Aucune -"}
                  </Tag>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Heure d'arrivée">{dayjs(record.beginDate).format("DD/MM/YYYY HH:mm:ss")}</Descriptions.Item>
              <Descriptions.Item label="Heure de départ">
                {record.endDate ? dayjs(record.endDate).format("DD/MM/YYYY HH:mm:ss") : <Tag color="red"> Séance en cours</Tag>}
              </Descriptions.Item>
            </TableRowExpandedDescription>
          )}
        />
      )}
    </Card>
  );
};
