import React, { useEffect, useRef, useState } from "react";
import { Axis, Chart, Coord, Geom, Label, Legend, Tooltip } from "bizcharts";
import { Col } from "antd";
import { UserStatsQuery } from "../../api/gqlTypes";
import { useElementSize, wDecimalRound } from "../../Common";
import DataSet from "@antv/data-set";

type Props = {
  userStats: UserStatsQuery["userStats"];
};

const { DataView } = DataSet;

const cols2 = {
  percent: {
    formatter: (val: number) => {
      return wDecimalRound(val * 100, 2) + "%";
    },
  },
};

export const FreqRepart = (props: Props) => {
  const ref = useRef(null);
  const size = useElementSize(ref);

  const [dv, setDv] = useState(null);
  useEffect(() => {
    if (props.userStats) {
      const dataView = new DataView();
      dataView.source(props.userStats).transform({
        type: "percent",
        field: "count",
        dimension: "club",
        as: "percent",
      });
      setDv(dataView);
    }
  }, [props, size]);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} ref={ref}>
      <div>
        <Chart height={size.height - 20} data={dv} scale={cols2} padding={[0, 0, 0, 0]} forceFit>
          <Coord type={"theta"} radius={0.75} innerRadius={0.6} />
          <Axis name="percent" />
          <Legend position="bottom" />
          <Tooltip showTitle={false} itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>' />
          {/*   <Guide>
            <Html
              position={["50%", "50%"]}
              html='<span style="color:#262626;font-size:2.5em">Répartition</span></div>'
              alignX="middle"
              alignY="middle"
            />
          </Guide>*/}
          <Geom
            type="intervalStack"
            position="percent"
            color="club"
            tooltip={[
              "club*percent",
              (club, percent) => {
                percent = wDecimalRound(percent * 100, 2) + "%";
                return {
                  name: club,
                  value: percent,
                };
              },
            ]}
            style={{
              lineWidth: 1,
              stroke: "#fff",
            }}
          >
            <Label
              content="percent"
              formatter={(val, item) => {
                return item.point.club + ": " + val;
              }}
            />
          </Geom>
        </Chart>
      </div>
    </Col>
  );
};
