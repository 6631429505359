import React, { useEffect, useRef } from "react";
import { Button, Card, Col, Form, Input, notification, Row, Select } from "antd";
import { layout, tailLayout } from "../../Common";
import { FormInstance } from "antd/es/form";
import { useAddCaliberMutation, useCaliberQuery, useCalibersFormRangeListQuery, useEditCaliberMutation } from "../../api/gqlTypes";

const { Option } = Select;

type Props = {
  caliberId: string;
  setForm(arg: null): void;
};

export const CaliberForm = (props: Props) => {
  const [form] = Form.useForm();
  const caliberFormRef = useRef<FormInstance>(null);

  useEffect(() => {
    if (props.caliberId === "add")
      caliberFormRef.current?.setFieldsValue({
        caliberName: undefined,
        allowedRanges: undefined,
      });
  }, [props.caliberId]);

  useCaliberQuery({
    variables: { id: props.caliberId },
    skip: props.caliberId === "add",
    onCompleted: (data) => {
      caliberFormRef.current?.setFieldsValue({
        caliberName: data?.caliber?.caliberName,
        allowedRanges: data?.caliber?.allowedRanges?.map(({ id }) => id),
      });
    },
  });

  const allRanges = useCalibersFormRangeListQuery();

  const [addCaliber] = useAddCaliberMutation({
    refetchQueries: ["Calibers", "Ranges"],
    onError: (err) => notification.error({ message: "Erreur: ", description: err.message }),
    onCompleted: () => {
      notification.success({
        message: "Succès: ",
        description: "Calibre ajouté!",
      });
      form.resetFields();
    },
  });

  const [editCaliber] = useEditCaliberMutation({
    refetchQueries: ["Calibers", "Ranges"],
    onError: (err) => notification.error({ message: "Erreur: ", description: err.message }),
    onCompleted: () => {
      notification.success({
        message: "Succès: ",
        description: "Calibre modifié!",
      });
      props.setForm(null);
    },
  });

  const onFinish = (values: { caliberName: string; allowedRanges: string[] }) => {
    if (props.caliberId === "add")
      addCaliber({
        variables: {
          caliberName: values.caliberName,
          allowedRanges: values.allowedRanges?.map((id) => ({ id })) ?? [],
        },
      });
    else
      editCaliber({
        variables: {
          id: props.caliberId,
          caliberName: values.caliberName,
          allowedRanges: values.allowedRanges?.map((id) => ({ id })) ?? [],
        },
      });
  };
  return (
    <Card title={props.caliberId === "add" ? "Ajouter un calibre :" : "Editer ce calibre :"} size="small">
      <Form {...layout} name="AmmunitionForm" onFinish={onFinish} form={form} ref={caliberFormRef}>
        <Form.Item label="Désignation du calibre" name="caliberName">
          <Input />
        </Form.Item>
        <Form.Item label="Pas de tir autorisés" name="allowedRanges">
          <Select mode="multiple">
            {allRanges?.data?.ranges?.map((range) => (
              <Option value={range.id} key={range.id}>
                {range.rangeName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Row gutter={[1, 0]}>
            <Col offset={0}>
              <Button type="primary" htmlType="submit">
                {props.caliberId === "add" ? "Ajouter" : "Modifier"}
              </Button>
            </Col>
            <Col offset={1}>
              <Button htmlType="button" onClick={() => props.setForm(null)}>
                Fermer
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
