import React, { useState } from "react";
import { CalibersTable } from "./CalibersTable";
import { CaliberForm } from "./CaliberForm";
import { Col } from "antd";

export const CalibersManager = () => {
  const [form, setForm] = useState<string | null>(null);

  return (
    <>
      {form && (
        <Col span={24}>
          <CaliberForm caliberId={form} setForm={setForm} />
        </Col>
      )}
      <Col span={24}>
        <CalibersTable setForm={setForm} />
      </Col>
    </>
  );
};
