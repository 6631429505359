import React, { useContext, useRef } from "react";
import { Button, Form, Input, Alert, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CurrentContext } from "../../App";
import { useOpenTicketMutation } from "../../api/gqlTypes";
import { FormInstance } from "antd/es/form";
import { errNot } from "../../Common";

export const ContactForm = () => {
  const { user } = useContext(CurrentContext);

  const contactFormRef = useRef<FormInstance>(null);
  const [openTicket] = useOpenTicketMutation({
    onCompleted: () => {
      contactFormRef.current.resetFields();
      notification.success({
        message: "Succès",
        description: "Votre message a été envoyé à l'administrateur, vous allez recevoir un email confirmant cet envoi dans quelques instants.",
      });
    },
    onError: (err) => errNot(err),
  });

  return (
    <Form
      ref={contactFormRef}
      layout="vertical"
      onFinish={({ email, object, message }) => {
        openTicket({
          variables: {
            email,
            object,
            message,
          },
        });
      }}
    >
      {user?.emails[0].address ? (
        <>
          <Alert
            type="info"
            message={"L'administrateur vous contactera avec l'adresse email liée à votre compte: " + user.emails[0].address + " ."}
          />
          <br />
        </>
      ) : (
        <Form.Item
          name="email"
          label="Adresse Email:"
          rules={[
            { required: true, message: "Veuillez entrer votre adresse email" },
            { type: "email", message: "Veuillez entrer une adresse email valide!" },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item name="object" label="Objet:" rules={[{ required: true, type: "string", message: "Veuillez décrire l'objet de votre demande." }]}>
        <Input />
      </Form.Item>
      <Form.Item name="message" label="Message:" rules={[{ required: true, type: "string", message: "Expliquez votre problème ici." }]}>
        <TextArea rows={6} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Envoyer
        </Button>
      </Form.Item>
    </Form>
  );
};
