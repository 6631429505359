import { Row, Col, Card, Descriptions, Image, Divider } from "antd";
import { UserOutlined, MailOutlined, FacebookOutlined, ProfileOutlined, BankOutlined } from "@ant-design/icons";
import React from "react";

import srLingo from "../../assets/srLingo.png";
import styled from "styled-components";
import { ContactForm } from "./ContactForm";

const { Meta } = Card;

const FullHeightCard = styled(Card)`
  height: 100%;
  div.ant-card-body {
    height: 100%;
  }
`;

export const Contact = () => {
  return (
    <Row gutter={[30, 30]} justify="center">
      <Col xs={24} sm={24} md={12}>
        <Card cover={<Image src={srLingo} />}>
          <Meta title="ShootRecord installé" description="Club de tir l'amitié Lingolsheim" />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <FullHeightCard className="ant-card-contact">
          <Descriptions title="A propos de ShootRecord:" column={2}>
            <Descriptions.Item label={<UserOutlined />}>Wilfried SUGNIAUX</Descriptions.Item>
            <Descriptions.Item label="SIREN">888 535 275 RCS Strasbourg</Descriptions.Item>
            <Descriptions.Item label={<ProfileOutlined />}>Diazonium Developpement</Descriptions.Item>
            <Descriptions.Item label={<MailOutlined />}>
              <a href="mailto:wilfried@shootrecord.com">wilfried@shootrecord.com</a>
            </Descriptions.Item>
            <Descriptions.Item label={<BankOutlined />}>67150 Gerstheim, France</Descriptions.Item>

            <Descriptions.Item label={<FacebookOutlined />}>
              <a href="https://www.facebook.com/ShootRcd">ShootRecord sur Facebook</a>
            </Descriptions.Item>
          </Descriptions>
          <br />
          <br />
          <Divider orientation="left">Me contacter</Divider>
          <ContactForm />
        </FullHeightCard>
      </Col>
    </Row>
  );
};
