import React from "react";
import { Card, Image, Space } from "antd";
import registre from "../../assets/registre.png";
import registre2 from "../../assets/registre2.png";
import { DocSection } from "./Reusable";

const { Meta } = Card;

export const Register = () => {
  return (
    <DocSection>
      Pour consulter le registre des présences:
      <ul style={{ marginTop: "10px" }}>
        <Space direction="vertical" size="large">
          <li>Sélectionnez "Registres et Stats" puis "Registre par date" depuis le menu supérieur</li>
          <Card cover={<Image src={registre} width="800px" />}>
            <Meta description="Accéder aux registres" />
          </Card>
          <br />
          <li>utilisez le formulaire de date pour sélectionner la date à laquelle vous voulez consulter les présences</li>

          <Card cover={<Image src={registre2} width="800px" />}>
            <Meta description="Choisir la date" />
          </Card>
        </Space>
      </ul>
    </DocSection>
  );
};
