import React, { useContext, useRef, useState } from "react";
import { Alert, Button, Col, Form, Input, notification } from "antd";
import { sha256 } from "js-sha256";
import { useStore } from "../Common";
import { useSendResetPwdEmailMutation, useSignInMutation } from "../api/gqlTypes";
import { CurrentContext } from "../App";
import { Home } from "../Home";
import { FormInsertParagraph, LeftMargedButton, CenteredWidthLimitedCard } from "./CommonStyledCMP";
import { FormInstance } from "antd/es/form";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 25 },
};

export const SignIn = () => {
  const { user } = useContext(CurrentContext);
  const [, setToken] = useStore("loginToken");
  const loginFormRef = useRef<FormInstance>(null);
  const [pwdReset, setPwdReset] = useState(false);

  const [signIn, { error: mutationError }] = useSignInMutation({
    onCompleted: ({ login }) => {
      setToken(login);
    },
  });

  const [sendResetPwdMail] = useSendResetPwdEmailMutation({
    onCompleted: () => {
      notification.success({ message: "Succès:", description: "Si le nom d'utilisateur existe, un e-mail vous a été envoyé" });
      loginFormRef.current.resetFields();
      setPwdReset(false);
    },
  });

  const onLogin = ({ username, password }: { username: string; password: string }) => {
    signIn({
      variables: {
        username: username,
        pwdHash: sha256(password),
      },
    });
  };

  const onNewPwdReq = ({ username }: { username: string }) => {
    sendResetPwdMail({ variables: { username, reset: true } });
  };

  if (user) return <Home />;
  return (
    <Col span={24}>
      <CenteredWidthLimitedCard title={pwdReset ? "Mot de passe oublié" : "Connexion"} width={600}>
        {mutationError && <Alert style={{ marginBottom: "20px" }} message={mutationError.message} type="error" closable />}
        <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={pwdReset ? onNewPwdReq : onLogin} ref={loginFormRef}>
          {pwdReset ? (
            <>
              <FormInsertParagraph>
                Veuillez entrer votre login (pseudo). Vous recevrez ensuite un mail avec les instructions pour ré-initialiser votre mot de passe:
              </FormInsertParagraph>

              <Form.Item label="Login" name="username" rules={[{ required: true, message: "Veuillez entrer votre login!" }]}>
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Valider
                </Button>
                <LeftMargedButton
                  onClick={() => {
                    setPwdReset(false);
                  }}
                >
                  Précédent
                </LeftMargedButton>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label="Login" name="username" rules={[{ required: true, message: "Entrez votre login!" }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: "Entrez votre mot de passe!" }]}>
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Se connecter
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    setPwdReset(true);
                  }}
                >
                  Mot de passe oublié
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </CenteredWidthLimitedCard>
    </Col>
  );
};
