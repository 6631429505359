import React, { useContext, useState } from "react";
import { Avatar, Button, Col, Descriptions, Divider, List, Row } from "antd";
import { SubscrOption } from "../../api/gqlTypes";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import { CurrentContext } from "../../App";
import { ClubOptionSelector } from "./OptionSelector";
import { CenteredWidthLimitedCard } from "../CommonStyledCMP";
import { ClubForm } from "./ClubForm";

const subscrOptions = [
  {
    enum: SubscrOption.Be,
    title: "Entrée / Sortie",
    desc: "Avec cette option le tireur badge à son arrivée, puis au moment de partir. Le registre conserve ces 2 dates.",
  },
  {
    enum: SubscrOption.Ca,
    title: "Gestion des calibres",
    desc:
      "Cette option vous permet de saisir une liste de calibres, les tireurs doivent ensuite choisir un/ plusieurs calibres qu'ils vont tirer au moment de badger.",
  },
  {
    enum: SubscrOption.Ra,
    title: "Gestion pas de tir",
    desc:
      "Cette option permet de tenir une liste de pas de tirs, les tireurs doivent sélectionner un pas de tir qu'ils emprunteront au moment de badger.",
  },
  {
    enum: SubscrOption.Gu,
    title: "Gestion des armes de prêt",
    desc: "Gérez un râtelier d'armes de prêt, le tireur renseigne l'arme qu'il emprunte au moment de badger.",
  },
  /*{
    enum: SubscrOption.Ke,
    title: "Gestion de clefs",
    desc: "Permet de gérer des clefs qui sont prêtées au tireur pour accéder au pas de tir",
  },*/
];

//types

export const ClubManager = () => {
  const { currentClub } = useContext(CurrentContext);
  const [edit, setEdit] = useState(false);
  if (currentClub)
    return (
      <>
        {edit && <ClubForm edit={true} setEdit={setEdit} />}
        <Col span={24}>
          <CenteredWidthLimitedCard width={800} title="Gestion du club de tir !">
            <Row gutter={[40, 40]}>
              <Col span={24}>
                <Descriptions
                  title={
                    <>
                      Coordonnées du club
                      <Button type="link" onClick={() => setEdit(true)}>
                        <EditOutlined />
                      </Button>
                    </>
                  }
                  column={2}
                >
                  <Descriptions.Item label="Nom du Club">{currentClub.name}</Descriptions.Item>
                  <Descriptions.Item label="N° fédéral">{currentClub.fedNb}</Descriptions.Item>
                  <Descriptions.Item label="Adresse" span={2}>
                    {currentClub.address.street + " " + currentClub.address.zip + " " + currentClub.address.city + " " + currentClub.address.country}
                  </Descriptions.Item>
                </Descriptions>
              </Col>

              <Col span={24}>
                <ClubOptionSelector />
              </Col>

              <Col span={24}>
                <Divider orientation="left">Option souscrites</Divider>
                <List
                  size="large"
                  dataSource={subscrOptions}
                  renderItem={(option) => (
                    <List.Item key={option.enum}>
                      <List.Item.Meta
                        title={option.title}
                        description={option.desc}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: currentClub?.actualOptions.includes(option.enum) ? "#87d068" : "#FF4F4F",
                            }}
                            icon={
                              currentClub?.actualOptions.includes(option.enum) ? <CheckOutlined color="success" /> : <CloseOutlined color="error" />
                            }
                          />
                        }
                      >
                        caca
                      </List.Item.Meta>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </CenteredWidthLimitedCard>
        </Col>
      </>
    );
  return (
    <Navigate
      to={{
        pathname: "/",
      }}
    />
  );
};
