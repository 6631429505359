import React from "react";
import { Button, Col, Result, Skeleton } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { EmailValPurpose, useEmailValFromTokenQuery } from "../../api/gqlTypes";
import { WidthLimitedCard } from "../CommonStyledCMP";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ValidateEmail } from "./ValidateEmail";
import { ResetPassword } from "./ResetPwd";
import { UrlRoutes } from "../../Common";

export const EmailValidator = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useEmailValFromTokenQuery({
    variables: { token },
  });

  return (
    <Col span={24}>
      <WidthLimitedCard
        width={600}
        title={
          (data?.emailValFromToken.purpose === EmailValPurpose.EmailConfirm && "Confirmation de votre adresse email:") ||
          (data?.emailValFromToken.purpose === EmailValPurpose.SetPwd && "Création de votre mot de passe:") ||
          (data?.emailValFromToken.purpose === EmailValPurpose.ResetPwd && "Réinitialisation de votre mot de passe:") ||
          (error && "Erreur") ||
          "Veuillez patienter..."
        }
        size="small"
      >
        {loading && <Skeleton />}
        {data &&
          (data.emailValFromToken.purpose === EmailValPurpose.EmailConfirm ? <ValidateEmail token={token} /> : <ResetPassword token={token} />)}
        {error && (
          <Result
            title="Erreur"
            status="error"
            icon={<CloseCircleOutlined />}
            subTitle={error.message}
            extra={
              <Button type="primary" onClick={() => navigate(UrlRoutes.Si)}>
                Retour
              </Button>
            }
          />
        )}
      </WidthLimitedCard>
    </Col>
  );
};
