import { Modal, Timeline, Alert, Button, Skeleton } from "antd";
import React from "react";
import moment from "moment";
import { setCase } from "../../Common";
import { useUserItacChecksTimelineQuery } from "../../api/gqlTypes";
import { Loader } from "../Loader";
import { FileProtectOutlined } from "@ant-design/icons";

type Props = {
  userId: string;
  setItacChecksTimeline(userId: string | null): void;
};

export const ItacChecksTimeline = (props: Props) => {
  const { loading, data, error } = useUserItacChecksTimelineQuery({
    variables: { userId: props.userId },
  });
  return (
    <Modal
      title={
        data ? (
          <>
            <FileProtectOutlined />
            {" Contrôles de licence de " +
              data.user.lname +
              " " +
              setCase(data.user.fname)}
          </>
        ) : (
          <Skeleton />
        )
      }
      visible={true}
      onCancel={() => props.setItacChecksTimeline(null)}
      footer={[
        <Button key="back" onClick={() => props.setItacChecksTimeline(null)}>
          Fermer
        </Button>,
      ]}
    >
      {loading && <Skeleton />}
      {error && <Loader embedded={true} error={error} />}

      {data &&
        (!data.user.itacChecks ? (
          <Alert
            type="error"
            message="Pas de vérifications Itac pour ce membre!"
          />
        ) : (
          <Timeline mode="right">
            {data.user.itacChecks.map((ic) => (
              <Timeline.Item
                label={ic.isValid ? "Licence valide" : "Licence invalide"}
                color={ic.isValid ? "green" : "red"}
              >
                {moment(new Date(ic.checkDate)).format("DD/MM/YYYY")}
              </Timeline.Item>
            ))}
          </Timeline>
        ))}
    </Modal>
  );
};
