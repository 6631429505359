import { DocSection, WarnTag, WidthLimitedParagraph } from "./Reusable";
import { Typography } from "antd";

export const InscriptionClub = () => {
  return (
    <DocSection>
      <Typography.Title level={2}>Je suis gérant de club</Typography.Title>
      <WidthLimitedParagraph>
        Pour commencer à utiliser ShootRecord sur votre club de tir il vous suffit de creer votre compte personnel, cf. Inscription. Une fois que vous
        êtes connecté avec votre compte, vous pouvez cliquer sur "Ajouter un club" en haut à droite. Un formulaire s'ouvrira et vous demandera les
        coordonnées de voyre club. Il vous suffit de le remplir et de valider. Aucun mail de validation ou autre étape n'est requise pour sa création.
      </WidthLimitedParagraph>

      <Typography.Title level={4}>Gestion des droits</Typography.Title>
      <WidthLimitedParagraph>
        Une fois la création de votre club faite, vous en êtes le seul administrateur. Des droits peuvent être octroyés et révoqués à d'autres
        personnes via le tableau des membres. Toutefois il faut pointer un tireur au moins une fois à votre club pour qu'il apparaisse dans le tableau
        des membres. Pointez donc les personnes de votre commité directeur, puis allez dans le tableau des membres et cliquez sur l'icône crayon sur
        la ligne de la personne concernée. Dans le champ privilège, choisissez le(s) droit(s) à octroyer. Une fois la validation faite, le tireur peut
        accéder aux fonctionnalités offertes.
      </WidthLimitedParagraph>

      <Typography.Title level={4}>Souscripion des options</Typography.Title>
      <WidthLimitedParagraph>
        ShootRecord vous offre une période d'essai de deux mois. Par défaut aucune option n'est activée. Vous pouvez naviguer dans les paramètres du
        club pour activer / désactiver librement les options pendant la période d'essai. A l'issu des deux mois, il vous sera proposé de payer
        l'abonnement, pour la durée de votre choix et avec les options souhaitées. Le total se recalcul automatiquement.
        <WarnTag
          message=" Notez que vous payez un abonnement lié au club et non à votre utilisateur. Imaginons que vous quittiez le comité directeur d'un club, il
            vous suffit de vous révoquer les droits dans la table des membres, toutefois les souscriptions faites restent attachés au club, et c'est aux
            membres ayant encore l'accès administrateur de renouveller le contrat quand il arrive à échéance"
        />
      </WidthLimitedParagraph>
    </DocSection>
  );
};
