import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Button, Form, Input, Menu, Modal, notification } from "antd";
import { BarcodeOutlined, SettingOutlined, LoginOutlined, LogoutOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useCloseKioskMutation, useOpenKioskMutation, useSignOutMutation } from "../../api/gqlTypes";
import { errNot, UrlRoutes, useStore } from "../../Common";
import { sha256 } from "js-sha256";
import { CurrentContext } from "../../App";
import styled from "styled-components";

const { SubMenu } = Menu;

const ConnectButton = styled(Button)`
margin-left: 10px;
`

export const UserMenu = () => {
  //Logout Mutation
  const [, setToken] = useStore("loginToken");
  const [form] = Form.useForm();
  const inputRef = useRef<Input>(null);
  const [modal, setModal] = useState<boolean>(false);
  const [logout] = useSignOutMutation({
    onCompleted: () => {
      setToken("");
    },
  });

  useEffect(() => {
    return inputRef?.current?.focus();
  });

  //open Kiosk mutation
  const [openKiosk] = useOpenKioskMutation({
    onCompleted: ({ openKiosk }) => {
      setToken(openKiosk);
      notification.success({ message: "Kiosk", description: "Mode kiosk démarré." });
    },
    onError: (err) => errNot(err),
  });

  const [closeKiosk] = useCloseKioskMutation({
    onCompleted: ({ closeKiosk }) => {
      setToken(closeKiosk);
      setModal(false);
      notification.success({ message: "Kiosk", description: "Mode kiosk quitté." });
    },
    onError: (err) => errNot(err),
  });

  const { user, kiosk, currentClub } = useContext(CurrentContext);

  return (
    <>
      <Modal forceRender title="Quitter le mode kiosk" visible={modal} onOk={form.submit} onCancel={() => setModal(false)}>
        <Form form={form} name="pwdConfirm" preserve={false} onFinish={({ password }) => closeKiosk({ variables: { pwdHash: sha256(password) } })}>
          <Form.Item label="Mot de passe:" name="password">
            <Input.Password ref={inputRef} autoComplete="new-password" />
          </Form.Item>
        </Form>
      </Modal>
      <Menu mode="horizontal">
        <SubMenu
          title={
            !user && (
              <ConnectButton type="dashed" size="small">
                <Link to={UrlRoutes.Si}>Se connecter</Link>
              </ConnectButton>
            )
          }
          icon={user ? <Avatar size={32} children={user.lname.charAt(0) + user.fname.charAt(0)} /> : <Avatar size={32} icon={<UserOutlined />} />}
        >
          {user ? (
            <>
              {!kiosk && (
                <Menu.Item icon={<LogoutOutlined />} onClick={() => logout()}>
                  Se déconnecter
                </Menu.Item>
              )}
              {currentClub &&
                (kiosk ? (
                  <Menu.Item
                    icon={<BarcodeOutlined />}
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    Quitter le kiosk
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    icon={<BarcodeOutlined />}
                    onClick={() => {
                      openKiosk();
                    }}
                  >
                    Lancer un kiosk
                  </Menu.Item>
                ))}
              {!kiosk && (
                <Menu.Item icon={<SettingOutlined />}>
                  <Link to={UrlRoutes.Us}>Mon Compte</Link>
                </Menu.Item>
              )}
            </>
          ) : (
            <>
              <Menu.Item icon={<LoginOutlined />}>
                <Link to={UrlRoutes.Si}>Se connecter</Link>
              </Menu.Item>
              <Menu.Item icon={<UserAddOutlined />}>
                <Link to={UrlRoutes.Su}>Creer un compte</Link>
              </Menu.Item>
            </>
          )}
        </SubMenu>
      </Menu>
    </>
  );
};
