import React, { useContext } from "react";
import { Col, Row } from "antd";
import { Navigate, Route, Routes, useRoutes } from "react-router-dom";
import { UrlRoutes } from "./Common";
import { SignUp } from "./ui/SignUp";
import { SignIn } from "./ui/SignIn";
import { UserParams } from "./ui/User/UsersParams";
import { CurrentContext } from "./App";
import { Role, SubscrOption } from "./api/gqlTypes";
import { RegisterManager } from "./ui/Register/RegisterManager";
import { AttendManager } from "./ui/Attend/AttendManager";
import { RangesManager } from "./ui/Ranges/RangesManager";
import { CalibersManager } from "./ui/Calibers/CalibersManager";
import { ClubManager } from "./ui/Clubs/ClubManager";
import { GunsManager } from "./ui/Guns/GunsManager";
import { MembersManager } from "./ui/Members/MemberManager";
import { RegisterCalendar } from "./ui/Register/RegisterCalendar";
import { Summary } from "./ui/User/Summary";
import { DocManager } from "./ui/Documentation/DocManager";
import { Home } from "./Home";
import { EmailValidator } from "./ui/EmailValidation/EmailValidator";
import { ClubForm } from "./ui/Clubs/ClubForm";
import { LandingPage } from "./ui/Landing/LandingPage";
import { Contact } from "./ui/About/Contact";
import { RGPD } from "./ui/RGPD";

export const MainGridWrapper = (props: any) => (
  <Row justify="center">
    <Col xxl={18} xl={20} lg={22} md={24} sm={24} xs={24}>
      <Row gutter={[20, 20]} justify={"space-around"}>
        {props.children}
      </Row>
    </Col>
  </Row>
);

export const MainContainer = () => {
  const { user, currentRoles, currentClub, kiosk } = useContext(CurrentContext);
  const subscriptions = currentClub?.actualOptions;

  return useRoutes([
    { path: UrlRoutes.Dc + "/*", element: <DocManager /> },
    { path: UrlRoutes.Lp, element: <LandingPage /> },
    { path: "/", element: <Home /> },
    {
      path: "/*",
      element: (
        <Row justify="center">
          <Col xxl={18} xl={20} lg={22} md={24} sm={24} xs={24}>
            <Row gutter={[20, 20]} justify={"space-around"}>
              <RGPD />
              <Routes>
                <Route path={UrlRoutes.Ct} element={<Contact />} />
                <Route path={UrlRoutes.Su} element={<SignUp />} />
                <Route path={UrlRoutes.Si} element={user ? <Navigate to="/" /> : <SignIn />} />
                <Route path={UrlRoutes.Ev + "/:token"} element={<EmailValidator />} />
                <Route path={UrlRoutes.Us} element={user && !kiosk ? <UserParams /> : <Navigate to="/" />} />
                <Route path={UrlRoutes.Sm} element={user && !kiosk ? <Summary /> : <Navigate to="/" />} />
                <Route path={UrlRoutes.At} element={currentRoles.includes(Role.At) ? <AttendManager /> : <Navigate to="/" />} />
                <Route path={UrlRoutes.Rg} element={currentRoles.includes(Role.Rg) && !kiosk ? <RegisterManager /> : <Navigate to="/" />} />

                <Route path={UrlRoutes.Ca} element={currentRoles.includes(Role.Rg) && !kiosk ? <RegisterCalendar /> : <Navigate to="/" />} />

                <Route
                  path={UrlRoutes.Ra}
                  element={
                    currentRoles.includes(Role.Ra) && subscriptions?.includes(SubscrOption.Ra) && !kiosk ? <RangesManager /> : <Navigate to="/" />
                  }
                />
                <Route
                  path={UrlRoutes.Cal}
                  element={
                    currentRoles.includes(Role.Cm) && subscriptions?.includes(SubscrOption.Ca) && !kiosk ? <CalibersManager /> : <Navigate to="/" />
                  }
                />

                <Route
                  path={UrlRoutes.Gu}
                  element={
                    currentRoles.includes(Role.Gu) && subscriptions?.includes(SubscrOption.Gu) && !kiosk ? <GunsManager /> : <Navigate to="/" />
                  }
                />
                <Route path={UrlRoutes.Cc} element={user && !kiosk ? <ClubForm edit={false} /> : <Navigate to="/" />} />
                <Route path={UrlRoutes.Cm} element={currentRoles.includes(Role.Ad) && !kiosk ? <ClubManager /> : <Navigate to="/" />} />
                <Route path={UrlRoutes.Mb} element={currentRoles.includes(Role.Ma) ? <MembersManager /> : <Navigate to="/" />} />
              </Routes>
            </Row>
          </Col>
        </Row>
      ),
    },
  ]);
};
