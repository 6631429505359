import React, { useContext } from "react";
import { CurrentContext } from "./App";
import { Role } from "./api/gqlTypes";
import { LandingPage } from "./ui/Landing/LandingPage";
import { AttendManager } from "./ui/Attend/AttendManager";
import { Summary } from "./ui/User/Summary";
import { MainGridWrapper } from "./MainContainer";

export const Home = () => {
  const { currentClub, user, currentRoles, kiosk } = useContext(CurrentContext);

  return (
    <>
      {kiosk || currentClub || user ? (
        <MainGridWrapper>
          {(kiosk && <AttendManager />) || (currentClub && currentRoles.includes(Role.At) && <AttendManager />) || (user && <Summary />)}
        </MainGridWrapper>
      ) : (
        <LandingPage />
      )}
    </>
  );
  /*  if (kiosk) return <AttendManager />;
  if (currentClub && currentRoles.includes(Role.At)) return <AttendManager />;
  if (user) return <Summary />;
  return <LandingPage />;*/
};
