import React, { useEffect } from "react";
import { useResetPwdMutation } from "../../api/gqlTypes";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Result } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { sha256 } from "js-sha256";
import { layout, tailLayout } from "../../Common";
import { FormInsertParagraph, LeftMargedButton } from "../CommonStyledCMP";

type Props = {
  token: string;
};

export const ResetPassword = (props: Props) => {
  const [resetPwd, { error, data, called }] = useResetPwdMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const timeout = setTimeout(() => {
        navigate("/");
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  });

  const onFinish = ({ password, password2 }: { password: string; password2: string }) => {
    resetPwd({ variables: { token: props.token, pwdHash: sha256(password), pwdHash2: sha256(password2) } });
  };

  return called ? (
    <Result
      title={(data && "Mot de passe remplacé, vous allez être redirigé(e)...") || (error && error.message) || "Creation de votre mot de passe..."}
      status={(error && "error") || (data && "success") || "info"}
      icon={(error && <CloseCircleOutlined />) || (data && <CheckCircleOutlined />) || <LoadingOutlined />}
      extra={<Button type="primary">Retour</Button>}
    />
  ) : (
    <>
      <FormInsertParagraph style={{ marginBottom: "30px", textAlign: "center" }}>Veuillez entrer votre nouveau mot de passe:</FormInsertParagraph>
      <Form onFinish={onFinish} {...layout}>
        <Form.Item name="password" label="Mot de passe">
          <Input type="password" />
        </Form.Item>
        <Form.Item name="password2" label="confirmation">
          <Input type="password" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
          <LeftMargedButton>Annuler</LeftMargedButton>
        </Form.Item>
      </Form>
    </>
  );
};
