import React, { useContext, useRef, useState } from "react";
import { Button, Col, Form, notification, Row, Select, Skeleton, Tag, Typography } from "antd";
import { AttendByLicenceMutation, SubscrOption, useAttendByUserIdMutation, useAvailableGunsQuery, useAvailableRangesQuery } from "../../api/gqlTypes";
import { ApolloError } from "@apollo/client";
import { errNot } from "../../Common";
import { CurrentContext } from "../../App";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Loader } from "../Loader";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const { Option } = Select;
const { Text } = Typography;

type Props = {
  memberId: string;
  setAttendFeedback(attendFeedback: AttendByLicenceMutation["attendByLicence"] | ApolloError | null): void;
  inputSize: boolean;
};

export const AttendSurvey = (props: Props) => {
  const { currentClub } = useContext(CurrentContext);

  const avRanges = useAvailableRangesQuery({
    skip: !currentClub.actualOptions.includes(SubscrOption.Ra),
    fetchPolicy: "no-cache",
    pollInterval: 2000,
  });
  const avGuns = useAvailableGunsQuery({
    skip: !currentClub.actualOptions.includes(SubscrOption.Gu),
    fetchPolicy: "no-cache",
    pollInterval: 1000,
  });

  const [attendByUserId] = useAttendByUserIdMutation({
    onError: (err) => errNot(err),
    onCompleted: (data) => {
      notification.success({
        message: "Succès :",
        description: "Pointage effectué.",
      });
      props.setAttendFeedback(data.attendByUserId);
    },
    refetchQueries: ["Register", "UsersTable"],
  });

  const surveyFormRef = useRef(null);
  const [usedRange, setUsedRange] = useState<string | null>(null);

  const onFinish = (values: { usedRange: string | undefined; usedCalibers: string[] | undefined; usedGun: string | undefined }) => {
    attendByUserId({
      variables: {
        id: props.memberId,
        usedRange: values.usedRange ?? null,
        usedCalibers: values.usedCalibers?.map((cal) => ({ id: cal })) ?? null,
        usedGun: values.usedGun ?? null,
        punch: true,
      },
      refetchQueries: ["Register", "UsersTable", "Guns", "Ranges", "ByDateRegister", "byMonthRegister"],
    });
  };

  if (avRanges.data || avGuns.data)
    return (
      <Form name="surveyForm" ref={surveyFormRef} onFinish={onFinish} {...layout}>
        {currentClub?.actualOptions.includes(SubscrOption.Ra) && (
          <Form.Item
            label={"Pas de tir:"}
            name="usedRange"
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner un pas de tir!",
              },
            ]}
          >
            <Select
              placeholder="Sélectionnez le pas de tir que vous allez utiliser"
              onChange={(value: string) => {
                setUsedRange(value);
              }}
              size={props.inputSize ? "large" : "middle"}
            >
              {avRanges.data?.availableRanges.map((range) => (
                <Option key={range.id} value={range.id}>
                  <Tag>{range.rangeName}</Tag> &nbsp;
                  {range.availableStations + "/" + range.shootingStations + " postes restants"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {currentClub?.actualOptions.includes(SubscrOption.Ca) && usedRange && (
          <Form.Item key="Calibers" label="Calibres tirés" name="usedCalibers">
            <Select
              mode="multiple"
              placeholder="Sélectionnez un ou plusieurs calibres que vous allez tirer"
              children={avRanges.data.availableRanges
                .find((range) => range.id === usedRange)
                ?.allowedCalibers.map((caliber) => (
                  <Option value={caliber.id} key={caliber.id}>
                    {caliber.caliberName}
                  </Option>
                ))}
            />
          </Form.Item>
        )}

        {currentClub?.actualOptions.includes(SubscrOption.Gu) && (
          <Form.Item key="Gun" label="Sélectionnez une arme de prêt:" name="usedGun">
            <Select
              allowClear
              clearIcon={<CloseCircleOutlined color="red" />}
              placeholder="Pas d'arme de prêt "
              size={props.inputSize ? "large" : "middle"}
              children={avGuns.data?.availableGuns.map((gun) => (
                <Option value={gun.id} key={gun.id}>
                  <Text underline children="Marque" /> {": " + gun.brand} &nbsp; <Text underline children=" Modèle" />
                  {": " + gun.model} &nbsp; <Text underline children="N° série" /> {": " + gun.serial}
                </Option>
              ))}
            />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Row gutter={[1, 0]}>
            <Col offset={0}>
              <Button type="primary" htmlType="submit">
                Valider
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  else if (avRanges.error || avGuns.error) return <Loader embedded={false} error={avRanges.error || avGuns.error} />;
  else return <Skeleton />;
};
