import { gql } from "@apollo/client";

export const calibersQuery = gql`
  query Calibers {
    calibers {
      id
      caliberName
      allowedRanges {
        rangeName
      }
      club {
        id
        name
      }
    }
  }
`;

export const caliberQuery = gql`
  query Caliber($id: ID!) {
    caliber(id: $id) {
      id
      caliberName
      allowedRanges {
        id
        rangeName
      }
      club {
        id
        name
      }
    }
  }
`;

export const rangeFormCalList = gql`
  query RangeFormCalList {
    calibers {
      id
      key
      caliberName
    }
  }
`;

export const allowedCalibers = gql`
  query AllowedCalibers($rangeId: ID!) {
    byRangeCalibers(rangeId: $rangeId) {
      id
    }
  }
`;

export const addCaliber = gql`
  mutation AddCaliber($caliberName: String!, $allowedRanges: [AllowedRange!]!) {
    addCaliber(caliberName: $caliberName, allowedRanges: $allowedRanges) {
      id
      caliberName
    }
  }
`;

export const editCaliber = gql`
  mutation EditCaliber(
    $id: ID!
    $caliberName: String!
    $allowedRanges: [AllowedRange!]!
  ) {
    editCaliber(
      id: $id
      caliberName: $caliberName
      allowedRanges: $allowedRanges
    ) {
      id
      caliberName
    }
  }
`;

export const removeCaliber = gql`
  mutation RemoveCaliber($id: ID!) {
    removeCaliber(id: $id)
  }
`;
