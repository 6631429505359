import React, { useContext } from "react";

import { Menu } from "antd";
import {
  AimOutlined,
  BankOutlined,
  BookOutlined,
  CalendarOutlined,
  ControlOutlined,
  EllipsisOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  HomeOutlined,
  OrderedListOutlined,
  PicCenterOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { Role, SubscrOption } from "../../api/gqlTypes";
import { UrlRoutes } from "../../Common";
import styled from "styled-components";
import { CurrentContext } from "../../App";

const { SubMenu } = Menu;

const SmallDivider = styled(Menu.Divider)`
  && {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const MainMenu = () => {
  const { pathname } = useLocation();
  const { user, currentClub, currentRoles, kiosk } = useContext(CurrentContext);
  return (
    <Menu mode="horizontal" selectedKeys={[pathname.slice(1)]}>
      {/*      <Menu.Item>
        <Link to={UrlRoutes.Lp}>Landing page</Link>
      </Menu.Item>*/}
      {currentRoles.includes(Role.At) && (
        <Menu.Item key={UrlRoutes.At} icon={<SolutionOutlined />}>
          <Link to={UrlRoutes.At}>Inscrire au registre</Link>
        </Menu.Item>
      )}

      {currentRoles.includes(Role.Rg) && !kiosk && (
        <SubMenu title="Registres par dates" icon={<FileDoneOutlined />}>
          <Menu.Item key={UrlRoutes.Rg} icon={<FileSearchOutlined />}>
            <Link to={UrlRoutes.Rg}>Registres par Date(s)</Link>
          </Menu.Item>

          <Menu.Item key={UrlRoutes.Ca} icon={<CalendarOutlined />}>
            <Link to={UrlRoutes.Ca}>Calendrier</Link>
          </Menu.Item>

          <Menu.Item icon={<OrderedListOutlined />}>Classement d'assiduité</Menu.Item>
        </SubMenu>
      )}

      {currentClub && !kiosk && (
        <SubMenu title="Gestion du Stand" icon={<BankOutlined />}>
          {currentClub?.actualOptions.includes(SubscrOption.Ca) && (
            <Menu.Item key={UrlRoutes.Cal} icon={<AimOutlined />}>
              <Link to={UrlRoutes.Cal}>Calibres</Link>
            </Menu.Item>
          )}
          {currentClub?.actualOptions.includes(SubscrOption.Ra) && (
            <Menu.Item key={UrlRoutes.Ra} icon={<PicCenterOutlined />}>
              <Link to={UrlRoutes.Ra}>Pas de tir </Link>
            </Menu.Item>
          )}

          {currentClub?.actualOptions.includes(SubscrOption.Gu) && (
            <Menu.Item key={UrlRoutes.Gu} icon={<AimOutlined />}>
              <Link to={UrlRoutes.Gu}>Râtelier d'armes</Link>
            </Menu.Item>
          )}

          <SmallDivider />
          {currentClub && (
            <Menu.Item key={UrlRoutes.Cm} icon={<ControlOutlined />}>
              <Link to={UrlRoutes.Cm}>Gestion du club</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {currentRoles?.includes(Role.Me) && !kiosk && (
        <Menu.Item key={UrlRoutes.Mb} icon={<TeamOutlined />}>
          <Link to={UrlRoutes.Mb}>Tireurs</Link>
        </Menu.Item>
      )}

      {user && !kiosk && (
        <Menu.Item key={UrlRoutes.Sm} icon={<UserOutlined />}>
          <Link to={UrlRoutes.Sm}>Ma page</Link>
        </Menu.Item>
      )}

      {!kiosk &&
        (currentClub ? (
          <SubMenu icon={<EllipsisOutlined />}>
            <Menu.Item key="/" icon={<HomeOutlined />}>
              <Link to="/">Accueil</Link>
            </Menu.Item>
            <Menu.Item key={UrlRoutes.Dc} icon={<BookOutlined />}>
              <Link to={UrlRoutes.Dc}>Documentation</Link>
            </Menu.Item>
            <Menu.Item key={UrlRoutes.Ct} icon={<QuestionCircleOutlined />}>
              <Link to={UrlRoutes.Ct}>A propos</Link>
            </Menu.Item>
          </SubMenu>
        ) : (
          <>
            <Menu.Item key="/" icon={<HomeOutlined />}>
              <Link to="/">Accueil</Link>
            </Menu.Item>
            <Menu.Item key={UrlRoutes.Dc} icon={<BookOutlined />}>
              <Link to={UrlRoutes.Dc}>Documentation</Link>
            </Menu.Item>
            <Menu.Item key={UrlRoutes.Ct} icon={<QuestionCircleOutlined />}>
              <Link to={UrlRoutes.Ct}>A propos</Link>
            </Menu.Item>
          </>
        ))}
    </Menu>
  );
};
