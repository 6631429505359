import { Alert, Button, Card, Checkbox, Col, DatePicker, Descriptions, Divider, notification, Row, Skeleton, Tag, Timeline } from "antd";
import styled from "styled-components";
import { useSendValidationEmailMutation, useUserSummaryQuery } from "../../api/gqlTypes";
import dayjs from "dayjs";
import { UserStats } from "./UserStats";
import moment from "moment";
import { AimOutlined, ShopOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { SmallMargedP } from "../CommonStyledCMP";

const TimelineCard = styled(Card)`
  height: calc(100% - 20px);
`;

const StyledPersonnalTimeline = styled(Timeline)`
  && li > .ant-timeline-item-label {
    width: calc(30% - 12px);
  }
  && li > .ant-timeline-item-tail {
    left: 30%;
  }
  && li > .ant-timeline-item-head {
    left: 30%;
  }
  &&& li > .ant-timeline-item-content {
    left: calc(30% - 4px);
    width: calc(70% - 14px);
    min-height: 10px;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  //width: fit-content;
`;

const { RangePicker } = DatePicker;
export const Summary = () => {
  const [wDetails, setWithDetails] = useState(true);
  const { data } = useUserSummaryQuery();

  const [sendValidationEmail] = useSendValidationEmailMutation({
    onCompleted: () =>
      notification.success({
        message: "Succès",
        description: "Un email de vérification vous a été renvoyé. Vous pouvez contacter un administrateur dans le cas où vous ne l'avez pas reçu!",
      }),
  });

  return (
    <>
      <Col span={8}>
        <TimelineCard title="Timeline" extra={<Checkbox children="Détails" checked={wDetails} onChange={() => setWithDetails(!wDetails)} />}>
          <div>
            <span>Intervalle:</span> <RangePicker size="small" />{" "}
          </div>
          <Divider />
          <StyledPersonnalTimeline mode="left">
            {data?.user.register.length === 0 && <Timeline.Item label="Aujourd'hui">Vous n'avez encore aucune présence!</Timeline.Item>}
            {data?.user.register.map((value, index) => {
              return (
                <Timeline.Item key={index} label={value.club.name}>
                  <SmallMargedP>{moment(value.beginDate).format("DD/MM/YYYY")}</SmallMargedP>
                  {wDetails && (
                    <>
                      {(value.usedRange || value.usedGun) && (
                        <SmallMargedP>
                          {value.usedRange && (
                            <>
                              <ShopOutlined />
                              {" " + value.usedRange.rangeName}
                            </>
                          )}
                          {value.usedRange && value.usedGun && <Divider type="vertical" />}
                          {value.usedGun && (
                            <>
                              {" "}
                              <AimOutlined /> {" " + [value.usedGun.brand, value.usedGun.model, value.usedGun.serial].join(" ")}
                            </>
                          )}
                        </SmallMargedP>
                      )}
                      {value.usedCalibers.length > 0 && (
                        <p>
                          {value.usedCalibers.map((caliber, index) => (
                            <Tag key={index}>{caliber.caliberName}</Tag>
                          ))}
                        </p>
                      )}
                    </>
                  )}
                </Timeline.Item>
              );
            }) ?? <Timeline.Item>Vous n'avez encore aucune présence!</Timeline.Item>}
          </StyledPersonnalTimeline>
        </TimelineCard>
      </Col>
      <Col span={16}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Card title="Mon profil">
              <>
                {data && !data?.user.trustedData && (
                  <StyledAlert
                    type="warning"
                    showIcon
                    message="Vos coordonnés n'ont pas été vérifiées! (Vous devez pointer dans un club de tir avec le QR-code de votre licence pour vérifier vos données.)"
                  />
                )}
                {data && data?.user.emails[0] && !data?.user.emails[0]?.verified && (
                  <StyledAlert
                    type="warning"
                    showIcon
                    message={
                      <>
                        Votre adresse email n'a pas été validée!{" "}
                        <Button
                          type="link"
                          onClick={() => {
                            sendValidationEmail();
                          }}
                        >
                          Renvoyer l'email
                        </Button>
                      </>
                    }
                  />
                )}
                {data ? (
                  <Descriptions>
                    <Descriptions.Item label="Nom">{data.user.lname}</Descriptions.Item>
                    <Descriptions.Item label="Prénom">{data.user.fname}</Descriptions.Item>
                    <Descriptions.Item label="Inscrit depuis">{dayjs(data.user.createdAt).format("DD/MM/YYYY")}</Descriptions.Item>
                    <Descriptions.Item label="Nb. présences">{data.user.register.length}</Descriptions.Item>
                    <Descriptions.Item label="Nb. contrôles itac">{data.user.itacChecks.length}</Descriptions.Item>
                    <Descriptions.Item label="Dernier contrôle itac">
                      {data.user.itacChecks[0] ? dayjs(data.user.itacChecks[0].checkDate).format("DD/MM/YYYY") : "Jamais"}
                    </Descriptions.Item>
                  </Descriptions>
                ) : (
                  <Skeleton />
                )}
              </>
            </Card>
          </Col>
          <Col span={24}>
            <UserStats />
          </Col>
        </Row>
      </Col>
    </>
  );
};
