import React, { useContext } from "react";
import { Button, Col, Row, Skeleton } from "antd";
import { ClubSelector } from "../Clubs/ClubSelector";
import { UserMenu } from "./UserMenu";
import { MainMenu } from "./MainMenu";
import styled from "styled-components";
import srLogo from "../../assets/srLogo.svg";
import { CurrentContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { UrlRoutes } from "../../Common";

const Logo = styled.div`
  width: 30px;
  height: 30px;
  margin: 16px 200px 16px 50px;
  float: left;
  background-image: url(${srLogo});
  background-repeat: no-repeat;
  background-size: 30px 30px;
`;

const UserCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const AddClubButton = styled(Button)`
  span {
    color: #88c876;
  }
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SkeletonMenuItem = styled(Skeleton.Button)`
  height: 40px;
  margin-right: 30px;
`;

const AvatarSkeleton = styled(Skeleton.Avatar)`
  height: 30px;
  margin-right: 10px;
`;

export const HeaderContent = () => {
  const navigate = useNavigate();

  const { user, clubs } = useContext(CurrentContext);
  return (
    <Row>
      <Col xxl={5} xl={5} lg={5} md={4} sm={3} xs={2}>
        <Logo />
      </Col>

      <Col xxl={14} xl={14} lg={14} md={16} sm={18} xs={20} className="inline-menus">
        <MainMenu />
      </Col>
      <UserCol xxl={5} xl={5} lg={5} md={4} sm={3} xs={2} className="user-header">
        {user &&
          (clubs.length > 0 ? (
            <ClubSelector />
          ) : (
            <AddClubButton type="dashed" size="small" onClick={() => navigate(UrlRoutes.Cc)}>
              Ajouter un Club
            </AddClubButton>
          ))}
        <UserMenu />
      </UserCol>
    </Row>
  );
};

export const LoadingHeaderContent = () => {
  return (
    <Row>
      <Col xxl={5} xl={5} lg={5} md={4} sm={3} xs={2}>
        <Logo />
      </Col>

      <FlexCol xxl={14} xl={14} lg={14} md={16} sm={18} xs={20}>
        <SkeletonMenuItem active size="large" />
        <SkeletonMenuItem active size="large" />
        <SkeletonMenuItem active size="large" />
      </FlexCol>
      <UserCol xxl={5} xl={5} lg={5} md={4} sm={3} xs={2}>
        <AvatarSkeleton active />
      </UserCol>
    </Row>
  );
};
